import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/style/border.css"
import "@/style/tff.css"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview)
Vue.use(ElementUI);
Vue.config.productionTip = false

// import "lib-flexible/flexible"
// import 'lib-flexible/flexible.js'
// import "@/utils/flexible";

/* 头部组件 */
// import Header from './components/Header'
// Vue.component(Header.name,Header)

/* 重置样式 */
import '@/assets/css/reset.min.css'
// import '@/assets/css/bootstrap.min.css'

import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}
unregister()
