<template>
  <div class="service">
    <div class="left">
      <div
        :class="['card ',index==active?'card-active':'']"
        v-for="(item,index) in datas"
        :key="'cards'+index"
        @click="imgPathFn(item,index)"
      >
        <div class="mengbans" :style="'background-image: url('+requireImg(item.url)+')'">
          <!-- <img :src=" alt /> -->
          <div class="mengban"></div>
        </div>
        <img :src="requireImg(active==index?item.activeIcon:item.icon)" alt />
        <span>{{item.text}}</span>
      </div>
    </div>
    <div class="right">
      <img :src="requireImg(imgPath)" alt />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    datas: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // key: value
      imgPath: "b2",
      active: null
    };
  },
  methods: {
    requireImg(url) {
      return require("@/assets/img/service/roadTransport/" + url + ".png");
    },
    imgPathFn(item, index) {
      this.imgPath = item.url;
      this.active = index;
      console.log(item, index);
    }
  }
};
</script>

<style lang="less" scoped>
.service {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .left {
    width: 474px;
    height: 397px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: space-between;
    .card {
      width: 219px;
      height: 115px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #f8f9fc;
      position: relative;
      cursor: pointer;
      img {
        width: 32px;
        height: 28px;
        margin-bottom: 15px;
        z-index: 10;
      }

      span {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 21px;
        z-index: 10;
      }
    }
    .card:nth-child(3) {
      img {
        width: 30px;
        height: 30px;
      }
    }
    .card-active {
      background-position: 50% 50%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url("../../../../../../assets/img/service/roadTransport/b1.png");
      span {
        color: #fff;
      }
      .mengbans {
        display: flex;
      }
    }
    .mengbans {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 8;
      background-position: 50% 50%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .mengban {
        width: 100%;
        height: 100%;
        background-color: rgba(64, 158, 255, 0.73);
      }
    }
  }
  .right {
    width: 680px;
    height: 395px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style> 