<template>
  <div class="content">
    <div class="top">
      <div class="card">
        <animate-number from="0" class="titlenum" to="3586" duration="3000"></animate-number>
        <div class="titleName">累积服务客户</div>
      </div>
      <div class="lines"></div>
      <div class="card card1">
        <animate-number from="0" class="titlenum" to="40306" duration="3000"></animate-number>
        <div class="titleName">累积业务订单</div>
      </div>
    </div>
    <div class="titleBot">
      <div v-for="(item, index) in data" :key="'titile' + index" style="">
        <span class="num">
          <animate-number from="0" :to="item.num" duration="3000"
            :formatter="(num) => formatter(num, item)"></animate-number>
          <!-- {{ item.num }} -->
          <span class="unit">{{ item.unit }}</span>
        </span>
        <span class="text">{{ item.text }}</span>
      </div>
    </div>
    <!-- 报关 -->
    <div class="clearance">
      <div class="product_title">报关</div>
      <div class="details">
        <div class="bgImg">
          <img src="@/assets/jyd_home/chanpinfuwu/baoguan.png" class="ImgBg" alt="" />
        </div>
        <div class="bgContent">
          <p>
            深圳市佳裕达报关有限公司主营口岸清关与仓储、运输等拓展性业务，通过科学高效的管理手段，使用自主研发的高新技术打造的系统操作平台，成功打造最具优势的链条式服务平台。我们秉持团结上进的企业文化，贯彻“专业报关、诚信服务”的经营理念。
          </p>
          <p class="pBG">
            主要业务:<span>进出口清关、属地报关、快件进出口、ATA单证册、修理物品、退运货物等特殊作业通关服务、9610，9710等跨境电商出口报关。</span>
          </p>
        </div>
      </div>
    </div>
    <!-- 空运 -->
    <div class="air">
      <div class="product_title">空运</div>
      <div class="airTabs">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="首航包机" name="first">
            <!-- <div v-for="(item,index) in airList" :key="index" class="airList">
                <img :src="item.img" alt="" class="airImg">
                <div class="airText">123</div>
            </div> -->
            <div class="bot">
              <div class="airList" v-for="(item, index) in airList" :key="index">
                <div class="tImg">
                  <img :src="item.img" class="airImg" alt="" />
                  <div class="bText">
                    <div class="Itext">{{ item.text }}</div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="运载能力" name="second">
            <img src="@/assets/jyd_home/chanpinfuwu/kyyznl.png" style="width: 35.3rem; height: 21.7rem" alt="" preview="1"
              preview-text="运载能力" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 海运 -->
    <div class="sea">
      <div class="title">海运</div>
      <div class="seeImg">
        <img src="@/assets/jyd_home/chanpinfuwu/haiyunt.png" style="width: 37.5rem; height: 17.8rem" alt="" preview="2"
          preview-text="海运" />
      </div>
    </div>
    <!-- 电商物流 -->
    <div class="dianshan">
      <div class="title product_title">电商物流-服务平台</div>
      <div class="content">
        <div class="dianT" v-for="(item, index) in dianshangList" :key="index">
          <img :src="item.img" alt="" :style="'width:calc(' + (item.with / 10) + '*1rem)'" />
          <div class="dText">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <!-- 国内及跨境陆路运输 -->
    <div class="domestic">
      <div class="product_title">国内及跨境陆路运输</div>
      <div class="domesticTabs">
        <el-tabs v-model="activeName1" @tab-click="handleClick1">
          <el-tab-pane label="服务内容" name="one">
            <div class="service">
              <div class="left">
                <div :class="['card ', index == active ? 'card-active' : '']" v-for="(item, index) in datas.charterData"
                  :key="'cards' + index" @click="imgPathFn(item, index)">
                  <div class="mengbans" :style="
                    'background-image: url(' + requireImg(item.url) + ')'
                  ">
                    <!-- <img :src=" alt /> -->
                    <div class="mengban"></div>
                  </div>
                  <img :src="
                    requireImg(active == index ? item.activeIcon : item.icon)
                  " style="margin-top: 0.2rem" alt />
                  <span style="margin-top: 0.2rem">{{ item.text }}</span>
                </div>
              </div>
              <div class="right">
                <img :src="requireImg(imgPath)" alt />
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="中港运输" name="two">
            <div class="transport">
              <div class="tramBox">
                <img src="../../../assets/jyd_home/chanpinfuwu/zgys.png" style="width: 31.8rem; height: 17.1rem" alt="" />
              </div>
              <div class="text">中港运输优势</div>
              <div class="boxContent" v-for="(item, index) in transportList" :key="index">
                {{ item.text }}
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="中欧铁路运输" name="three">
            <div class="railway">
              <div class="railwayBox">
                <img src="../../../assets/jyd_home/chanpinfuwu/tlys.png" style="width: 31.9rem; height: 17rem" alt="" />
              </div>
              <div class="railwayText">中欧铁路运输优势</div>
              <div class="railwayText1">
                比海运高效，比空运经济，交期准确，稳定性强
              </div>
              <div class="railwayText2">适用货运产品</div>
              <div class="railwayText3">
                高科技产品，电子产品，医疗器械，工业设备，汽车及其零配件，高端消费品，农副产品
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 仓储 -->
    <div class="keepGrain">
      <div class="product_title">仓储</div>
      <div class="keepGrainTabs">
        <el-tabs v-model="activeName2" @tab-click="handleClick2">
          <el-tab-pane label="仓库情况说明" name="oneC">
            <!-- <div class="storehouseBig">
              <div class="storehouse">
                <div class="storehouseNum">
                  1200<span class="unit">m²</span>
                </div>
                <div class="storehouseText" style="margin-left: 2rem">
                  中港中转仓
                </div>
              </div>
              <div class="storehouse" style="margin-left: ">
                <div class="storehouseNum" style="margin-left: ">
                  1000<span class="unit">m²</span>
                </div>
                <div class="storehouseText" style="margin-left: 1rem">
                  红酒客户
                </div>
              </div>
              <div class="storehouse" style="margin-left:1rem">
                <div class="storehouseNum" style="margin-left: 2rem;">
                  1000<span class="unit">m²</span>
                </div>
                <div class="storehouseText" style="margin-left:0.4rem">进口海运货物库存仓</div>
              </div>
              <div class="storehouse">
                <div class="storehouseNum" style="margin-left:1rem">
                  1500<span class="unit">m²</span>
                </div>
                <div class="storehouseText" style="margin-left: 2rem">
                  国际快寄货物
                </div>
              </div>
              <div class="storehouse">
                <div class="storehouseNum" style="margin-left: -0.2rem">
                  16000<span class="unit">m²</span>
                </div>
                <div class="storehouseText">国内货物暂存及中港配送</div>
              </div>
              <div class="storehouse">
                <div class="storehouseNum" style="margin-left: -1rem">
                  800<span class="unit">m²</span>
                </div>
                <div class="storehouseText" style="margin-left: 1rem">
                  香港本地派送
                </div>
              </div>
            </div> -->
            <div class="tabPaneContent" style="width:100%">
              <div class="card" v-for="(it, id) in storehouseList" :key="id"
                :style="'margin-right: ' + (it.right / 10) + 'rem;margin-left: ' + (it.left / 10) + 'rem'">
                <span class="num">{{ it.num }}<span class="symbol">{{ it.danwei }}</span></span>
                <span class="text">{{ it.text }}</span>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="仓库设施" name="twoC">
            <div class="installation">
              <div class="box" v-for="(item, index) in installationList" :key="index">
                <img :src="item.img" alt="" style="width: 11rem; height: 7.7rem" v-if="item.img" />
                <div class="boxBot" v-if="item.text">
                  <div class="text">{{ item.text }}</div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="大铲湾项目" name="threeC">
            <div class="houseContent">
              <img src="@/assets/jyd_home/chanpinfuwu/dcwxm.png" style="width: 35.2rem; height: 14.3rem" alt="" />
              <p class="houseText">
                大铲湾国际物流中心位于宝安区金港大道华展物流园内，项目包含3号仓、5号仓，两仓库内面积共计约
                1.4万平米。项目主要业务为快进快出、散货集拼、机场前置仓，具备仓储、全球采购、国际分拨配送和国际中转等多元化、多流向的综合业务能力。项目可为华南制造业从业者、贸易商以及物流同行提供更完善和更高效的物流服务及进出口商贸配套服务。
              </p>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 技术开发 -->
    <div class="technological">
      <div class="title">技术开发</div>
      <div class="jsImg" style="margin-top: 1.7rem; margin-left: 1.2rem; height: 14.4rem">
        <img src="@/assets/jyd_home/chanpinfuwu/jskfq.png" style="width: 35.1rem; height: 12.8rem" alt="" preview="3"
          preview-text="技术开发" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() { },
  data() {
    return {
      datas: {
        charterData: [
          { text: "整车运输", activeIcon: "11", icon: "1", url: "b2" },
          { text: "零担快运", activeIcon: "12", icon: "2", url: "b7" },
          { text: "同城配送", activeIcon: "13", icon: "3", url: "b5" },
          { text: "冷链运输", activeIcon: "14", icon: "4", url: "b3" },
          { text: "项目运输", activeIcon: "15", icon: "5", url: "b6" },
          { text: "小包裹准时交付", activeIcon: "16", icon: "6", url: "b4" },
        ],
      },
      transportList: [
        { text: "自有车辆" },
        { text: "车辆周转快" },
        { text: "香港仓库快速中转" },
        { text: "定时派送" },
      ],
      data: [
        { num: 23, text: "服务网点", unit: "个" },
        { num: 6, text: "仓储中心", unit: "个" },
        { num: 32200, text: "仓储总面积", unit: "m²" },
        { num: 80.96, text: "年运货总价值", unit: "亿元" },
      ],
      activeName2: "oneC",
      activeName: "first",
      activeName1: "one",
      airList: [
        {
          img: require("@/assets/jyd_home/chanpinfuwu/jlp.png"),
          text: "深圳—吉隆坡",
        },
        {
          img: require("@/assets/jyd_home/chanpinfuwu/mg.png"),
          text: "深圳—曼谷",
        },
        {
          img: require("@/assets/jyd_home/chanpinfuwu/ny.png"),
          text: "深圳—纽约",
        },
        {
          img: require("@/assets/jyd_home/chanpinfuwu/mnl.png"),
          text: "深圳—马尼拉",
        },
        {
          img: require("@/assets/jyd_home/chanpinfuwu/xdl.png"),
          text: "香港—新德里",
        },
        {
          img: require("@/assets/jyd_home/chanpinfuwu/klk.png"),
          text: "深圳—克拉克",
        },
      ],
      dianshangList: [
        {
          img: require("@/assets/jyd_home/chanpinfuwu/yms.png"),
          text: "亚马逊", with: 88
        },
        {
          img: require("@/assets/jyd_home/chanpinfuwu/Ebay.png"),
          text: "Ebay", with: 65
        },
        {
          img: require("@/assets/jyd_home/chanpinfuwu/shopify.png"),
          text: "独立站", with: 82
        },
        {
          img: require("@/assets/jyd_home/chanpinfuwu/wayfair.png"),
          text: "Wayfair", with: 94
        },
        {
          img: require("@/assets/jyd_home/chanpinfuwu/lazada.png"),
          text: "Lazada", with: 93
        },
        {
          img: require("@/assets/jyd_home/chanpinfuwu/shopee.png"),
          text: "Shopee", with: 94
        },
        {
          img: require("@/assets/jyd_home/chanpinfuwu/lotte.png"),
          text: "乐天", with: 95
        },
        {
          img: require("@/assets/jyd_home/chanpinfuwu/alixpress.png"),
          text: "速卖通", with: 117
        },
        {
          img: '',
          text: "",
          with: 90
        },
      ],
      storehouseList: [
        { text: '中港中转仓', num: "1200", danwei: "m²", right: 69, left: 3 },
        { text: '红酒客户', num: "1000", danwei: "m²", right: 50, left: 0 },
        { text: '进口海运货物暂存仓', num: "1000", danwei: "m²", right: 0, left: 0 },
        { text: '国际快寄货物', num: "1500", danwei: "m²", right: 36, left: 0 },
        { text: '国内货物暂存及中港配送', num: "16000", danwei: "m²", right: 33, left: 0 },
        { text: '香港本地派送', num: "800", danwei: "m²", right: 0, left: 0 },
      ],
      installationList: [
        {
          img: require("@/assets/jyd_home/chanpinfuwu/sxt.png"),
          text: "摄像头",
        },
        {
          img: require("@/assets/jyd_home/chanpinfuwu/chache.png"),
          text: "叉车",
        },
        {
          img: require("@/assets/jyd_home/chanpinfuwu/tuopan.png"),
          text: "托盘",
        },
        {
          img: require("@/assets/jyd_home/chanpinfuwu/yeyasjpt.png"),
          text: "液压升降平台",
        },
        {
          img: require("@/assets/jyd_home/chanpinfuwu/pda.png"),
          text: "PDA",
        },
        {
          img: require("@/assets/jyd_home/chanpinfuwu/huojia.png"),
          text: "货架",
        },
        {
          img: require("@/assets/jyd_home/chanpinfuwu/spjk.png"),
          text: "视屏监控",
        },
        {
          img: require("@/assets/jyd_home/chanpinfuwu/xgajj.png"),
          text: "X光安检机",
        },
        {
          img: null,
          text: null,
        },
      ],
      imgPath: "b2",
      active: null,
    };
  },
  methods: {
    formatter(num, item) {
      if (item.num == 80.96) {
        return num.toFixed(2);
      }
      return num.toFixed(0);
    },
    handleClick2(tab) {
      console.log(tab);
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleClick1(tab) {
      console.log(tab);
    },
    requireImg(url) {
      // return require("@/assets/img/service/roadTransport/" + url + ".png");
      return require("../../../assets/jyd_home/chanpinfuwu/chanpinBg/" +
        url +
        ".png");
    },
    imgPathFn(item, index) {
      this.imgPath = item.url;
      this.active = index;
      console.log(item, index);
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  position: relative;

  .top {
    // position: absolute;
    // left:1.08rem;
    display: flex;
    width: 34.9rem;
    height: 6rem;
    background: #ffffff;
    box-shadow: 0rem 0.2rem 0.5rem 0.2rem rgba(219, 219, 219, 0.5);
    border-radius: 0.2rem;
    // margin-top: -3rem;
    // margin-left: 1.3rem;
    transform: translate(1.3rem, -3rem);

    .card {
      // margin-top: 1rem;
      // margin-left: 7.75rem;
      padding: 1rem 0 1rem 1.8rem;
      font-size: 1.8rem;
      font-family: Oswald-Medium, Oswald;
      font-weight: 500;
      color: #357bff;
      width: 17.4rem;
      height: 6rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }


    .card1 {
      padding: 1rem 1.8rem 1rem 0;

    }

    .lines {
      width: 0.1rem;
      height: 2.86rem;
      border-right: 0.1rem solid #cccccc;
      position: absolute;
      left: 17.4rem;
      top: 1.57rem;
    }

    .titlenum {
      font-size: 1.8rem;
      font-family: 'Oswald-Medium';
      font-weight: 500;
      color: #357BFF;
      line-height: 2.5rem;
      // width: 100%;
      text-align: center;
    }

    .titleName {
      font-size: 1rem;
      font-family: MicrosoftYaHei;
      color: #333333;
      line-height: 1.4rem
    }
  }

  .titleBot {
    margin-top: -0.8rem;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .num {
      //   width: 1.8rem;
      height: 2.5rem;
      font-size: 1.8rem;
      font-family: Oswald-Medium, Oswald;
      font-weight: 500;
      color: #357bff;
      line-height: 2.5rem;
      font-family: Oswald-Medium, Oswald;
    }

    .unit {
      //   width: 1.1rem;
      height: 1.6rem;
      font-size: 1.1rem;
      font-family: MicrosoftYaHei;
      // font-weight: bold;
      color: #357bff;
      line-height: 1.5rem;
      margin-left: 0.3rem;
    }

    .text {
      //   width: 4rem;
      height: 1.4rem;
      font-size: 1rem;
      font-family: MicrosoftYaHei;
      color: #333333;
      line-height: 1.4rem;
      margin-top: 0.7rem;
    }
  }

  .clearance {
    .title {
      text-align: center;
      margin-top: 2.2rem;
      font-size: 1.6rem;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 2.1rem;
    }

    .details {
      width: 35.1rem;
      height: 37rem;
      background: #f0f5ff;
      margin: 0 auto;

      .ImgBg {
        display: inline-block;
        // margin: 0 auto;
        margin-top: 1.85rem;
        width: 31.5rem;
        height: 17.1rem;
        margin-left: 1.8rem;
      }

      .bgContent {
        width: 31.4rem;
        height: 10rem;
        font-size: 1.2rem;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 2rem;
        text-indent: 2em;
        margin: 0 auto;
        margin-top: 1.5rem;
      }

      .pBG {
        font-weight: 700;
        margin-top: 0.8rem;
      }
    }
  }

  .air {
    .title {
      text-align: center;
      font-size: 1.6rem;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 2.1rem;
      margin: 0 auto;
      margin-top: 2.2rem;
    }

    .bot {
      width: 35.1rem;
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .airList {
        width: 16.9rem;
        height: 14rem;
        margin-top: 1rem;
        position: relative;
        // display: flex;
        //   height: 200px;
        // background-color: red;
        //   float: left;
        // margin-left: .6rem;
        // margin-top: 1.1rem;

        .tImg,
        .airImg {
          width: 100%;
          height: 100%;
          display: inline-block;

        }

        .bText {
          position: absolute;
          display: block;
          width: 16.9rem;
          height: 3rem;
          background: rgba(0, 0, 0, 0.3);
          // opacity: 0.3;
          //   opacity: 0.3; 
          z-index: 100;
          bottom: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-content: center;

          .Itext {
            z-index: 9999;
            font-size: 1.2rem;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            line-height: 3rem;
            color: #ffffff;
            text-align: center;
          }
        }
      }
    }

    .airTabs {
      margin: 0 auto;

      /deep/ .el-tabs {
        margin: 0 auto;
        width: 35.1rem;

        // .el-tabs__header,
        // .el-tabs__nav,
        // .el-tabs__item {
        //   height: 2.8rem;
        // }

        // display: flex;
        // height: 100%;
        .el-tabs__content {
          width: 100%;
        }

        .el-tabs__header {
          width: 100%;
          display: flex;
          justify-content: space-around;

          border-bottom: 1px solid #cccccc;

          .el-tabs__item {
            margin-right: 11.9rem;
            font-size: 1.4rem;
            font-family: MicrosoftYaHei;
            color: #333333;
            line-height: 1.9rem;
            height: 2.8rem;
          }

          .el-tabs__item:last-child {
            margin: 0;
          }

          .el-tabs__item,
          .el-tabs__item:hover {
            color: #333;
            padding: 0;
          }

          .el-tabs__active-bar {
            display: none;
          }

          .is-active {
            color: #357bff !important;
            border-bottom: 3px solid #357bff;
          }
        }
      }
    }
  }

  .sea {
    width: 100vw;

    .title {
      text-align: center;
      margin-top: 2.2rem;
      font-size: 1.6rem;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 2.1rem;
      width: 100%;
    }

    .seeImg {
      width: 100%;
      height: 17.7rem;
      margin: 0 auto;
      margin-top: 2.5rem;
    }
  }

  .dianshan {
    width: 37.5rem;
    height: 31.6rem;
    background-image: url("~@/assets/jyd_home/chanpinfuwu/dswubg.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 1.3rem;

    .title {
      text-align: center;
      // display: block;
      // margin: 0 auto;
      padding-top: 2rem;
      font-size: 1.6rem;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ffffff;
      line-height: 2.1rem;
    }

    .content {
      // padding-left: 2.4rem;
      padding: 0 1.5rem;
      // margin-top: -1.2rem;
      display: flex;
      justify-content: space-between;
      align-content: flex-start;
      flex-wrap: wrap;
      height: 2.65rem;

      .dianT {
        float: left;
        margin: 1.5rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 8.5rem;

        &:nth-child(3n) {
          margin-right: 0rem;
        }

        img {
          max-height: 2.8rem;
          min-height: 1.9rem;
        }
      }

      .dText {
        margin-top: 1.2rem;
        height: 1.5rem;
        font-size: 1.1rem;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        line-height: 1.5rem;
        width: 100%;
        text-align: center;
      }
    }
  }

  .domestic {
    .service {
      width: 100%;
      height: 100%;
      // display: flex;
      flex-direction: column;

      // justify-content: space-between;
      .left {
        // width: 474px;
        // height: 397px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-content: space-between;
        height: 13.6rem;

        .card {
          width: 11rem;
          height: 6.3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background-color: #f8f9fc;
          position: relative;
          cursor: pointer;

          // margin-top: 1.1rem;
          img {
            width: 2.6rem;
            height: 2.6rem;
            // margin-bottom: 15px;
            // margin: 1rem auto auto 0;
            margin-left: auto;
            margin-right: auto;
            z-index: 10;
          }

          span {
            font-size: 1.1rem;
            font-family: MicrosoftYaHei;
            color: #333333;
            line-height: 21px;
            z-index: 10;
          }
        }

        .card:nth-child(3) {
          img {
            width: 30px;
            height: 30px;
          }
        }

        .card-active {
          background-position: 50% 50%;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-image: url("../../../assets/jyd_home/chanpinfuwu/chanpinBg/b1.png");

          span {
            color: #fff;
          }

          .mengbans {
            display: flex;
          }
        }

        .mengbans {
          display: none;
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 8;
          background-position: 50% 50%;
          background-size: 100% 100%;
          background-repeat: no-repeat;

          .mengban {
            width: 100%;
            height: 100%;
            background-color: rgba(64, 158, 255, 0.73);
          }
        }
      }

      .right {
        width: 35.2rem;
        height: 17.1rem;
        margin-top: 2rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .title {
      text-align: center;
      margin-top: 2.3rem;
      font-size: 1.6rem;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 2.1rem;
    }

    .domesticTabs {
      margin: 0 auto;

      /deep/ .el-tabs {
        margin: 0 auto;
        width: 35.1rem;

        // display: flex;
        // height: 100%;
        .el-tabs__content {
          width: 100%;
        }

        .el-tabs__header {
          width: 100%;
          display: flex;
          justify-content: space-around;

          border-bottom: 1px solid #cccccc;

          #tab-one {
            margin-right: 6.4rem;
            margin-left: 0.65rem;
          }

          #tab-two {
            margin-right: 5rem;
          }



          .el-tabs__item,
          .el-tabs__item:hover {
            color: #333;
            padding: 0;
          }

          .el-tabs__active-bar {
            display: none;
          }

          .is-active {
            color: #357bff !important;
            border-bottom: 3px solid #357bff;
          }
        }
      }

      .transport {
        width: 35.1rem;
        height: 34.6rem;
        padding-top: 1.7rem;
        background: #f0f5ff;

        .tramBox {
          // display: inline-block;
          margin: 0 auto;
          // padding-top: 1.7rem;
          width: 31.8rem;
          height: 17.1rem;
        }

        .text {
          margin: 0 auto;
          margin-top: 1.9rem;
          width: 8.4rem;
          height: 1.9rem;
          font-size: 1.4rem;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #333333;
          line-height: 1.9rem;
        }

        .boxContent {
          float: left;
          width: 15.3rem;
          height: 3.6rem;
          background: #ffffff;
          border-radius: 0.2rem;
          margin-left: 1.6rem;
          margin-top: 1.2rem;
          font-size: 1.3rem;
          font-family: MicrosoftYaHei;
          color: #333333;
          line-height: 3.6rem;
          text-align: center;

          .text {
            font-size: 1.3rem;
            font-family: MicrosoftYaHei;
            color: #333333;
            line-height: 0.6rem;
            text-align: center;
          }
        }
      }

      .railway {
        width: 35.1rem;
        height: 34.6rem;
        padding-top: 1.7rem;
        background: #f0f5ff;

        .railwayBox {
          width: 32.1rem;
          height: 17rem;
          margin: 0 auto;
        }

        .railwayText {
          margin-top: 1.9rem;
          margin-left: 1.7rem;
          font-size: 1.4rem;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #333333;
          line-height: 1.9rem;
        }

        .railwayText1 {
          margin-top: 0.8rem;
          margin-left: 1.7rem;
          font-size: 1.2rem;
          font-family: MicrosoftYaHei;
          color: #333333;
          line-height: 1.6rem;
        }

        .railwayText2 {
          margin-left: 1.7rem;
          margin-top: 1.6rem;
          font-size: 1.4rem;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #333333;
          line-height: 1.9rem;
        }

        .railwayText3 {
          margin-left: 1.7rem;
          margin-top: 0.9rem;
          font-size: 1.2rem;
          font-family: MicrosoftYaHei;
          color: #333333;
          line-height: 1.8rem;
        }
      }
    }
  }

  .keepGrain {
    .title {
      // margin: 0 auto;
      text-align: center;
      margin-top: 2.2rem;
      font-size: 1.6rem;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 2.1rem;
    }

    .keepGrainTabs {
      margin: 0 auto;

      /deep/ .el-tabs {
        margin: 0 auto;
        width: 35.1rem;

        // display: flex;
        // height: 100%;
        .el-tabs__content {
          width: 100%;

          #pane-oneC {
            padding: 0 1rem 0 2.5rem;
          }
        }

        .el-tabs__header {
          width: 100%;
          display: flex;
          justify-content: space-around;

          border-bottom: 1px solid #cccccc;
          margin-bottom: 2.3rem;

          .el-tabs__item {
            margin: 0;
            height: 2.8rem;
            line-height: 1.9rem;
            font-size: 1.4rem;
            font-family: MicrosoftYaHei;
            color: #333333;
          }

          .el-tabs__item,
          .el-tabs__item:hover {
            color: #333;
            padding: 0;
          }

          .el-tabs__active-bar {
            display: none;
          }

          div#tab-oneC {
            margin-right: 4.3rem;
          }

          div#tab-twoC {
            margin-right: 5.7rem;
          }

          .is-active {
            color: #357bff !important;
            border-bottom: 3px solid #357bff;
          }
        }
      }
    }

    .tabPaneContent {
      width: 100%;
      // height: calc((105/10)*1rem);
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      // padding-top: 65px;
      .card {
        // width: 300px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 5;

        .num {
          color: #357BFF;
          font-size: calc((18/10)*1rem);
          font-family: Oswald-Medium, Oswald;
          font-weight: 500;
          color: #357BFF;
          line-height: calc((25/10)*1rem);
          margin-bottom: calc((4/10)*1rem);

          // line-height: 65px;
          .symbol {
            font-size: calc((11/10)*1rem);
            font-family: 'MicrosoftYaHei';
            color: #357BFF;
            line-height: calc((15/10)*1rem);
          }
        }

        .text {
          font-size: calc((10/10)*1rem);
          font-family: MicrosoftYaHei;
          color: #333333;
          line-height: calc((14/10)*1rem);
          margin-top: calc((3/10)*1rem);
          margin-bottom: calc((17/10)*1rem);
          text-align: center;

        }
      }
    }

    .storehouseBig {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      width: 31.1rem;

      // margin: 0 auto;
      .storehouse {
        margin-left: 1rem;
        margin-top: 2.3rem;
        text-align: center;

        .storehouseNum {
          font-size: 1.8rem;
          font-family: Oswald-Medium, Oswald;
          font-weight: 500;
          color: #357bff;
          line-height: 2.5rem;
          margin-left: 2rem;
          text-align: center;

          .unit {
            // width: 1.6rem;
            // height: 1.6rem;
            font-size: 1.1rem;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #357bff;
            line-height: 1.6rem;
          }
        }

        .storehouseText {
          // width: 5rem;
          height: 1.4rem;
          font-size: 1rem;
          font-family: MicrosoftYaHei;
          color: #333333;
          line-height: 1.4rem;
          margin-top: 0.4rem;
        }
      }
    }

    .installation {
      display: flex;
      justify-content: space-between;
      align-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      height: 25.1rem;

      .box {
        width: 11rem;
        height: 7.7rem;
        position: relative;

        .boxBot {
          width: 10.96rem;
          height: 2.65rem;
          background: rgba(0, 0, 0, 0.3);
          // opacity: 0.44;
          z-index: 100;
          position: absolute;
          // display: inline-block;
          bottom: 0;
          left: 0;

          .text {
            display: inline-block;
            width: 100%;
            font-size: 1.1rem;
            font-family: MicrosoftYaHei;
            color: #ffffff;
            line-height: 2.65rem;
            text-align: center;
          }
        }
      }
    }

    .houseContent {
      .houseText {
        width: 35.1rem;
        height: 10.8rem;
        font-size: 1.2rem;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 1.8rem;
        margin-top: 1.1rem;
        text-indent: 2em;
      }
    }
  }

  .technological {

    // width: 35.1rem;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    .title {
      text-align: center;
      // margin: 0 auto;
      margin-top: 2rem;
      font-size: 1.6rem;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 2.1rem;
    }
  }
}

.product_title {
  font-size: 1.6rem;
  padding-bottom: 1.7rem;
}
</style>
