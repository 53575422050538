<template>
  <div class="qualification">
    <div class="swiperBg">
      <div class="swiper-container qualificationSwiper">
        <div class="swiper-wrapper " v-if="data.length > 0">
          <div class="swiper-slide" v-for="(item, index) in data" :key="index">
            <div class="img">
              <div>
                <img class="imgSmall" :src="item.img"
                  :style="'width: ' + (item.width1 / 10) + 'rem; height: ' + (item.height1 / 10) + 'rem;'" />
                <img class="imgBig" :src="item.img1" preview="8" :preview-text="item.title"
                  :style="'width: ' + (item.width / 10) + 'rem; height: ' + (item.height / 10) + 'rem;'" />
              </div>
            </div>
            <div class="word">
              <div>{{ item.title }}</div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>

      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
import { slideList } from './data'
export default {
  data() {
    return {
      data: []
    }
  },
  created() {
    this.data = slideList
  },
  mounted() {
    new Swiper(".qualificationSwiper", {
      // 如果需要前进后退按钮
      observer: true, // 修改swiper自己或子元素时，自动初始化swiper
      observeParents: true,
      autoplay: {
        delay: 2000,//自动切换时间；
        //与loop有关，设置为true，loop失效
        stopOnLastSlide: false,
        //用户操作之后，是否禁止自动切换（默认true禁止/设置false允许）
        disableOnInteraction: true,
      },
      spaceBetween: 25,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        bulletClass: 'my-pagination',
        bulletActiveClass: 'my-pagination-active',
      },
      effect: "coverflow",
      centeredSlides: true,
      slidesPerView: 3,
      rewind: true,
      loop: true,
      coverflowEffect: {
        rotate: 0,//slide做3d旋转时Y轴的旋转角度
        stretch: -2,//每个slide之间的拉伸值，越大slide靠得越紧。5.3.6 后可使用%百分比
        depth: 77,//slide的位置深度。值越大z轴距离越远，看起来越小。
        modifier: 1,//depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。
        slideShadows: false,//是否开启slide阴影
      },
    });
  },
  methods: {


  },
}
</script>

<style lang="less" scoped>
.qualification {
  width: 100%;
  height: calc((192/10)*1rem);
  background: #fff;
  // padding: 0 calc((12/10)*1rem);
}

.swiperBg {
  width: 100%;
  margin: 0 auto;
  // padding: 0 calc((12/10)*1rem);

  .swiper-container {
    width: 100%;
    height: calc((177/10)*1rem);

    .swiper-slide {
      height: calc((142/10)*1rem);
      // background: #FFFFFF;
      box-shadow: 0px 2px 12px 0px rgba(207, 207, 207, 0.5);
      border-radius: 2px;
      text-align: center;
      font-size: 18px;
      background: #fff;
      position: relative;

      .img {
        height: calc((108/10)*1rem);
        width: 100%;
        // padding: 10px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px dashed #b1b1b1;

        div {
          .imgSmall {
            display: block;
          }

          .imgBig {
            display: none;
          }
        }

        div:hover {
          .imgSmall {
            display: none;
          }

          .imgBig {
            display: block;
          }
        }
      }

      .word {
        position: absolute;
        bottom: calc((9/10)*1rem);
        width: 100%;
        padding: 0 calc((1/10)*1rem);
        height: calc((15/10)*1rem);
        font-size: calc((11/10)*1rem);
        line-height: calc((15/10)*1rem);
        font-family: MicrosoftYaHei;
        color: #333333;
        display: flex;
        justify-content: center;
        align-items: center;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;

      }

      &:hover {
        .imgSmall {
          display: none;
        }

        .imgBig {
          display: block;
        }

        .word {
          bottom: calc((7/10)*1rem);
        }
      }
    }

    .swiper-pagination {
      z-index: 10;
    }
  }
}
</style>