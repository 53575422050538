<template>
  <div class="Development">
    <div class="swiperBg">
      <div class="swiper-container DevelopmentSwiper">
        <div class="swiper-wrapper " v-if="data.length > 0">
          <div class="swiper-slide" v-for="(item, index) in data" :key="'fz' + index">
            <div class="Development_img">

              <img class="imgurl" :src="item.path" preview="3" />

            </div>
            <div class="word">
              <div>{{ item.title }}</div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>

      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
// import { Introduc } from '../components/data'
export default {
  data() {
    return {
      data: [{ path: require("@/assets/jyd_home/introduce/fz1.png") },
      { path: require("@/assets/jyd_home/introduce/fz2.png") },
      { path: require("@/assets/jyd_home/introduce/fz3.png") }]
    }
  },
  created() {
  },
  mounted() {
    new Swiper(".DevelopmentSwiper", {
      // 如果需要前进后退按钮
      observer: true, // 修改swiper自己或子元素时，自动初始化swiper
      observeParents: true,
      autoplay: {
        delay: 2000,//自动切换时间；
        //与loop有关，设置为true，loop失效
        stopOnLastSlide: false,
        //用户操作之后，是否禁止自动切换（默认true禁止/设置false允许）
        disableOnInteraction: true,
      },
      spaceBetween: 13,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        bulletClass: 'my-paginationH',
        bulletActiveClass: 'my-pagination-activeH',
      },
      // effect: "coverflow",
      centeredSlides: true,
      // slidesPerView: 3,
      // rewind: true,
      loop: true,
      coverflowEffect: {
        rotate: 0,//slide做3d旋转时Y轴的旋转角度
        stretch: 0,//每个slide之间的拉伸值，越大slide靠得越紧。5.3.6 后可使用%百分比
        depth: 0,//slide的位置深度。值越大z轴距离越远，看起来越小。
        modifier: 0,//depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。
        slideShadows: false,//是否开启slide阴影
      },
    });
  },
  methods: {


  },
}
</script>

<style lang="less" scoped>
.Development {
  width: 100%;
  height: calc((154/10)*1rem);
  background-image: url('../../../assets/jyd_home/introduce/bg3.png');
  background-position: 50% 50%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 calc((12/10)*1rem);
}

.swiperBg {
  width: 100%;
  margin: 0 auto;
  // padding: 0 calc((12/10)*1rem);

  .swiper-container {
    width: 100%;
    height: calc((154/10)*1rem);
    padding: calc((31/10)*1rem) calc((6/10)*1rem) 0;

    .swiper-slide {
      height: calc((86/10)*1rem);
      border-radius: 2px;
      text-align: center;
      font-size: 18px;
      position: relative;

      .Development_img {
        width: 100%;
        height: 100%;

        .imgurl {
          height: 100%;
          max-width: 100%;
        }
      }

    }

    .swiper-pagination {
      z-index: 10;
    }
  }
}
</style>