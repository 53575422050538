<template>
  <div class="container">
    <div class="main">
      <div class="columnCount">
        <img src="@/assets/img/JYDlogo.png" style="width: 118px;height:71px" alt />
        <div class="zhixian"></div>
        <div class="texts">
          <div class="text">
            <img src="@/assets/img/icon/firm.png" style="width:20px;height:20px" alt />
            <span>公司： 深圳｜东莞｜南京｜西安｜香港</span>
          </div>
          <div class="text">
            <img src="@/assets/img/icon/address.png" style="width:20px;height:20px" alt />
            <span>地址：深圳市盐田区沙头角保税区4栋4楼</span>
          </div>
          <div class="text">
            <img src="@/assets/img/icon/phone.png" style="width:20px;height:20px" alt />
            <span>电话： 0755-2536 9609 丨 0755-25595489</span>
          </div>
        </div>
      </div>
      <!--  <div class="logTop">
          <div class="logoIcon">
            <img src="@/assets/img/JYDlogo.png" style="width: 100%" alt />
          </div>
          <div class="logoEWM">
            <div class="word">微信公众号</div>
            <img src="@/assets/img/gongzhonghao.png" alt />
          </div>
        </div>
        <div class="address">
          <div class="address1">全国统一服务热线：</div>
          <div class="address2">400-8888-8888</div>
          <div class="address3">©2017佳裕达版权所有 粤ICP备00000000号</div>
      </div>-->
      <div class="imgs flexCC">
        <div class="logoEWM flexCC">
          <img src="@/assets/img/gongzhonghao.png" alt />
          <div class="word">关注微信公众号</div>
        </div>
        <div class="logoEWM flexCC">
          <img src="@/assets/img/qiyevideo.png" alt />
          <div class="word">企业宣传视频</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {};
  }
};
</script>
<style scoped lang="less">
.container {
  width: 100%;
  background: #252838;

  .main {
    background: #252838;
    box-sizing: border-box;
    width: 809.3px;
    height: 332px;
    margin: 0 auto;
    color: #fff;

    // overflow: hidden;
    // text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logoEWM {
      width: 99.3px;
      height: 115px;
      margin-left: 72px;

      flex-direction: column;

      img {
        width: 100%;
        height: 99px;
        margin-bottom: 8px;
      }

      .word {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 18px;
      }
    }

    .columnCount {
      width: 440px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 98px;

      .zhixian {
        width: 1px;
        height: 78px;
        border: 1px solid #a6a6a6;
      }

      span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 42px;
      }

      .text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 266px;
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 7px;
      }
    }
  }
}

.flexCC {
  display: flex;
  justify-content: center;
  align-items: center;
}</style>
