<template>
  <div class="product">
    <!-- 集团介绍 -->
    <div class="proGroup">
      <div class="proGroupTop" id="groupProfile">
        <div class="left">
          <div class="title">集团简介</div>
          <!-- <div class="title2">GROUP PROFILE</div> -->
          <p class="list">
            佳裕达集团成立于2009年，总部位于广东省深圳市。经过十多年的不懈努力，现已发展成为立足粤港澳大湾区、面向全国、布局全球的大型AAAA级综合服务型物流企业。服务范围涵盖空运服务、海运服务、陆运、清关、物流配送、仓储、电子商务、跨境运输、物流软件系统开发等各个领域。
          </p>
          <p class="list" style="margin-top: 20px">
            佳裕达集团以致力于发展“成为全球领先综合物流服务商”为愿景，将先进的物流技术和科学的营运管理融入到为客户提供安全、便捷的个性化物流服务当中，依托资源优势、资源整合能力和精益求精的科学管理方式，服务全球各行业优秀企业，有效降低企业物流成本，提高经济效益，不断为客户创造价值！
          </p>
          <p class="list" style="margin-top: 20px">
            2023年4月21日，集团旗下的Jayud Global Logistics Ltd（以下简称“佳裕达”），成功在美国纳斯达克IPO上市，股票代码JYD。至此，佳裕达全面开启全球化旅程！
          </p>
          <p class="list" style="margin-top: 20px">
            佳裕达集团与多个国内外一线知名品牌合作，涉及领域包括新能源、汽车、医疗、消费电子、母婴健康、儿童玩具、户外运动、智能穿戴等多个行业。其中与宁德时代、VIVO、OPPO、光弘集团、欣旺达、小米、联想、TCL、安克、大族激光、雅昌、中粮集团、派克、伊英克斯等数家行业头部企业保持紧密的战略合作关系。
          </p>
        </div>
        <div class="right">
          <img src="@/assets/product/lou.png" style="width: 100%" alt="" />
        </div>
      </div>
      <div class="proGroupBot"></div>
    </div>
    <!-- 组织架构 -->
    <div class="framWort" id="organizationalStructure">
      <div class="title">组织架构</div>
      <!-- <div class="titles">FRAMEWORK</div> -->
      <div class="img">
        <img
          src="@/assets/product/集团架构.png"
          style="width: 1200px; margin-top: 73px"
          alt=""
        />
      </div>
    </div>

    <!-- 发展历程 -->
    <div class="histiry" id="developmentHistory">
      <div class="title">发展历程</div>
      <!-- <div class="titles">DEVELOPMENT HISTORY</div> -->
      <div class="historyList">
        <div class="historyImg">
          <div class="carsel">
            <el-carousel trigger="click" height="292px">
              <el-carousel-item
                v-for="(item, index) in historyList"
                :key="index"
              >
                <img :src="item.img"/>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
    <!-- 集团战略 -->
    <div class="group" id="groupStrategy">
      <div class="title">集团战略</div>
      <!-- <div class="titles">GROUP STRATEGY</div> -->
      <div class="box">
        <div class="boxList">
          <ul>
            <li
              style="list-style-type: disc; margin-left: 104px"
              class="gruopList"
            >
              构建集团强大的分销网络
            </li>
            <li
              style="
                list-style-type: disc;
                margin-left: 104px;
                margin-top: 27px;
              "
              class="gruopList"
            >
              针对中国各垂直行业制定物流报关解决方案
            </li>
            <li
              style="
                list-style-type: disc;
                margin-left: 104px;
                margin-top: 27px;
              "
              class="gruopList"
            >
              对集团的智能物流平台系统加大投入，实现智能化
            </li>
            <li
              style="
                list-style-type: disc;
                margin-left: 104px;
                margin-top: 27px;
              "
              class="gruopList"
            >
              招揽行业人才，引领集团发展
            </li>
            <li
              style="
                list-style-type: disc;
                margin-left: 104px;
                margin-top: 27px;
              "
              class="gruopList"
            >
              通过投资或收购拓展集团业务和服务能力，形成产业闭环
            </li>
          </ul>
        </div>
        <div class="boxPic">
          <img src="@/assets/product/group.png" style="width: 100%" alt="" />
        </div>
      </div>
    </div>
    <!-- 集团文化 -->
    <div class="culture" id="groupCulture">
      <div class="culTop">
        <div class="titles">
          <div class="title1">集团文化</div>
          <!-- <div class="title2">GROUP CULTURE</div> -->
        </div>
      </div>
      <div class="culBot">
        <div class="pic">
          <img src="@/assets/product/cultue.png" style="width: 100%" alt="" />
        </div>
        <div class="pics">
          <div class="tit">使命</div>
          <div class="tit2">为客户提供安全、便捷的个性化物流服务</div>
          <div class="tit" style="margin-top: 42px">愿景</div>
          <div class="tit2">成为全球领先综合物流服务商</div>
          <div class="tit" style="margin-top: 42px">核心价值</div>
          <div class="tit2">积极、自信、团结、创新</div>
        </div>
      </div>
    </div>
    <!-- <div class="honors">
      <div class="content">
        <div class="title">荣誉资质</div>
      </div>
      <div class="swiperBg">
        <div class="swiper-container mySwiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in slideList"
              :key="index"
            >
              <div class="img">
                <img :src="item.img" alt="" />
              </div>
              <div class="word">
                <div>道路运输许可资质</div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
          <div class="swiper-button-prev">
            <img
              src="@/views/home/service/components/com/img/left.png"
              alt
              class="button-jiangtou"
            />
          </div>
          <div class="swiper-button-next">
            <img
              src="@/views/home/service/components/com/img/right.png"
              alt
              class="button-jiangtou"
            />
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
export default {
  components: {},
  data() {
    return {
      slideList: [
        {
          img: require("@/assets/product/shu5.png"),
        },
        {
          img: require("@/assets/product/shu4.png"),
        },
        {
          img: require("@/assets/product/shu2.png"),
        },
        {
          img: require("@/assets/product/shu3.png"),
        },
        {
          img: require("@/assets/product/shu1.png"),
        },
      ],
      historyList: [
        {
          img: require("@/assets/product/fazhan4.png"),
        },
        {
          img: require("@/assets/product/fazhan7.png"),
        },
        {
          img: require("@/assets/product/fazhan5.png"),
        },
      ],
    };
  },
  created(){
    this.$nextTick(() => this.getGroupProfile())
    this.$nextTick(() => this.getOrganizationalStructure())
    this.$nextTick(() => this.getDevelopmentHistory())
    this.$nextTick(() => this.getGroupStrategy())
    this.$nextTick(() => this.getGroupCulture())
  },
  mounted() {
    new Swiper(".mySwiper", {
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // rewind: true,
      loop: true,
      slidesPerView: 5,
      // spaceBetween: 10,
      spaceBetween: 10,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      effect: "coverflow",
      coverflowEffect: {
        rotate: 0,
        stretch: -50,
        depth: 77,
        modifier: 2,
        slideShadows: false,
      },
    });
  },
  methods:{
    getGroupProfile() {
      let select = localStorage.getItem("groupProfile");
      let elm = document.getElementById(select);
      if (select) {
        elm.scrollIntoView(true);
      }
    },
    getOrganizationalStructure() {
      let select = localStorage.getItem("organizationalStructure");
      let elm = document.getElementById(select);
      if (select) {
        elm.scrollIntoView(true);
      }
    },
    getDevelopmentHistory() {
      let select = localStorage.getItem("developmentHistory");
      let elm = document.getElementById(select);
      if (select) {
        elm.scrollIntoView(true);
      }
    },
    getGroupStrategy(){
      let select = localStorage.getItem("groupStrategy");
      let elm = document.getElementById(select);
      if (select) {
        elm.scrollIntoView(true);
      }
    },
    getGroupCulture(){
      let select = localStorage.getItem("groupCulture");
      let elm = document.getElementById(select);
      if (select) {
        elm.scrollIntoView(true);
      }
    },
  },
  destroyed() {
    localStorage.setItem("groupProfile", "");
    localStorage.setItem("organizationalStructure", "");
    localStorage.setItem("developmentHistory", "");
    localStorage.setItem("groupStrategy", "");
    localStorage.setItem("groupCulture", "");
  },
};
</script>

<style lang="less" scoped>
.product {
  width: 100%;
  height: 100%;
  .proGroup {
    height: 648px;
    width: 100%;
    .proGroupTop {
      margin: 40px auto 0 auto;
      width: 1200px;
      height: 589px;
      display: flex;
      .left {
        // margin-top: 82px;
        margin-bottom: -110px;
        width: 623px;
        height: 453px;
        .title {
          font-size: 24px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #333333;
          line-height: 31px;
        }
        .title2 {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #333333;
          line-height: 21px;
          margin-top: 7px;
        }
        .list {
          margin-top: 56px;
          text-indent: 24px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #333333;
          line-height: 26px;
        }
      }
      .right {
        margin-left: 136px;
        width: 442px;
        height: 573px;
      }
    }
    .proGroupBot {
      width: 100%;
      height: 139px;
      margin: -80px auto;
      background-color: #f8f9fc;
    }
    .side {
      position: fixed;
      top: 247px;
      right: 138px;
    }
  }
  .framWort {
    height: 896px;
    width: 1200px;
    margin: 0 auto;
    .title {
      padding-top: 46px;
      text-align: center;
      font-size: 24px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 31px;
    }
    .titles {
      margin-top: 7px;
      text-align: center;
      font-size: 21px;
      font-family: MicrosoftYaHei;
      color: #333333;
      line-height: 27px;
    }
  }
  .histiry {
    margin-top: 46px;
    // background-color: red;
    height: 730px;
    width: 100%;
    // margin: 0 auto;

    .title {
      padding-top: 20px;
      text-align: center;
      font-size: 24px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 31px;
    }
    .titles {
      margin-top: 7px;
      text-align: center;
      font-size: 21px;
      font-family: MicrosoftYaHei;
      color: #333333;
      line-height: 27px;
    }
    .historyList {
      background-image: url("~@/assets/product/bgHis.png");
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      // margin-top: 66px;
      height: 616px;
      width: 100%;
      // margin: 0 auto;
      .historyImg {
        width: 1200px;
        height: 616px;
        margin: 0 auto;
        margin-top: 66px;
        .carsel {
          margin: 149px auto;
          display: inline-block;
          width: 1244px;
          height: 292px;
        }
      }
      /deep/.el-carousel__arrow--left {
        left: 6px;
        top: 152px;
      }
      /deep/.el-carousel__arrow--right {
        right: 6px;
        top: 152px;
      }
      /deep/ul.el-carousel__indicators.el-carousel__indicators--horizontal {
    display: none;
}
    }
  }
  .group {
    margin: 37px auto 0;
    width: 1200px;
    height: 698px;
    .title {
      padding-top: 69px;
      text-align: center;
      font-size: 24px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 31px;
    }
    .titles {
      margin-top: 7px;
      text-align: center;
      font-size: 21px;
      font-family: MicrosoftYaHei;
      color: #333333;
      line-height: 27px;
    }
    .box {
      margin-top: 20px;
      position: relative;
      display: flex;
      justify-content: center;
      .boxList {
        position: absolute;
        top: 1%;
        left: 1%;
        margin-left: 59px;
        margin-top: 201px;
        width: 592px;
        height: 276px;
        background: #ffffff;
        box-shadow: 0px 5px 19px 0px rgba(191, 191, 191, 0.5);
        border-radius: 2px;
        .circle {
          border: 8px solid #357bff;
          //   width: 18px;
          //   height: 18px;
          border-radius: 8px;
          background: #357bff;
        }
        .gruopList {
          margin-top: 29px;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #333333;
          line-height: 21px;
        }
        .gruopList:hover{
          color: #357bff;
        }
      }
      .boxPic {
        margin-left: 518px;
        margin-top: 68px;
        width: 556px;
        height: 351px;
      }
    }
  }
  .culture {
    width: 100%;
    height: 636px;
    .culTop {
      height: 302px;
      width: 100%;
      background-color: #f8f9fc;
      .titles {
        width: 1200px;
        height: 302px;
        margin: 0 auto;
        .title1 {
          padding-top: 35px;
          text-align: center;
          font-size: 24px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #333333;
          line-height: 31px;
        }
        .title2 {
          margin-top: 7px;
          text-align: center;
          font-size: 21px;
          font-family: MicrosoftYaHei;
          color: #333333;
          line-height: 27px;
        }
      }
    }
    .culBot {
      width: 1200px;
      height: 348px;
      margin: 0 auto;
      display: flex;
      position: relative;
      .pic {
        width: 507px;
        height: 413px;
        position: absolute;
        top: -150px;
        left: 20px;
      }
      .pics {
        background-image: url("~@/assets/product/clutres.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        width: 694px;
        height: 413px;
        position: absolute;
        top: -150px;
        left: 520px;
        .tit {
          margin-top: 68px;
          font-size: 21px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #ffffff;
          line-height: 27px;
          margin-left: 70px;
        }
        .tit2 {
          margin-top: 12px;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 21px;
          margin-left: 70px;
        }
      }
    }
  }
  .honors {
    margin: 0 auto;
    width: 100%;
    background-image: url("~@/assets/product/Bg.png");
    height: 713px;
    .content {
      width: 100%;
      margin-bottom: 92px;
      padding-top: 51px;
      .title {
        text-align: center;
        font-size: 21px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #333333;
        line-height: 27px;
      }
      .title1 {
        margin-top: 7px;
        text-align: center;
        font-size: 21px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 27px;
      }
    }
    .swiperBg {
      width: 100%;
      margin: 0 auto;

      .swiper-container {
        width: 1551px;
        height: 398px;
        .swiper-slide {
          height: 398px;
          // background: #FFFFFF;
          box-shadow: 0px 2px 12px 0px rgba(207, 207, 207, 0.5);
          border-radius: 2px;
          text-align: center;
          font-size: 18px;
          background: #fff;
          .img {
            height: 319px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px dashed #b1b1b1;
          }
          .word {
            height: 78px;
            width: 100%;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}

::marker {
  color: #357bff;
  font-size: 19px;
}
.swiper-button-prev,
.swiper-button-next {
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.36);
  background-image: "";
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  bottom: 111px;
}
.swiper-button-next {
  right: 31px;
}
.swiper-button-prev {
  left: 31px;
}
.button-jiangtou {
  width: 13px;
  height: 20px;
}
.carImg {
  flex-direction: column;
  // display: flex;
  // align-items: flex-end;
  .line {
    width: 328px;
    height: 68px;
    border-bottom: 1px dashed red;
  }
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  display: none;
}
</style>
