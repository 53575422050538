<template>
  <div class="content">
    <!-- 顶部 -->
    <div class="top">
      <div class="topCont">
        <div class="topList">
          <span>新闻资讯</span>
          <i class="el-icon-arrow-right"></i>
          <span>行业资讯</span>
          <i class="el-icon-arrow-right"></i>
          <span
            >进口汽车费用削减20%-深圳海关助力自贸区建设
            支持平行汽车进口政策落地</span
          >
        </div>
      </div>
    </div>
    <!-- 新闻资讯 -->
    <div class="newMessage">
      <div class="newMsgCont">
        <h2 class="title">
          进口汽车费用削减20%-深圳海关助力自贸区建设 支持平行汽车进口政策落地
        </h2>
        <div class="time">发布时间：2015-05-06</div>
        <p class="list" >
          中国（广东）自由贸易试验区前海蛇口片区日前挂牌。深圳海关将从突出深港合作、依托创新驱动、面向“一带一路”、推动互联互通、促进贸易便利等5个方面，全力支持自贸片区建设发展。
        </p>
        <p class="list" style="margin-top:0">
          深圳海关将打造前海蛇口片区内海关特殊监管区域货物经陆路口岸进出香港的物流直通通道，减少了转关申报、到位核销等多个环节，通关时间可缩短一半。据深圳海关介绍，该关还将全力支持推动平行汽车进口业务的落地实施，建立与平行汽车进口相适应的海关监管模式，为企业提供更高效的通关服务，汽车平行进口将减少经销的中间环节，消费者购买进口车成本或可减少20%左右。（周海玉
          陈奕槟 汪绍文）
        </p>
        <img src="@/assets/news/bigSea.png" alt="" style="margin-top:29px;">
          <p class="list">
          中国（广东）自由贸易试验区前海蛇口片区日前挂牌。深圳海关将从突出深港合作、依托创新驱动、面向“一带一路”、推动互联互通、促进贸易便利等5个方面，全力支持自贸片区建设发展。
        </p>
        <p class="list" style="margin-top:0">
          深圳海关将打造前海蛇口片区内海关特殊监管区域货物经陆路口岸进出香港的物流直通通道，减少了转关申报、到位核销等多个环节，通关时间可缩短一半。据深圳海关介绍，该关还将全力支持推动平行汽车进口业务的落地实施，建立与平行汽车进口相适应的海关监管模式，为企业提供更高效的通关服务，汽车平行进口将减少经销的中间环节，消费者购买进口车成本或可减少20%左右。（周海玉
          陈奕槟 汪绍文）
        </p>
        <div class="buttoms">
          <div class="left">
            <el-button type="text" class="butto"><i class="el-icon-arrow-left"></i>上一篇</el-button>
          </div>
          <div class="right">
            <el-button type="text" class="butto">下一篇 <i class="el-icon-arrow-right"></i></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: 100%;
  .top {
    width: 100%;
    height: 60px;
    background-color: #f4f3f3;
    margin-top: 60px;
    .topCont {
      width: 1200px;
      margin: 0 auto;
      .topList {
        line-height: 60px;
      }
    }
  }
  .newMessage {
    width: 100%;
    height: 1197px;
    .newMsgCont {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      .title {
        margin-top: 38px;
        text-align: center;
        font-size: 24px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #333333;
        line-height: 31px;
      }
      .time {
        margin-top: 36px;
        text-align: center;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 21px;
      }
      .list {
        text-indent: 24px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 27px;
        margin-top: 16px;
      }
      .buttoms{
        margin-top: 56px;
        display: flex;
        justify-content: space-between;
        .butto{
          color: #333333;
        }
      }
    }
  }
}
</style>