<template>
  <div class="information">
    <div class="title">
      <div class="titleTop">
        <div class="card">
          <div class="number" style="margin-left:246px">
            <animate-number from="0" to="3586" duration="3000" style="font-weight:600"></animate-number>
          </div>
          <div class="titleName">累积服务客户</div>
          <!-- <span class="num">{{this.data.customer.num}}</span>
          <span class="text" style="line-height: 6px;">{{this.data.customer.text}}</span> -->
        </div>
        <div class="lines"></div>
        <div class="card">
          <div class="number">
            <animate-number from="0" to="40306" duration="3000" style="font-weight:600"></animate-number>
          </div>
          <div class="titleName">累积业务订单</div>
          <!-- <span class="num">{{this.data.order.num}}</span>
          <span class="text" style="line-height: 6px;">{{this.data.order.text}}</span> -->
        </div>
      </div>
      <div class="titleBot">
        <div v-for="(item, index) in this.data.data" :key="'titile' + index">
          <span class="num">
            <animate-number from="0" :to="item.num" duration="3000" style="font-weight:600;font-size:52px;margin-right: 15px"></animate-number>
            <!-- {{ item.num }} -->
            <span class="unit">{{ item.unit }}</span>
          </span>
          <span class="text">{{ item.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { information } from "../data";
export default {
  data() {
    return {
      data: {},
    };
  },
  created() {
    this.data = information;
  },
};
</script>

<style lang="less" scoped>
.title {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  .num {
    height: 76px;
    font-size: 56px;
    font-family: Oswald-Medium, Oswald;
    font-weight: 600;
    color: #357bff;
    line-height: 55px;
    letter-spacing: 1px;
    margin-top: 22px;
  }
  .text {
    // width: 126px;
    height: 29px;
    font-size: 21px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #333333;
    line-height: 29px;
    margin-top: 22px;
  }
  .titleTop {
    display: flex;
    width: 1200px;
    height: 156px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(184, 184, 184, 0.5);
    border-radius: 2px;
    position: absolute;
    top: -101px;
    z-index: 900;
    // left: 20%;
    // column-count: 2;
    // column-rule: 1px outset #cccccc;
    padding-top: 34px;
    .lines {
      // margin-top: ;
      width: 1px;
      height: 89px;
      border: 1px solid #cccccc;
    }
    .card {
      width: 1200px;
      height: 100%;
      text-align: center;
      .number {
        width: 118px;
        height: 76px;
        font-size: 50px;
        font-family: Oswald-Medium, Oswald;
        font-weight: 600;
        color: #357bff;
        line-height: 76px;
        letter-spacing: 1px;
        margin-left: 230px;
        margin-top: -12px;
        transform: scale(1, 1.2);
      }
      .titleName {
        // width: 126px;
        // height: 29px;
        font-size: 21px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #333333;
        line-height: 29px;
        margin-left: 18px;
        text-align: center;
      }
    }
    // div {
    //   height: 109px;
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    //   justify-content: center;
    // }
  }
  .titleBot {
    height: 300px;
    width: 1200px;
    // margin-left: 50%;
    // transform: translate(-48.5%, 0);
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      display: flex;
      height: 88.5px;

      align-items: center;
      flex-direction: column;
    }
    .num {
      font-size: 52px;
      line-height: 71px;
      letter-spacing: 0px;
    }
    .unit {
      font-size: 23px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #357bff;
      line-height: 32px;
      margin-left: -10px;
    }
  }
}
</style>