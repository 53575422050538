<template>
  <div class="System">
    <div class="content" v-for="(item, index) in platformList" :key="index" @click="jumpOutlink">
      <div class="imgCont">
        <img class="imgTit" style="width:100%" :src="item.img" alt="" />
      </div>
      <div class="contentTit">{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      platformList: [
        {
          img: require("@/assets/home/oms.png"),
          title: "OMS订单系统",
        },
        {
          img: require("@/assets/home/TMS.png"),
          title: "TMS智能运输",
        },
        {
          img: require("@/assets/home/wms.png"),
          title: "WMS仓储系统",
        },
        {
          img: require("@/assets/home/B2C.png"),
          title: "B2C电商物流",
        },
        {
          img: require("@/assets/home/baoguan.png"),
          title: "报关系统",
        },
        {
          img: require("@/assets/home/BI.png"),
          title: "BI数据分析",
        },
        {
          img: require("@/assets/home/IOT.png"),
          title: "IOT智能仓库",
        },
        {
          img: require("@/assets/home/BMS.png"),
          title: "BMS结算系统",
        },
        {
          img: require("@/assets/home/dakehu.png"),
          title: "大客户定制化",
        },
        {
          img: require("@/assets/home/wuliudashuju.png"),
          title: "物流大数据",
        },
        {
          img: require("@/assets/home/huodaicaozuoxitong.png"),
          title: "货代操作系统",
        },
        {
          img: require("@/assets/home/kehuguanlixitong.png"),
          title: "客户管理系统",
        },
        {
          img: require("@/assets/home/haiwai.png"),
          title: "海外仓系统",
        },
        {
          img: require("@/assets/home/xiaobao.png"),
          title: "小包系统",
        },
        {
          img: require("@/assets/home/gongyinlian.png"),
          title: "供应链系统",
        },
      ],
    }
  },
  created() {
  },

  methods: {
    jumpOutlink() {
      window.open('https://order.jayud.com')
    }

  },
}
</script>

<style lang="less" scoped>
.System {
  width: 100%;
  height: calc((435/10)*1rem);

  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
  width: 100%;

  .content {
    width: calc((110/10)*1rem);
    height: calc((74/10)*1rem);
    background-image: url('../../../assets/jyd_home/Intelligence/bg2.png');
    background-position: 50% 50%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-bottom: calc((12/10)*1rem);

    .imgCont {
      width: calc((28/10)*1rem);
      height: calc((28/10)*1rem);

      .imgTit {
        margin-top: calc((14/10)*1rem);
        margin-left: calc((41/10)*1rem);
      }
    }

    .contentTit {
      text-align: center;
      margin-top: calc((7/10)*1rem);
      font-size: calc((12/10)*1rem);
      font-family: MicrosoftYaHei;
      color: #333333;
      line-height: calc((16/10)*1rem);
    }
  }

  .content:hover {
    border-color: #357BFF;
    cursor: pointer;
  }
}
</style>