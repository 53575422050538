<template>
  <div class="tableVue">
    <div class="tableLise" v-if="data.data.length > 0">
      <div class="item" v-for="(item, index) in data.data" :key="'item' + index" @click="handleClick(item)">
        <div class="left">
          <div class="title">{{ item.title }} </div>
          <div class="level "> {{ item.level }} <div class="heng"> </div> {{ item.range }}</div>
        </div>
        <div class="right">
          <div class="num">{{ item.num }} </div>
          <div class="address">{{ item.address }} </div>
        </div>
      </div>
    </div>
    <div class="tableLise" v-else>空</div>
    <div class="buts">
      <div :class="data.present == 1 ? 'leftbut disable' : 'leftbut'"><img slot="suffix"
          :src="require('@/assets/jyd_home/Recruitment/left.png')" alt="" class="but_img"> 上一页 </div>
      <div class="total"><span class="present">{{ data.present }}</span>/{{ data.total }}</div>
      <div :class="data.present == data.total ? 'rightbut disable' : 'rightbut'"> 下一页 <img slot="suffix"
          :src="require('@/assets/jyd_home/Recruitment/right.png')" alt="" class="but_img"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          data: [], total: '1', present: '1'
        }
      }
    },
  },
  methods: {
    handleClick(item) {
      console.log(item);
      item.id = 1
      this.$router.push({
        path: '/details',
        query: {
          id: item.id,
          name: item.title
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
.tableVue {
  width: 100%;
  height: calc((432/10)*1rem);

  .item {
    width: 100%;
    height: calc((60/10)*1rem);
    border-bottom: calc((1/10)*1rem)solid #EDEDED;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    color: #333333;
    display: flex;
    justify-content: space-between;
    padding: calc((9/10)*1rem) 0;

    .left,
    .right,
    .level {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .right {
      align-items: flex-end;
    }

    .title {
      font-size: calc((13/10)*1rem);
      font-weight: bold;
      color: #333333;
      line-height: calc((17/10)*1rem);
    }

    .heng {
      width: calc((1/10)*1rem);
      height: calc((11/10)*1rem);
      border-left: calc((1/10)*1rem) solid #EDEDED;
      display: inline-block;
      margin: 0 calc((8/10)*1rem);
    }

    .address,
    .num,
    .range,
    .level {
      font-size: calc((12/10)*1rem);
      line-height: calc((16/10)*1rem);
    }

    .level {
      flex-direction: row;
      align-items: center;
    }

    .num {
      font-weight: bold;
    }

  }

  .buts {
    width: 100%;
    padding: calc((22/10)*1rem) calc((23/10)*1rem) calc((17/10)*1rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: calc((12/10)*1rem);
    font-family: MicrosoftYaHei;
    color: #333333;
    line-height: calc((16/10)*1rem);

    .present {
      color: #357BFF;
    }

    .disable {
      cursor: no-drop;
      background-color: rgba(0, 0, 0, 0.1);
      color: #C0C4CC;
      cursor: not-allowed;
      background-image: none;
      background-color: #FFF;
    }

    .leftbut,
    .rightbut {
      cursor: pointer;
      width: calc((85/10)*1rem);
      height: calc((24/10)*1rem);
      border-radius: calc((2/10)*1rem);
      border: calc((1/10)*1rem) solid #D5D5D5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 calc((18/10)*1rem);


      .but_img {
        width: calc((6/10)*1rem);
        height: calc((10/10)*1rem);
      }

    }
  }
}
</style>