<template>
  <div>
    <div class="airFreight">
      <div class="content">
        <div class="top" id="airAnchor">
          <div class="title">{{ data.title }}</div>
          <!-- <div class="titleE">{{data.titleE}}</div> -->
        </div>
        <div class="buttom">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane :label="data.charter" name="charter">
              <div class="tabPaneContent">
                <div class="card" v-for="(item, index) in data.charterData" :key="'tabPane' + index">
                  <img :src="requireImg(item.url)" alt />
                  <div class="text"></div>
                  <p class="text">{{ item.text }}</p>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane :label="data.carry" name="carry">
              <!-- <div class="aircurry"> -->
              <img src="@/assets/home/kongyun2.png" alt class="carryUrl" />
              <!-- </div> -->
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <div :class="activeName == 'charter' ? 'whiteSpace' : 'whiteSpace2'"></div>
  </div>
</template>

<script>
import { airFreight } from "../data";
export default {
  data() {
    return {
      data: {},
      activeName: "charter"
    };
  },
  created() {
    this.data = airFreight;
    this.$nextTick(() => this.getAirAnchor());
  },
  methods: {
    requireImg(url) {
      return require("@/assets/img/service/airFreight/" + url + ".png");
    },
    handleClick(tab, event) {
      console.log(tab.index, event);
      if (!tab.index == 0) {
        document.querySelector(".el-tabs__active-bar").style.transform =
          "translateX(424px)";
      }
    },
    getAirAnchor() {
      let select = localStorage.getItem("airAnchor");
      let elm = document.getElementById(select);
      if (select) {
        elm.scrollIntoView(true);
      }
    },
  },
  watch: {
    // activeName(newValue) {
    //   if (newValue == "carry") {
    //   }
    // }
  },
  destroyed() {
    localStorage.setItem("airAnchor", "");
  },
};
</script>

<style lang="less" scoped>
.airFreight {
  width: 100%;
  height: 100%;
  position: relative;
  background: #ffffff;
  display: flex;
  justify-content: center;

  .content {
    width: 1200px;
    height: 786px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .top {
      margin-top: 58px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .title {
        font-size: 24px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #333333;
        line-height: 31px;
      }

      .titleE {
        margin-top: 7px;
        font-size: 21px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 27px;
      }
    }

    .buttom {
      width: 100%;
      display: flex;
      margin-top: 63px;
      justify-content: space-between;
      height: 341px;

      /deep/ .el-tabs {
        width: 100%;

        .el-tabs__header {
          border-bottom: 1px solid #cccccc;
          position: relative;
          display: flex;
          justify-content: space-around;

          #tab-charter {
            margin-right: 428px;
          }

          .el-tabs__item {
            padding: 0;
            font-size: 18px;
          }

          .el-tabs__active-bar {
            display: none;
          }

          .is-active {
            border-bottom: 3px solid #357BFF;
            color: #357BFF;
          }

          // .el-tabs__nav {
          //   display: flex;
          //   justify-content: space-around;
          //   // position: absolute;
          //   width: 100%;
          // }
        }
      }

      .tabPaneContent {
        width: 100%;
        height: 714px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .card {
          width: 374px;
          height: 309px;
          margin-top: 39px;
          position: relative;

          img {
            width: 100%;
            height: 100%;
          }

          .text {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 71px;
            font-size: 21px;
            text-align: center;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #ffffff;
            line-height: 71px;
          }

          div {
            opacity: 0.29;
            background: #000000;
          }
        }
      }

      .carryUrl {
        width: 1187px;
        height: 666px;
        margin-top: 58px;
      }
    }
  }
}

.whiteSpace {
  width: 100%;
  height: 208px;
  background: #f8f9fc;
}

.whiteSpace2 {
  width: 100%;
  height: 208px;
  background: #fff;
}</style>