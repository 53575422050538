<template>
  <div class="social"></div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
.social{
    width: 100%;
    height: 100%;
}
</style>