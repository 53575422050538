<template>
  <div class="service">
    <div class="left">
      <img :src="requireImg('b2')" alt />
    </div>
    <div class="right">
      <div class="cards">
        <div v-for="(item,index) in datas.data" :key="'cards'+index" class="card">
          <div class="title">{{item.title}}</div>
          <div class="text">{{item.text}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      // key: value
    };
  },
  methods: {
    requireImg(url) {
      return require("@/assets/img/service/roadTransport/" + url + ".png");
    }
  }
};
</script>

<style lang="less" scoped>
.service {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .left {
    width: 680px;
    height: 395px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    width: 518px;
    height: 395px;
    display: flex;
    background-color: rgba(53, 123, 255, 0.07);
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 21px;
      margin: 0 0 56px 0;
      text-align: center;
    }
    .cards {
      display: flex;
      flex-direction: column;
      width: 398px;
      height: 100%;
      // height: 165px;
      .card {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-top: 62px;
        .title {
          margin: 0 0 40px 0;
          padding: 0;
          font-size: 16px;
          font-family: "MicrosoftYaHei-Bold, MicrosoftYaHei";
          font-weight: bold;
          color: #333333;
          line-height: 21px;
        }
        .text {
          font-size: 16px;
          font-family: "MicrosoftYaHei";
          color: #333333;
          line-height: 23px;
          opacity: 0.9;
        }
      }
    }
  }
}
</style> 