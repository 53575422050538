<template>
  <div
    class="electricalBusinessLogistics"
    :style="'background-image:url('+requireImg(data.backgroundurl)+')'"
  >
    <div class="content">
      <div class="top" id="E-commerceLogistics">
        <div class="title">{{data.title}}</div>
        <!-- <div class="titleE">{{data.titleE}}</div> -->
      </div>
      <div class="buttom">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="data.platfor" name="charter">
            <div class="tabPaneContent">
              <div class="card" v-for="(item,index) in data.platformData" :key="'tabPane'+index">
                <img :src="requireImg(item.url)"  alt :height="item.height" :width="item.width" />
                <p class="text">{{item.text}}</p>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="data.carry" name="carry">
            <Carousel :datas="data.carryData" :type="true" class="carousel"></Carousel>

            <!-- <img :src="requireImg(data.carryUrl)" alt class="carryUrl" /> -->
          </el-tab-pane>
          <el-tab-pane :label="data.carry2" name="carry2">
            <div class="tabPaneContent2">
              <div class="card" v-for="(item,index) in data.carry2Data" :key="'tabPane'+index">
                <img :src="requireImg(item.url)" alt />
                <p class="text">{{item.text}}</p>
              </div>
            </div>

            <!-- <img :src="requireImg(data.carryUrl)" alt class="carryUrl" /> -->
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "./com/carousel";
import { EBL } from "../data";
export default {
  data() {
    return {
      data: {},
      activeName: "charter"
    };
  },
  components: {
    Carousel
  },
  created() {
    this.data = EBL;
    this.$nextTick(() => this.getEcommerceLogistics())
  },
  methods: {
    requireImg(url) {
      return require("@/assets/img/service/EBL/" + url + ".png");
    },
    handleClick(tab, event) {
      console.log(tab.index, event);
      if (!tab.index == 0) {
        document.querySelector(".el-tabs__active-bar").style.transform =
          "translateX(424px)";
      }
    },
    getEcommerceLogistics() {
      let select = localStorage.getItem("E-commerceLogistics");
      let elm = document.getElementById(select);
      if (select) {
        elm.scrollIntoView(true);
      }
    },
  },
  destroyed() {
    localStorage.setItem("E-commerceLogistics", "");
  },
};
</script>

<style lang="less" scoped>
.electricalBusinessLogistics {
  width: 100%;
  height: 784px;
  position: relative;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  .content {
    width: 1329px;
    height: 784px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .top {
      width: 1200px;
      height: 123px;
      text-align: center;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-top: 139px;
      .title {
        font-size: 24px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #fff;
        line-height: 31px;
      }
      .titleE {
        margin-top: 7px;
        font-size: 21px;
        font-family: MicrosoftYaHei;
        color: #fff;
        line-height: 27px;
      }
    }
    .buttom {
      width: 100%;
      display: flex;
      margin-top: 77px;
      justify-content: center;
      /deep/ .el-tabs {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .el-tabs__content {
          // margin-top: 58px;
          width: 100%;
        }
        .el-tabs__header {
          width: 1200px;
          display: none;
          // border-bottom: 1px solid #cccccc;
          position: relative;
          display: flex;
          justify-content: space-around;
          .el-tabs__item {
            margin-right: 275px;
          }
          .el-tabs__item:last-child {
            margin: 0;
          }
          .el-tabs__item,
          .el-tabs__item:hover {
            color: #ffffff;
            padding: 0;
          }
          .el-tabs__active-bar {
            display: none;
          }
          .is-active {
            color: #ffffff;
            border-bottom: 3px solid #ffffff;
          }
          // .el-tabs__nav {
          //   display: flex;
          //   justify-content: space-around;
          //   // position: absolute;
          //   width: 100%;
          // }
        }
      }
      .tabPaneContent {
        width: 100%;
        height: 480px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        // padding-top: 65px;
        align-items: baseline;
        .card {
          width: 300px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          // img {

          // }
          .text {
            width: 73px;
            height:26px;
            font-size: 20px;
            text-align: center;
            font-family: MicrosoftYaHei;
            font-weight: normal;
            color: #ffffff;
            line-height: 71px;
          }
        }
      }
      .tabPaneContent2 {
        width: 100%;
        height: 495px;
        display: flex;
        justify-content: space-between;
        padding-top: 85px;
        img {
          width: 539px;
          height: 314px;
        }
        .text {
          width: 539px;
          height: 44px;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 22px;
          margin-top: 38px;
        }
      }
      .carryUrl {
        width: 1191px;
        height: 702px;
        margin-top: 40px;
      }
    }
  }
}
</style>