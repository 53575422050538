<template>
  <div class="roadTransport">
    <div class="content" id="declareAtCustoms">
      <div class="top">
        <div class="title">{{ data.title }}</div>
        <!-- <div class="titleE">{{data.titleE}}</div> -->
      </div>
      <div class="buttom">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="data.charter" name="charter">
            <Service :datas="data.charterData"></Service>
          </el-tab-pane>
          <el-tab-pane :label="data.carry" name="carry">
            <Nakaminato :datas="data.carryData"></Nakaminato>
          </el-tab-pane>
          <el-tab-pane :label="data.carry2" name="carry2">
            <MiddleEurope :datas="data.carry2Data"></MiddleEurope>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { roadTransport } from "../data";
import Service from "./com/roadTransport/service";
import MiddleEurope from "./com/roadTransport/middleEurope";
import Nakaminato from "./com/roadTransport/Nakaminato";
export default {
  data() {
    return {
      data: {},
      activeName: "charter"
    };
  },
  components: {
    Service,
    Nakaminato,
    MiddleEurope
  },
  created() {
    this.data = roadTransport;
    this.$nextTick(() => this.getStorages())
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab.index, event);
    },
    getStorages() {
      let select = localStorage.getItem("storages");
      let elm = document.getElementById(select);
      if (select) {
        elm.scrollIntoView(true);
      }
    },
  },
  destroyed() {
    localStorage.setItem("storages", "");
  },
};
</script>

<style lang="less" scoped>
.roadTransport {
  width: 100%;
  height: 100%;
  position: relative;
  background: #ffffff;
  display: flex;
  justify-content: center;

  .content {
    width: 1200px;
    height: 760px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .top {
      margin-top: 58px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .title {
        font-size: 24px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #333333;
        line-height: 31px;
      }

      .titleE {
        margin-top: 7px;
        font-size: 21px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 27px;
      }
    }

    .buttom {
      width: 100%;
      display: flex;
      margin-top: 63px;
      justify-content: space-between;
      height: 341px;

      /deep/ .el-tabs {
        width: 100%;

        .el-tabs__header {
          border-bottom: 1px solid #cccccc;
          position: relative;
          display: flex;
          justify-content: space-around;
          margin: 0 0 61px;

          #tab-charter,
          #tab-carry {
            margin-right: 309px;
          }

          .el-tabs__item {
            padding: 0;
            font-size: 18px;
          }

          .el-tabs__active-bar {
            display: none;
          }

          .is-active {
            border-bottom: 3px solid #357BFF;
            color: #357BFF;
          }
        }
      }

      .tabPaneContent {
        width: 100%;
        height: 714px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
  }
}</style>