<template>
  <div class="service">
    <!-- 客户数据 -->
    <Information></Information>
    <!-- 商贸供应链 -->
    <Side class="side"></Side>
    <!-- <SupplyChain></SupplyChain> -->
    <!-- 报关业务介绍 -->
    <div class="declare" id="declareAtCustoms">
      <div class="declareIntroduce">
        <div class="declaretitle">报关</div>
        <div class="declareBody">
          <img src="../../../assets/img/service/baoguan@2x.png" alt="">
          <div class="declareBody_right">
            <p class="declareTextO">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;深圳市佳裕达报关有限公司主营口岸清关与仓储、运输等拓展性业务，通过科学高效的管理手段，使用自主研发的高新技术打造的系统操作平台，
              成功打造最具优势的链条式服务平台。我们秉持团结上进的企业文化，贯彻“专业报关、诚信服务”的经营理念。</p>
            <p class="declareTextOT"><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;主要业务</span>：进出口清关、属地报关、快件进出口、ATA单证册、修理物品、退运货物等特殊作业通关服务、9610，9710等跨境电商出口报关。</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 空运 -->
    <AirFreight></AirFreight>
    <!-- 海运 -->
    <Shipping></Shipping>
    <!-- 电商物流 -->
    <ElectricalBusinessLogistics></ElectricalBusinessLogistics>
    <!-- 国内及跨境陆路运输 -->
    <RoadTransport></RoadTransport>
    <!-- 仓储 -->
    <Storage></Storage>
    <!-- 技术开发 -->
    <development></development>
  </div>
</template>

<script>
import Side from "@/components/Side";
import Information from "./components/information.vue";
// import SupplyChain from "./components/supplyChain";
import AirFreight from "./components/airFreight";
import Shipping from "./components/shipping";
import ElectricalBusinessLogistics from "./components/electricalBusinessLogistics";
import RoadTransport from "./components/roadTransport";
import Storage from "./components/storage";
import Development from "./components/development";
export default {
  data() {
    return {
      data: {}
    };
  },
  components: {
    Information,
    // SupplyChain,
    AirFreight,
    Shipping,
    ElectricalBusinessLogistics,
    RoadTransport,
    Storage,
    Development,
    Side
  },
  created(){
    this.$nextTick(() => this.getCustomsDeclarationBusiness());
  },
  methods:{
    getCustomsDeclarationBusiness(){
      let select = localStorage.getItem("declareAtCustoms");
      let elm = document.getElementById(select);
      if (select) {
        elm.scrollIntoView(true);
      }
    }
  },
  destroyed() {
    localStorage.setItem("declareAtCustoms", "");
  },
};
</script>

<style lang="less"  scoped>
.service{
  width: 100%;
  height: 100%;
  .declare{
    width: 100%;
    .declareIntroduce{
      height: 100%;
      width: 1200px;
      margin: 0 auto;
      padding-bottom: 10px;
      .declaretitle{
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 31px;
        text-align: center;
        padding: 30px 0 63px;
      }
      .declareBody{
        display: flex;
        img{
          width:598px;
          height: 395px;
        }
      }
      .declareBody_right{
        margin-left: -1px;
        background: rgba(53, 123, 255, 0.07);
        padding: 77px 55px 78px 56px;
        color: #333333;
        .declareTextO{
          font-size: 16px;
          line-height: 28px;
        }
        .declareTextOT{
          font-size: 16px;
          line-height: 28px;
          margin-top: 74px;
          span{
            font-weight: bold;
          }
        }
      }
    }
  }
}
::-webkit-scrollbar {
  width: 1px;
  height: 0px;
  background-color: #f5f5f5;
}
.el-tabs__nav-wrap::after {
  height: 0px;
  display: none;
}
.side {
   float: right;
   opacity: 1;
  z-index: 999;
  position: sticky;
  top: 247px;
  // right: 100px;
  margin-top: -300px;
  margin-right: 38px;
}
</style>