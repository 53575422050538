import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home/index.vue'
import software from '@/views/home/software.vue'
import newsInformation from '@/views/home/newsInformation.vue'
import newDetail from '@/views/home/newDetail.vue'
import cooperationConsultation from '@/views/home/cooperationConsultation.vue'
import service from '@/views/home/service/index.vue'
import smartTechnology from '@/views/home/smartTechnology/index.vue'
import theListedWebsites from '@/views/home/theListedWebsites/index.vue'
import personnelRecruitment from '@/views/home/personnelRecruitment/index.vue'
import socialResponsibility from '@/views/home/socialResponsibility/index.vue'
import HHome from '@/views/jyd_home/index.vue';
import Introduce from '@/views/jyd_home/introduce/index.vue';
import Intelligence from '@/views/jyd_home/intelligence/index.vue';
import Recruitment from '@/views/jyd_home/recruitment/index.vue';
import details from '@/views/jyd_home/recruitment/details.vue';
import Chanpinfuwu from '@/views/jyd_home/chanpinfuwu/index.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/home',
    name: 'home',
    component: HHome,
  },
  {
    path: '/introduce',
    name: 'introduce',
    component: Introduce,
  },
  {
    path: '/details',
    name: 'details',
    component: details,
  },
  {
    path: '/intelligence',
    name: 'intelligence',
    component: Intelligence,
  },
  {
    path: '/recruitment',
    name: 'recruitment',
    component: Recruitment,
  },
  {
    path: '/software',
    name: 'software',
    component: software
  },
  {
    path: '/newsInformation',
    name: 'newsInformation',
    component: newsInformation
  },
  {
    path: '/service',
    name: 'service',
    component: service
  },
  {
    path: '/newDetail',
    name: 'newDetail',
    component: newDetail
  },
  {
    path: '/smartTechnology',
    name: 'smartTechnology',
    component: smartTechnology
  },
  {
    path: '/cooperationConsultation',
    name: 'cooperationConsultation',
    component: cooperationConsultation
  },
  {
    path: '/theListedWebsites',
    name: 'theListedWebsites',
    component: theListedWebsites
  },
  {
    path: '/personnelRecruitment',
    name: 'personnelRecruitment',
    component: personnelRecruitment
  },
  {
    path: '/socialResponsibility',
    name: 'socialResponsibility',
    component: socialResponsibility
  },
  {
    path: '/Chanpinfuwu',
    name: 'Chanpinfuwu',
    component: Chanpinfuwu,
  }
]

const router = new VueRouter({
  routes
})

router.afterEach(() => {

  window.scrollTo(0, 0);
});

//解决锚点在同一页面重复报错原因
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
