<template>
  <div class="smartTechnology">
    <!-- 数字孪生 -->
    <div class="digitalTwin" id="digitalTwins">
      <div class="digitalTit">数字孪生:佳裕达让物流更智能</div>
      <div class="img">
        <img src="@/assets/home/guanxitu2.png" alt="" style="width: 100%" />
      </div>
    </div>
    <!-- 佳裕达数智化平台系统 -->
    <div class="platform" id="intelligentPlatform">
      <div class="title">佳裕达数智化平台系统</div>
      <div class="platform-content">
        <div class="content" v-for="(item, index) in platformList" :key="index" @click="jumpOutlink">
          <div class="imgCont">
            <img class="imgTit" style="width:100%" :src="item.img" alt="" />
          </div>
          <div class="contentTit">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <!-- <Intellect></Intellect> -->
    <!-- <Side class="side"></Side> -->
    <!-- <Customer></Customer> -->
  </div>
</template>

<script>
// import Side from "@/components/Side";
// import Intellect from "./components/intellect.vue";
// import Customer from "./components/customer.vue";
export default {
  data() {
    return {
      data: {},
      platformList: [
        {
          img: require("@/assets/home/oms.png"),
          title: "OMS订单系统",
        },
        {
          img: require("@/assets/home/TMS.png"),
          title: "TMS智能运输",
        },
        {
          img: require("@/assets/home/wms.png"),
          title: "WMS仓储系统",
        },
        {
          img: require("@/assets/home/B2C.png"),
          title: "B2C电商物流",
        },
        {
          img: require("@/assets/home/baoguan.png"),
          title: "报关系统",
        },
        {
          img: require("@/assets/home/BI.png"),
          title: "BI数据分析",
        },
        {
          img: require("@/assets/home/IOT.png"),
          title: "IOT智能仓库",
        },
        {
          img: require("@/assets/home/BMS.png"),
          title: "BMS结算系统",
        },
        {
          img: require("@/assets/home/dakehu.png"),
          title: "大客户定制化",
        },
        {
          img: require("@/assets/home/wuliudashuju.png"),
          title: "物流大数据",
        },
        {
          img: require("@/assets/home/huodaicaozuoxitong.png"),
          title: "货代操作系统",
        },
        {
          img: require("@/assets/home/kehuguanlixitong.png"),
          title: "客户管理系统",
        },
        {
          img: require("@/assets/home/haiwai.png"),
          title: "海外仓系统",
        },
        {
          img: require("@/assets/home/xiaobao.png"),
          title: "小包系统",
        },
        {
          img: require("@/assets/home/gongyinlian.png"),
          title: "供应链系统",
        },
      ],
    };
  },
  created() {
    this.$nextTick(() => this.getDigitalTwins())
    this.$nextTick(() => this.getIntelligentPlatform())
  },
  methods: {
    getDigitalTwins() {
      let select = localStorage.getItem("digitalTwins");
      let elm = document.getElementById(select);
      if (select) {
        elm.scrollIntoView(true);
      }
    },
    getIntelligentPlatform() {
      let select = localStorage.getItem("intelligentPlatform");
      let elm = document.getElementById(select);
      if (select) {
        elm.scrollIntoView(true);
      }
    },
    jumpOutlink() {
      window.open('https://order.jayud.com')
    }
  },
  destroyed() {
    localStorage.setItem("digitalTwins", "");
    localStorage.setItem("intelligentPlatform", "");
  },
  // components: { Intellect, Customer, Side }
};
</script>

<style lang="less"   scoped>
.smartTechnology {
  width: 100%;
  height: 100%;

  .digitalTwin {
    width: 1200px;
    height: 644px;
    margin: 0 auto;

    .digitalTit {
      font-size: 24px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 31px;
      text-align: center;
      margin-top: 54px;
    }

    .img {
      width: 100%;
      height: 544px;
      margin-top: 69px;
    }
  }

  .platform {
    width: 1200px;
    height: 731px;
    margin: 0 auto;
    margin-top: 94px;

    .title {
      text-align: center;
      font-size: 24px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 31px;
    }

    .platform-content {
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      justify-content: space-between;
      width: 100%;
      height: 537px;
      margin-top: 73px;

      .content {
        width: 205px;
        height: 150px;
        border-radius: 10px;
        border: 1px solid #cacaca;

        .imgCont {
          width: 55px;
          height: 55px;

          .imgTit {
            margin-top: 31px;
            margin-left: 75px;
          }
        }

        .contentTit {
          text-align: center;
          margin-top: 18px;
          font-size: 18px;
          // margin-left: 45px;
        }
      }

      .content:hover {
        border-color: #357BFF;
        cursor: pointer;
      }
    }
  }
}
</style>