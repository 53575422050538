<template>
  <div class="carousel">
    <div class="swiper-container1">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in datas" :key="'datas' + index">
          <img :src="item.url" alt />
          <span class="text">{{ item.text }}</span>
        </div>
      </div>

      <!-- 如果需要导航按钮 -->
      <div class="swiper-button-prev">
        <img src="./img/left.png" alt class="button-jiangtou" />
      </div>
      <div class="swiper-button-next">
        <img src="./img/right.png" alt class="button-jiangtou" />
      </div>
      <!-- 如果需要滚动条 -->
    </div>
  </div>
</template>
<script>
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";
export default {
  props: {
    datas: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      //   key: value
      data: []
    };
  },
  methods: {
    Swiperfn() {
      new Swiper(".swiper-container1", {
        pagination: ".swiper-pagination",
        slidesPerView: 3, //一次几个
        paginationClickable: true,
        spaceBetween: 30,
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        // mousewheel: true, //滚轮
        autoplay: {
          //自动开始
          delay: 2500, //时间间隔
          disableOnInteraction: false //*手动操作轮播图后不会暂停*
        },
        loop: true, // 循环模式选项
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.Swiperfn();
    });
  }
};
</script>

<style  lang="less" scoped>
.carousel {
  width: 100%;
  height: 577px;
}

#carousel {
  width: 100%;
  height: 100%;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-wrapper {
  width: 100%;
  height: 412px;
  // overflow: hidden;
}

.swiper-slide {
  width: 373px;
  height: 228px;
  margin-top: 184px;
  transition: margin 0.2s;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  .text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -93px;
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 24px;
  }
}

.swiper-slide-next {
  width: 461px;
  height: 282px;
  margin-top: 130px;
  transition: margin 0.2s;
}

.swiper-button-prev,
.swiper-button-next {
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.36);
  background-image: "";
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  bottom: 111px;
}

.swiper-button-next {
  right: 31px;
}

.swiper-button-prev {
  left: 31px;
}

.button-jiangtou {
  width: 13px;
  height: 20px;
}

.swiper-container1,
.swiper-wrapper {
  height: 100%;
}</style>