<template>
    <div class="personnelManager">
        <div class="tit">
            <div class="tit_heart">
                <span class="tit_Text">人才招聘</span><i class="el-icon-arrow-right"></i>
                <span class="tit_Icon" @click="back()">社会招聘</span><i class="el-icon-arrow-right"></i><span class="tit_Icon">职位详情</span>
            </div>
        </div>
        <div class="con">
            <div class="applyForPosition">
                <p class="position_left">职位名称：客服专员</p>
                <el-button type="primary" disabled>申请职位</el-button>
            </div>
        </div>
        <div class="introduce">
            <div class="introduce_top">
                <p class="sty" style="margin-right:377px">招聘人数：4人</p>
                <p class="sty" style="margin-right:335px">招聘有效期：2月～5月</p>
                <p class="sty">薪酬范围：6000-8000元/月</p>
            </div>
            <div class="introduce_bottom" style="margin-top:16px">
                <p class="sty" style="margin-right:396px">学历要求：大专</p>
                <p class="sty">经验要求：经验不限</p>
            </div>
        </div>
        <div class="operatingDuty">
            <div class="operatingDuty_text">工作职责</div>
            <ul class="operatingDuty_item">
                <li>1、准确及时地处理每日客户下达的订单，并在订单系统信息录入、维护系统回单数据，确保系统数据准确与及时；</li>
                <li>2、与客户对接订单及发票资料，包括从接单开始到后续全程跟进跟踪货物；核对客户应收账账单及跟进；</li>
                <li>3、客户信息咨询的及时反馈；问题处理与及时反馈；与客户、司机进行协调与沟通；</li>
                <li>4、与客户建立良好的联系，熟悉及挖掘客户需求，具备处理问题、安排进展、跟进进程、沟通及疑难问题服务的意识跟能力，最大限度的提高客户满意度。遇
                </li>
                <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;到不能解决的问题按流程提交相关人员或主管处理，并跟踪进展直至解决；</li>
                <li>5、处理各类突发事件并及时向上级汇报；完成部门分配的其他工作任务。</li>
            </ul>
        </div>
        <div class="operatingDuty" style="padding:43px 0 54px;">
            <div class="operatingDuty_text">岗位要求</div>
            <ul class="operatingDuty_item">
                <li>1、思维敏捷，接受能力强；</li>
                <li>2、善于总结工作经验；</li>
                <li>3、能熟练使用办公软件和学习能力强，有物流行业客服经验优先。</li>
            </ul>
        </div>
    </div>
</template>

<script>
     export default{
        data(){
            return{
                bacKShow:null
            }
        },
        methods:{
            back(){
                this.bacKShow = 1
                this.$emit('bacKShow',this.bacKShow)
            }
        }
    }
</script>

<style lang="less" scoped>
.personnelManager{
    .tit{   
        background: #F4F3F3;
        height: 60px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 60px;
        .tit_heart{
            margin: 0 auto;
            width: 1200px;
            .tit_Text{
                margin-right: 11px;
            }
            .tit_Icon{
                margin: 11px;
            }
        }
    }
    .con{
        margin: 0 auto;
        width: 1200px;
        padding-top: 38px;
        .applyForPosition{
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .position_left{
                font-size: 18px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #333333;
                line-height: 24px;
            }
            /deep/.el-button--primary.is-disabled{
                background: #357BFF;
                border: #357BFF;
                border-radius: 2px;
            }
        }
    }
    .introduce{
        padding: 39px 0 37px;
        width: 1200px;
        margin: 0 auto;
        border-bottom: 1px solid #CCCCCC;
        .introduce_top,.introduce_bottom{
            display: flex;
            .sty{
                font-size: 16px;
                font-family: MicrosoftYaHei;
                color: #333333;
                line-height: 21px;
            }
        }
    }
    .operatingDuty{
        width: 1200px;
        margin: 0 auto;
        padding-top: 36px;
        .operatingDuty_text{
            font-size: 18px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #333333;
            line-height: 24px;
        }
        .operatingDuty_item{
            padding: 29px 0 0 72px;
            li{
                font-size: 16px;
                font-family: MicrosoftYaHei;
                color: #333333;
                line-height: 21px;
                margin-bottom: 12px;
            }
        }
    }
}
</style>