// 产品
export const productData = [
  {
    top: 0, title: '空运',
    text: '佳裕达联合多家国际知名航空公司，携手推出空运出口、空运进口运输及包机业务，公司与东方航空、韩亚航空、新加坡航空，U.S.POLARAIR航空公司、北欧航空、奥地利航空、印度航空公司、阿拉伯水晶航空、上海航空等多家航空公司建立了长期友好的合作关系。',
    button: ['空运出口', '空运进口', '空运包机', '空运大件货'],
    image: 'bg1.png',
    height: "183",
    imgbot: '5'
  },
  {
    top: 0, title: '报关',
    text: '深圳市佳裕达报关有限公司主营口岸清关与仓储、运输等拓展性业务，通过科学高效的管理手段，使用自主研发的高新技术打造的系统操作平台， 成功打造最具优势的链条式服务平台。我们秉持团结上进的企业文化，贯彻“专业报关、诚信服务”的经营理念。',
    titlebold: '主要业务：',
    textbold: '进出口清关、属地报关、快件进出口、ATA单证册、修理物品、退运货物等特殊作业通关服务、9610，9710等跨境电商出口报关。',
    button: ['报关代理', '空运进口', '空运包机'],
    image: 'bg2.png',
    height: "184",
    imgbot: '6'
  }, {
    top: 110, title: '海运',
    text: '佳裕达集团经国家交通部批准，具有无船承运业务资格。集团主营海运集装箱、拼箱货物、海运散杂货运输等海运进出口服务。我司与HPL/YML/MSC/CMA/MCC/-COSCO多家船运公司合作，在境内外均拥有健全的服务网络机制，为客户提供从订舱、报关、转关、仓储、清关、代办货运保险及各证书、代理检验/检疫等一系列物流服务。',
    title2: '海外进口流程',
    text2: ['1、审核客户提供的各类清关文件。',
      '2、编制各类单证、订舱、报关。',
      '3、办理产地证、保险单和商检证。',
      '4、集装箱租赁和装箱、国内运输、进港、装船、签发提       单、运杂费结算。',
      ' 5、对接国外进口报关、纳税、拆箱/转运、派送到国外代理手上。'],
    image: 'bg3.png',
    height: "184",
    imgbot: '0'
  }, {
    top: 0, title: '铁路',
    text: '深圳“湾区号”的订舱服务，珠三角区域内提供门到站服务，目的站点覆盖马拉、汉堡、杜堡、莫斯科、明斯克、塔什干、阿拉木图、老挝万象等。重庆、郑州、西安往返马拉、汉堡、杜堡、莫斯科、明斯克 进出口整柜订舱、散货集拼。',

    button: ['出口铁路整箱', '出口铁路拼箱', '进口铁路整箱', '进口铁路拼箱'],
    image: 'bg4.png',
    height: "184",
    imgbot: '6'
  }, {
    top: 0, title: '电商物流',
    text: '由佳裕达集团打造的跨境电子商务物流平台，其服务区域横跨美、亚大洲，持续为美国亚马逊、 印度亚马逊以及台湾虾皮等全球电子商务企业提供国际一流的物流运输服务。物流运输的细化业务含仓库存储、分拣打包、快递发货等电子商务综合服务。',

    button: ['空派', '空卡', '海派', '海卡'],
    image: 'bg5.png',
    height: "184",
    imgbot: '6'
  },
  {
    top: 70, title: '运输',
    text: ['佳裕达集团已开通全国各地直达深圳的多条物流专线，包含中港跨境、香港本地、深圳城际、珠江三角洲、华南-华东和华南-川渝专线，主营国内铁路、公路、航空、中港货物、吨车往返。自创办以来，深受广大客户的信任和支持。', '珠江三角洲业务范围内，公司配备运输服务业内的技术骨干和专业团队，采用各类车型来满足各种货物运输需求；中港跨境业务方面，主要提供深圳与各大港口的拖车服务，已经形成一套完备、高效的流程体系。', '我司独立拥有60多辆拖车、挂靠拖车和单拖、双拖、重柜双拖、转关车等多种车型全程采用GPS跟踪管理。拖车部提供24小时服务，实现同行货代、司机、货主三者沟通和谐。'],
    button: ['内陆运输', '中港运输', '拖车运输'],
    image: 'bg6.png',
    height: "163", imgbot: '0'
  },
  {
    top: 38, title: '仓储',
    text: '佳裕达集团是综合型国际货运代理有限公司，在深圳、香港及海外拥有多所仓库及配送车辆，可提供装卸、运输、存储、包装、分拣、贴标等一站式服务。仓储区域有专业的叉车设备、专用大型三层承重货架、小件货架、分拣车、收发专用PDA设备和扫描枪。同时，仓储区域24小时设置全方位监控及保安执勤，消防设施齐全，安保管控严格。主要仓库有：',
    image: 'bg7.png',
    platformData: [
      { text: '大铲湾综合仓', num: "占地面积1500", danwei: "m²", right: 0, left: 0 },
      { text: '龙岗仓库', num: "占地面积8000", danwei: "m²", right: 0, left: 0 },
      { text: '盐田保税仓库', num: "占地面积3000", danwei: "m²", right: 0, left: 0 },
      { text: '香港仓库', num: "占地面积5000", danwei: "m²", right: 0, left: 0 },
      { text: '义乌仓库', num: "占地面积1200", danwei: "m²", right: 0, left: 15 },
      { text: '海外仓库', num: "洛杉机、印度、泰国", danwei: "", right: 0, left: 0 },
    ],
    height: "182",
    imgbot: '0'
  }, {
    top: 0, title: '系统研发',
    text: '秉承10年的综合物流服务理念和经验，2015年开始注重发展物流领城信息自动化技术创新和升级。组建有着十余年丰富物流开发经验的专家团队，涵盖物联网、大数据分析、解决方案、产品研发等诸多技术领域，自主研发集团数智化信息平台，全程可视化追踪。',

    button: ['报关系统', '货代系统', '供应链系统', '运输系统', '仓储系统', '电商物流系统'],
    image: 'bg8.png',
    height: "184",
    imgbot: '7'
  }
]
// 资质
export const slideList = [
  {
    img: require("@/assets/product/honor/pW2.png"),
    title: '无船承运人资质',
    width: '94',
    hieght: '68',
    img1: require("@/assets/product/honor/pW3.png"),
    width1: '83',
    hieght1: '59',
  },
  {
    img: require("@/assets/product/honor/pD2.png"),
    title: '道路运输许可资质',
    width: '94',
    hieght: '68',
    img1: require("@/assets/product/honor/pD3.png"),
    width1: '83',
    hieght1: '59',
  },
  {
    img: require("@/assets/product/honor/pA2.png"),
    title: 'AAAA级物流企业',
    width: '94',
    hieght: '68',
    img1: require("@/assets/product/honor/pA3.png"),
    width1: '83',
    hieght1: '59',
  },
  {
    img: require("@/assets/product/honor/pB2.png"),
    title: '报关单位注册登记证书',
    width: '94',
    hieght: '68',
    img1: require("@/assets/product/honor/pB3.png"),
    width1: '83',
    hieght1: '59',
  },
  {
    img: require("@/assets/product/honor/pG2.png"),
    title: '国际食品经营许可证',
    width: '94',
    hieght: '68',
    img1: require("@/assets/product/honor/pG3.png"),
    width1: '83',
    hieght1: '59',
  },
  {
    img: require("@/assets/product/honor/pGJ2.png"),
    title: '国际货运代理企业备案表',
    width: '62',
    hieght: '88',
    img1: require("@/assets/product/honor/pGJ3.png"),
    width1: '49',
    hieght1: '71'
  },
  {
    img: require("@/assets/product/honor/pAEO2.png"),
    title: 'AEO认证企业证书',
    width: '62',
    hieght: '88',
    img1: require("@/assets/product/honor/pAEO3.png"),
    width1: '49',
    hieght1: '71'
  },
  {
    img: require("@/assets/product/honor/pDW2.png"),
    title: '对外贸易经营者备案登记表',
    width: '62',
    hieght: '88',
    img1: require("@/assets/product/honor/pDW3.png"),
    width1: '49',
    hieght1: '71'
  },
  {
    img: require("@/assets/product/honor/pISO2.png"),
    title: 'ISO物流科技',
    width: '62',
    hieght: '88',
    img1: require("@/assets/product/honor/pISO3.png"),
    width1: '49',
    hieght1: '71'
  },
  {
    img: require("@/assets/product/honor/pZ2.png"),
    title: '自理报检企业备案登记证明书',
    width: '94',
    hieght: '68',
    img1: require("@/assets/product/honor/pZ3.png"),
    width1: '83',
    hieght1: '59',
  },
]
