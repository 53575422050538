<template>
  <div class="intelligence">
    <div class="product">
      <div class="figure">
        <div class="product_title"> 数字孪生:佳裕达让物流更智能 </div>

        <img src="@/assets/jyd_home/Intelligence/bg1.png" alt="" class="figure_Img" preview="12">
      </div>
      <div class="system">
        <div class="product_title">佳裕达数智化平台系统</div>
        <System />
      </div>
    </div>
  </div>
</template>

<script>
import System from "./system.vue";
import { Introduc } from '../components/data'
export default {
  components: {
    // ProductService,
    // structure,
    System
  },
  data() {
    return {
      figure: '',

    }
  },
  created() {
    this.figure = Introduc
  },

}
</script>

<style lang="less" scoped>
.intelligence {
  width: 100vw;

  .product {
    width: 100vw;

    .figure {
      background-position: 50% 50%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 0 calc((12/10)*1rem);

      .figure_Img {
        width: 100%;
        height: calc((159/10)*1rem);
      }
    }

    .system {
      background-position: 50% 50%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 0 calc((12/10)*1rem);

      .system_Img {
        width: 100%;
        margin-top: calc((2/10)*1rem);
        height: calc((209/10)*1rem);
      }
    }


  }
}
</style>