<template>
  <div id="app">
    <div class="container">
      <Header class="header pc" v-if="type"></Header>
      <HeaderH v-if="!type" />
      <Side class="side" v-if="$route.fullPath != '/service' && type"></Side>
      <router-view />
      <FooterH v-if="!type" />
      <Footer class="Footer" v-if="type"></Footer>
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import HeaderH from '@/views/jyd_home/Header.vue';
import Footer from "../../components/Footer.vue";
import FooterH from '@/views/jyd_home/Footer.vue';
import Side from "@/components/Side";
export default {
  components: {
    Header,
    Footer,
    FooterH,
    HeaderH,
    Side
  },
  data() {
    return {
      type: true,
      zsie: '1'
    }
  },
  created() {
    this.isMobile()
  },
  mounted() {


    this.zsie = document.documentElement.clientWidth
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      if (flag) {
        this.type = false
        this.$router.push('/home')
        this.recalcfn()
      } else {
        this.$router.push('/')
        this.type = true
        let docEl = document.documentElement;
        docEl.style.fontSize = '12px';
      }
    },
    recalcfn() {
      let docEl = document.documentElement;
      console.log(1111);
      let resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
        recalc = function () {
          var clientWidth = docEl.clientWidth;
          if (!clientWidth) return;

          docEl.style.fontSize = 10 * (clientWidth / 375) + 'px';
        };
      if (!document.addEventListener) return;
      window.addEventListener(resizeEvt, recalc, false);
      document.addEventListener('DOMContentLoaded', recalc, false);
    }

  },
};
</script>

<style lang="less">
html,
body {
  margin: 0;
  padding: 0;
  background-color: #fff !important;
  -webkit-background-color: #fff !important;
}

.el-tabs__nav-wrap::after {
  height: 0px;
  display: none;
}

::-webkit-scrollbar {
  width: 0px;

  height: 0px;

  background-color: #f5f5f5;
}
</style>
