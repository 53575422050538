export const details = [
  {
    title: '人事行政经理', num: '1', xueli: '全日制本科学历', jingyan: '经验不限', gongzi: '20-25K', zhize: '1、负责公司各部门定岗定编及人员优化等相关工作；  2、负责培训管理体系建设，推动培训体系落地执行； 3、负责绩效管理体系优化改进，指导各部门优化绩效考核指标，协助各部门进行绩效改进； 4、负责部门团队建设，对部门人员进行培训、指导、考核及激励； 5、协助上级领导设计并推动管理改善项目，提升公司综合管理水平；6、负责行政管理体系的构建与完善，并推动执行；7、负责员工活动的组织，提高员工的归属感和凝聚力； 8、负责当地政府部门和行政机构的对接 ', yaoqiu: '1、全日制本科学历，人力资源相关专业，持二级人力师证优先；   2、熟悉人力资源6大模块，精通培训或者薪酬绩效管理模块；3、具有很强的激励、沟通、协调、团队协作能力，抗压能力强、责任心、事业心强，有物流或相关行业管理经验者优先考 '
  },
  {
    title: '财务经理', num: '1', xueli: '全日制本科学历', jingyan: '3年以上', gongzi: '20-25K', zhize: '1、负责参与集团账务、税务、资金等预算业务；  2、负责相关会计报表的分析工作，为管理层的经营决策提供依据；3、对集团税务处理工作进行审核、监督，负责集团税务筹划及税务事项办理，规避税务风险；4、对公司重大的投资、融资、并购等经营活动提供建议和决策支持，参与风险评估、指导、跟踪和控制；5、参与完善集团的财务管理制度及人才培养；6、认真贯彻执行集团的规章制度和国家相关财经法律法规', yaoqiu: '1、统招本科及以上学历，财务及相关专业,持中级会计师以上资格证；2、有同行物流企业财务管理工作经验；3、三年以上财务经理工作经验；   4、具有全面的财务专业知识、账务处理及财务管理经验； 5、熟练操作企业成本管理体系和全面预算管理体系；6、受过管理学、战略管理、管理能力开发、企业运营流程、财务管理等方面的培训。'
  },
  {
    title: 'FBA业务员', num: '30', xueli: '大专', jingyan: '经验不限', gongzi: '5-20k', zhize: ' 1、通过各种渠道收集潜在客户资料外贸公司、跨境电商平台等，并跟客户进行沟通，了解客户需求公司货运航线及服务，寻找合作机会； 2、挖掘客户需求，给出货运物流方案，达成交易，努力完成公司制定的销售任务； 3、维护好老客户，并负责客户回款催收工作。 ', yaoqiu: '1、热爱业务开发，具有一定抗压能力； 2、有亚马逊平台及FBA销售经验及有物流管理、供应链、市场营销等相关专业优先考虑。  '
  }
  ,
  {
    title: '客服专员', xueli: '大专', jingyan: '经验不限', gongzi: '6-8k', num: '4', zhize: '1、准确及时地处理每日客户下达的订单，并在订单系统信息录入、维护系统回单数据，确保系统数据准确与及时； 2、与客户对接订单及发票资料，包括从接单开始到后续全程跟进跟踪货物，核对客户应收账账单及跟进； 3、客户信息咨询的及时反馈，问题处理与及时反馈，与客户、司机进行协调与沟通； 4、与客户建立良好的联系，熟悉及挖掘客户需求，具备处理问题、安排进展、跟进进程、沟通及疑难问题服务的意识跟能力，最大限度的提高客户满意度。遇到不能解决的问题按流程提交相关人员或主管处理，并跟踪进展直至解决； 5、处理各类突发事件并及时向上级汇报,完成部门分配的其他工作任务。  ', yaoqiu: ' 1、思维敏捷，接受能力强；  2、善于总结工作经验；  3、能熟练使用办公软件和学习能力强，有物流行业客服经验优先。  '
  },
  {
    title: '仓库管理员', xueli: '大专', jingyan: '经验不限', gongzi: '6-8k', num: '4', zhize: ' 1、使用仓储系统，对仓库物资进出库的验收、摆放、帐务、发放的处理，确保数据的一致性及相关台账的记录； 2、保管好仓库内的物资，定期检查，清理，保证库存物资完好无损，并做好相关安全工作； 3、掌握物资的库存情况，保证物资的及时供应，相关物资做好统计，管理； 4、负责发放货物的跟踪落实，及时反馈、解决在跟踪落实过程中存在的各类问题，定期汇总各项物流管理报表，并根据管理报表改进物流运作； 5、仓储部各类主要文件的整理及存档及流程优化，固定资产的管理、使用、维护及监督； 6、做好仓库盘点工作，总结出问题所在，并及时处理及优化，各种单据报表的归档管理工作。 ', yaoqiu: '1、积极向上、吃苦耐劳 、具有良好的团队协作能力； 2、及时处理及优化，各种单据报表的归档管理工作。  '
  },
  {
    title: '电话销售', xueli: '大专', jingyan: '经验不限', gongzi: '5-20k', num: '30', zhize: '1、通过电话或者网络，负责终端客户的开发，产品的销售，后期的客户维护(前期公司有完善的拓客体系，只负责开发客户邀约)； 2、了解跨境物流的基础知识 (公司安排基础培训，协助销售解决客户后顾之忧)； 3、通过电话预约客户时间，通知业务经理上门拜坊； 4、完成公司每月的邀约目标，及时向上级汇报工作，递交相关报告，如客户跟踪情况等(辅助你快速成交)。  ', yaoqiu: '1、对电话销售有浓厚兴趣；  2、性格开朗、做事严谨、善于沟通。   '
  }
]