<template>
    <div class="personnelManager">
        <div class="tit">
            <div class="tit_heart">
                <span class="tit_Text">人才招聘</span><i class="el-icon-arrow-right"></i>
                <span class="tit_Icon" @click="back()">社会招聘</span><i class="el-icon-arrow-right"></i><span class="tit_Icon">职位详情</span>
            </div>
        </div>
        <div class="con">
            <div class="applyForPosition">
                <p class="position_left">职位名称：财务经理</p>
                <el-button type="primary" disabled>申请职位</el-button>
            </div>
        </div>
        <div class="introduce">
            <div class="introduce_top">
                <p class="sty" style="margin-right:383px">招聘人数：1人</p>
                <p class="sty" style="margin-right:335px">招聘有效期：2月～5月</p>
                <p class="sty">薪酬范围：20-25K</p>
            </div>
            <div class="introduce_bottom" style="margin-top:16px">
                <p class="sty" style="margin-right:360px">学历要求：大学本科</p>
                <p class="sty">经验要求：三年以上</p>
            </div>
        </div>
        <div class="operatingDuty">
            <div class="operatingDuty_text">工作职责</div>
            <ul class="operatingDuty_item">
                <li>1、负责参与集团账务、税务、资金等预算业务；</li>
                <li>2、负责相关会计报表的分析工作，为管理层的经营决策提供依据；</li>
                <li>3、对集团税务处理工作进行审核、监督，负责集团税务筹划及税务事项办理，规避税务风险；</li>
                <li>4、对公司重大的投资、融资、并购等经营活动提供建议和决策支持，参与风险评估、指导、跟踪和控制；</li>
                <li>5、参与完善集团的财务管理制度及人才培养；</li>
                <li>6、认真贯彻执行集团的规章制度和国家相关财经法律法规。</li>
            </ul>
        </div>
        <div class="operatingDuty" style="padding:43px 0 54px;">
            <div class="operatingDuty_text">岗位要求</div>
            <ul class="operatingDuty_item">
                <li>1、统招本科及以上学历，财务及相关专业；持中级会计师以上资格证；</li>
                <li>2、有同行物流企业财务管理工作经验，三年以上财务经理工作经验；</li>
                <li>3、具有全面的财务专业知识、账务处理及财务管理经验；</li>
                <li>4、熟练操作企业成本管理体系和全面预算管理体系；</li>
                <li>5、受过管理学、战略管理、管理能力开发、企业运营流程、财务管理等方面的培训。</li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default{
        data(){
            return{
                bacKShow:null
            }
        },
        methods:{
            back(){
                this.bacKShow = 1
                this.$emit('bacKShow',this.bacKShow)
            }
        }
    }
</script>

<style lang="less" scoped>
.personnelManager{
    .tit{   
        background: #F4F3F3;
        height: 60px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 60px;
        .tit_heart{
            margin: 0 auto;
            width: 1200px;
            .tit_Text{
                margin-right: 11px;
            }
            .tit_Icon{
                margin: 11px;
            }
        }
    }
    .con{
        margin: 0 auto;
        width: 1200px;
        padding-top: 38px;
        .applyForPosition{
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .position_left{
                font-size: 18px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #333333;
                line-height: 24px;
            }
            /deep/.el-button--primary.is-disabled{
                background: #357BFF;
                border: #357BFF;
                border-radius: 2px;
            }
        }
    }
    .introduce{
        padding: 39px 0 37px;
        width: 1200px;
        margin: 0 auto;
        border-bottom: 1px solid #CCCCCC;
        .introduce_top,.introduce_bottom{
            display: flex;
            .sty{
                font-size: 16px;
                font-family: MicrosoftYaHei;
                color: #333333;
                line-height: 21px;
            }
        }
    }
    .operatingDuty{
        width: 1200px;
        margin: 0 auto;
        padding-top: 36px;
        .operatingDuty_text{
            font-size: 18px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #333333;
            line-height: 24px;
        }
        .operatingDuty_item{
            padding: 29px 0 0 72px;
            li{
                font-size: 16px;
                font-family: MicrosoftYaHei;
                color: #333333;
                line-height: 21px;
                margin-bottom: 12px;
            }
        }
    }
}
</style>