<template>
  <div class="recruitment">
    <div class="ment">
      <div class="talent_title"> 职位搜索</div>
      <div class="talent_input">
        <el-input placeholder="请输入职位关键字" v-model="input3">
          <img slot="suffix" :src="require('@/assets/jyd_home/Recruitment/input.png')" alt="" class="input_img">
        </el-input>
      </div>
      <div class="talent_table">
        <div class="table_title" @click="hbleTable(0)"> 工作地点 <img slot="suffix"
            :src="require('@/assets/jyd_home/Recruitment/arrow.png')" alt="" class="input_img"></div>
        <div class="table_title" @click="hbleTable(1)"> 职位类别 <img slot="suffix"
            :src="require('@/assets/jyd_home/Recruitment/arrow.png')" alt="" class="input_img"></div>
      </div>
      <div class="talent_tbalist">
        <div>
          <Table :data="data" />
        </div>
      </div>
    </div>
    <el-drawer :visible.sync="drawer" direction="btt" :before-close="handleClose">
      <template #title>
        <div class="newtitle">{{ drawerTitle }}
          <img :src="require('@/assets/jyd_home/Recruitment/close.png')" alt="" class="close" @click="handleClose">
        </div>

      </template>
      <div class="itemList">

        <el-checkbox class="item" :indeterminate="isIndeterminate" v-model="checkAll"
          @change="handleCheckAllChange">全选</el-checkbox>
        <el-checkbox-group v-model="checkList" @change="handleCheckedCitiesChange">
          <el-checkbox :label="item.value" class="item" v-for="(item, index) in dLisraList " :key="'item' + index">{{
            item.label }}</el-checkbox>

        </el-checkbox-group>
      </div>
      <div class="itembuts">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" class="submit">提交 </el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { Introduc } from '../components/data'
import Table from "./table.vue";
export default {
  components: {
    Table,
  },
  data() {
    return {
      figure: '',
      input3: '',
      drawer: false,
      drawerTitle: '工作地点',
      checkList: [],
      isIndeterminate: false,
      checkAll: false,
      data: {
        data: [{ title: "FBA业务员", address: '广东-深圳市-龙岗区', num: '5-20K', range: '经验不限', level: '大专' },
        { title: "客服专员", address: '广东-深圳市-龙岗区', num: '6-8K', range: '经验不限', level: '大专' },
        { title: "仓库管理员", address: '广东-深圳市-龙岗区', num: '6-8K', range: '经验不限', level: '大专' },
        { title: "电话销售", address: '广东-深圳市-盐田区', num: '5-20K', range: '经验不限', level: '大专' },
        { title: "人事行政经理", address: '广东-深圳市-盐田区', num: '20-25K', range: '经验不限', level: '本科' },
        { title: "财务经理", address: '广东-深圳市-盐田区', num: '20-25K', range: '三年以上', level: '本科' },
        ], total: '13', present: '1'
      },
      dLisraList: [
        { label: "北京", value: '1' },
        { label: "上海", value: '2' },
        { label: "深圳", value: '3' },
        { label: "广州", value: '4' },
        { label: "南京", value: '5' },
        { label: "武汉", value: '6' },
        { label: "香港", value: '7' },
        { label: "宁波", value: '8' },
        { label: "江门", value: '9' },
        { label: "澳门", value: '10' },
      ]
    }
  },
  created() {
    this.figure = Introduc
  },
  methods: {
    hbleTable(index) {
      let dom = document.querySelectorAll('.table_title')[index]
      if (!dom.className.includes('active')) {
        dom.classList = 'table_title active'
      } else {
        dom.classList = 'table_title blusfirst'
      }
      console.log(dom.className.includes('active'));
      this.drawer = !this.drawer
      this.drawerTitle = index == 1 ? '职位类别' : '工作地点'
    },
    handleClose() {
      this.drawer = false
      let num = this.drawerTitle == '工作地点' ? 0 : '1'
      document.querySelectorAll('.table_title')[num].classList = 'table_title blusfirst'
    },
    handleCheckAllChange(val) {
      let data = this.dLisraList.map(item => {
        return item.value
      })
      this.checkList = val ? data : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.dLisraList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.dLisraList.length;
      console.log(value, checkedCount > 0, checkedCount < this.dLisraList.length);
    }
  },
}
</script>

<style lang="less" scoped>
.recruitment {
  width: 100vw;
  height: calc((597/10)*1rem);
  background: rgba(229, 239, 255, 0.36);
  padding: calc((12/10)*1rem) calc((16/10)*1rem);
}

.ment {
  width: 100%;
  height: calc((573/10)*1rem);
  background: #FFFFFF;
  border-radius: calc((2/10)*1rem);
  padding: calc((12/10)*1rem) calc((16/10)*1rem) 0;

  .talent_title {
    font-size: calc((16/10)*1rem);
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #333333;
    line-height: calc((21/10)*1rem);

  }

  .talent_input {
    width: 100%;
    height: calc((38/10)*1rem);
    background: #F6F9FF;
    border-radius: calc((2/10)*1rem);
    margin-top: calc((15/10)*1rem);

    .input_img {
      width: calc((15/10)*1rem);
      height: calc((15/10)*1rem);
      margin: calc((11/10)*1rem) calc((10/10)*1rem) 0 0;
    }

    ::v-deep .el-input__inner {
      background: #F6F9FF;
      border: 0;

      &::placeholder {
        font-size: calc((12/10)*1rem);
        font-family: MicrosoftYaHei;
        color: #9B9B9B;
        line-height: calc((16/10)*1rem);
      }
    }
  }

  .talent_table {
    width: 100%;
    height: calc((54/10)*1rem);
    border-bottom: calc((1/10)*1rem)solid #EDEDED;
    display: flex;
    justify-content: center;

    .table_title {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: calc((13/10)*1rem);
      font-family: MicrosoftYaHei;
      color: #333333;
      line-height: calc((17/10)*1rem);

      &:first-child {
        margin-right: calc((84/10)*1rem);
      }

      img {
        margin-left: calc((6/10)*1rem);
        width: calc((12/10)*1rem);
        height: calc((8/10)*1rem);
      }

      // &:hover,
      // &:active {
      //   img {
      //     transform: rotate(180deg);
      //     animation: myfirst 0.3s;
      //   }
      // }
    }

    .active {
      img {
        transform: rotate(180deg);
        animation: myfirst 0.3s;
      }

    }

    .blusfirst {
      img {
        transform: rotate(0deg);
        animation: blusfirst 0.3s;
      }

    }
  }


}

::v-deep .el-drawer__wrapper {

  .el-drawer__open .el-drawer.btt {

    height: calc((623/10)*1rem) !important;
    border-radius: calc((6/10)*1rem) calc((6/10)*1rem) 0px 0px;

    .el-drawer__header {
      padding: 0;
      margin: 0;

      .el-drawer__close-btn {
        display: none;
      }
    }
  }

}

::v-deep .el-checkbox__label {
  color: #333333;
}

.newtitle {
  width: 100%;
  height: calc((61/10)*1rem);
  border-radius: calc((6/10)*1rem) calc((6/10)*1rem) 0px 0px;
  font-size: calc((16/10)*1rem);
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #333333;
  line-height: calc((21/10)*1rem);
  text-align: center;
  position: relative;
  background-color: #FFFFFF;
  padding: calc((22/10)*1rem) calc((19/10)*1rem);

  .close {
    position: absolute;
    height: calc((15/10)*1rem);
    width: calc((15/10)*1rem);
    top: calc((25/10)*1rem);
    right: calc((19/10)*1rem);
    z-index: 2000;
  }
}


.itemList {
  width: 100%;
  height: calc((460/10)*1rem);
  overflow: auto;
  padding: 0 calc((31/10)*1rem);

  ::v-deep .el-checkbox-group {
    width: 100%;
    display: flex;
    flex-direction: column;


  }

  .item {
    height: calc((45/10)*1rem);
    border-bottom: calc((1/10)*1rem) solid #EDEDED;
    display: flex;
    align-items: center;
    margin-right: 0;

    ::v-deep .el-checkbox__input {
      line-height: 0;
      height: calc((18/10)*1rem);
      width: calc((18/10)*1rem);

    }

    ::v-deep .el-checkbox__inner {
      height: calc((18/10)*1rem);
      width: calc((18/10)*1rem);
      // // background-color: #357BFF;
      // border-color: #357BFF;

      &:after {
        width: calc((5/10)*1rem);
        height: calc((10/10)*1rem);
        left: calc((5.5/10)*1rem);
      }
    }

    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #357BFF;
      border-color: #357BFF;
    }

    ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
      color: #357BFF;
    }

  }
}

.itembuts {

  margin-top: calc((24/10)*1rem);
  padding: 0 calc((31/10)*1rem);
  display: flex;
  justify-content: space-between;

  .cancel,
  .submit {
    width: calc((147/10)*1rem);
    height: calc((38/10)*1rem);
    border-radius: calc((2/10)*1rem);
    border: 1px solid #D5D5D5;
    font-size: calc((14/10)*1rem);
    font-family: MicrosoftYaHei;
    line-height: calc((19/10)*1rem);
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .submit {
    color: #FFFFFF;
    background: #357BFF;
  }

}

@keyframes myfirst {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

@keyframes blusfirst {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
}
</style>