<template>
  <div class="home">
    <div class="product">
      <div class="HproductService">
        <div class="product_title"> 产品服务 </div>
        <ProductService />
      </div>
      <div class="Qualificatio">
        <div class="product_title"> 荣誉资质 </div>
        <Qualificatio />
      </div>
      <Partner />
    </div>
  </div>
</template>

<script>
import ProductService from "./components/productService.vue";
import Qualificatio from "./components/qualification.vue";
import Partner from "./components/partner.vue";
export default {
  components: {
    ProductService,
    Qualificatio,
    Partner
  },

}
</script>

<style lang="less" scoped>
.home {
  width: 100vw;

  .product {
    width: 100vw;
    // height: calc((542/10)*1rem);



    .HproductService {
      height: calc((542/10)*1rem);
      background-image: url('../../assets/jyd_home/home/product.png');
      background-position: 50% 50%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 0 calc((12/10)*1rem);
    }

    .product_title {
      width: 100%;
      text-align: center;
      font-size: calc((16/10)*1rem);
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: calc((21/10)*1rem);
      padding: calc((22/10)*1rem) 0 calc((17/10)*1rem) 0;
    }


  }
}
</style>