var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"productHomeService"},[_c('div',{staticClass:"swiperProduct"},[_c('div',{staticClass:"swiper-container productHomeSwiper"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.data),function(item,index){return _c('div',{key:' productHome' + index,staticClass:"swiper-slide"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"text"},[(typeof item.text == 'object')?_c('div',_vm._l((item.text),function(it,id){return _c('div',{key:'text' + id,staticClass:"text"},[_vm._v(" "+_vm._s(it)+" ")])}),0):_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(item.text)+" "),(item.titlebold)?_c('div',[_c('span',{staticClass:"titlebold"},[_vm._v(_vm._s(item.titlebold))]),_vm._v(_vm._s(item.textbold)+" ")]):_vm._e()])]),(item.title2)?_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title2))]):_vm._e(),(item.title2)?_c('div',{staticClass:"text"},[(typeof item.text2 == 'object')?_c('div',_vm._l((item.text2),function(it,id){return _c('div',{key:'text' + id,staticClass:"text2"},[_vm._v(" "+_vm._s(it)+" ")])}),0):_c('div',{staticClass:"text"},[_vm._v(_vm._s(item.text2))])]):_vm._e(),(item.platformData)?_c('div',{staticClass:"tabPaneContent"},_vm._l((item.platformData),function(it,id){return _c('div',{key:'tabPane' + id,staticClass:"card",style:('margin-right: ' +
                it.right / 10 +
                'rem;margin-left: ' +
                it.left / 10 +
                'rem')},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(it.text))]),_c('span',{staticClass:"num"},[_vm._v(_vm._s(it.num)),_c('span',{staticClass:"symbol"},[_vm._v(_vm._s(it.danwei))])])])}),0):_vm._e(),_c('div',{staticClass:"image",style:('margin: -' +
              item.top / 10 +
              'rem 0 ' +
              item.imgbot / 10 +
              'rem 0')},[_c('img',{style:('height:calc((' + item.height + '/10)*1rem);'),attrs:{"src":require('@/assets/jyd_home/home/' + item.image),"alt":""}})]),(item.button)?_c('div',{staticClass:"PButtons"},_vm._l((item.button),function(it,id){return _c('div',{key:'text' + id,staticClass:"PButton"},[_vm._v(" "+_vm._s(it)+" ")])}),0):_vm._e()])}),0),_c('div',{staticClass:"swiper-pagination"})])])])}
var staticRenderFns = []

export { render, staticRenderFns }