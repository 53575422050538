<template>
  <div class="details">
    <div class="ment" v-if="detailedList">
      <div class="talent_title"> 职位详情</div>
      <div class="details_top">
        <div class="title">{{ detailedList.title }}</div>
        <div class="butt">申请职位</div>
      </div>
      <div class="hengxiang"></div>
      <div class="requirement">
        <div class="left">
          <div class="title">招聘人数：{{ detailedList.num }}人 </div>
          <div class="title "> 薪酬范围：{{ detailedList.gongzi }}</div>
          <div class="title">经验要求：{{ detailedList.jingyan }} </div>
        </div>
        <div class="right">
          <div class="title">招聘有效期：2月～5月 </div>
          <div class="title">学历要求：{{ detailedList.xueli }}</div>
        </div>
      </div>
      <div class="hengxiang"></div>
      <div class="details_top" style="margin:  calc((15/10)*1rem) 0">
        <div class="title">工作职责</div>
      </div>
      <div class="detailed">
        <div class="text" v-for="(item, index) in detailedList.detailedList[0]" :key="'detailed1' + index">
          {{ item }} </div>
      </div>
      <div class="hengxiang"></div>
      <div class="details_top" style="margin:  calc((15/10)*1rem) 0">
        <div class="title">岗位要求</div>
      </div>
      <div class="detailed">
        <div class="text" v-for="(item, index) in detailedList.detailedList[1]" :key="'detailed1' + index">
          {{ item }} </div>
      </div>
    </div>
    <div v-else>
      空
    </div>
  </div>
</template>

<script>
import { details } from './data'
export default {
  components: {
  },
  data() {
    return {
      detailedList: {
        title: "人事经理",
        num: '1',
        gongzi: '20-25K',
        detailedList: [
          [
            '1、负责公司各部门定岗定编及人员优化等相关工作；',
            '2、负责培训管理体系建设，推动培训体系落地执行；',
            '3、负责绩效管理体系优化改进，指导各部门优化绩效考核指标，   协助各部门进行绩效改进；',
            '4、负责部门团队建设，对部门人员进行培训、指导、考核及激励；',
            '5、协助上级领导设计并推动管理改善项目，提升公司综合管理水平；',
            '6、负责行政管理体系的构建与完善，并推动执行；',
            '7、负责员工活动的组织，提高员工的归属感和凝聚力；',
            '8、负责当地政府部门和行政机构的对接。'
          ],
          [
            '1、全日制本科学历，人力资源相关专业，持二级人力师证优先；',
            ' 2、熟悉人力资源6大模块，精通培训或者薪酬绩效管理模块； ',
            '3、具有很强的激励、沟通、协调、团队协作能力，抗压能力强、责任心、事业心强，有物流或相关行业管理经验者优先考。'
          ]
        ]
      }

    }
  },
  created() {
    let name = this.$router.history.current.query.name
    console.log(details);
    let data = details.find(ite => {
      console.log(ite.title, name);
      return ite.title == name
    })
    console.log(data);
    if (!data) return
    this.detailedList = data
    this.detailedList.detailedList = [data.zhize.split('；'), data.yaoqiu.split('；')]
  },


}
</script>

<style lang="less" scoped>
.details {
  width: 100vw;
  // height: calc((632/10)*1rem);
  background: rgba(229, 239, 255, 0.36);
  padding: calc((12/10)*1rem) calc((12/10)*1rem);
}

.ment {
  width: 100%;
  // height: calc((608/10)*1rem);
  background: #FFFFFF;
  border-radius: calc((2/10)*1rem);
  padding: calc((12/10)*1rem) calc((16/10)*1rem) 0;

  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;

  .talent_title {
    font-size: calc((16/10)*1rem);
    font-weight: bold;
    color: #333333;
    line-height: calc((21/10)*1rem);

  }

  .details_top {
    display: flex;
    justify-content: space-between;
    margin: calc((17/10)*1rem) 0;
    align-items: center;

    .title {
      font-size: calc((14/10)*1rem);
      font-weight: bold;
      color: #333333;
      line-height: calc((19/10)*1rem);
    }

    .butt {
      width: calc((109/10)*1rem);
      height: calc((32/10)*1rem);
      background: #357BFF;
      border-radius: calc((2/10)*1rem);
      font-size: calc((13/10)*1rem);
      color: #FFFFFF;
      line-height: calc((17/10)*1rem);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .requirement {
    width: 100%;
    height: calc((106/10)*1rem);
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    color: #333333;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: calc((6/10)*1rem) 0 calc((17/10)*1rem);

    .left,
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .right {
      align-items: flex-end;
    }

    .title {
      font-size: calc((13/10)*1rem);
      color: #333333;
      line-height: calc((17/10)*1rem);
      margin-top: calc((12/10)*1rem);
    }



  }

  .detailed {
    width: 100%;
    padding-bottom: calc((17/10)*1rem);

    .text {
      width: 100%;
      text-align: center;
      font-size: calc((11/10)*1rem);
      font-family: MicrosoftYaHei;
      color: #333333;
      line-height: calc((18/10)*1rem);
      text-align: left;
      word-break: break-all;
      white-space: 0;
      z-index: 2;
    }
  }

}

.hengxiang {
  width: 100%;
  height: calc((1/10)*1rem);
  opacity: 0.36;
  border-bottom: calc((1/10)*1rem) solid #CCCCCC;

}
</style>