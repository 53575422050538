<template>
  <div class="container">
    <!-- 产品服务 -->
    <div class="product1">
      <div class="product" id="productService">
        <div class="title">产品服务</div>
        <!-- <div class="titles">PRODUCT SERVICE</div> -->
        <div class="proIcon">
          <div class="productCatalog">
            <ul>
              <li :class="isShow == 4 ? 'liHover' : ''" @click="Show(4)">
                报关
              </li>
              <li :class="isShow == 1 ? 'liHover' : ''" @click="Show(1)">
                空运
              </li>
              <li :class="isShow == 8 ? 'liHover' : ''" @click="Show(8)">
                海运
              </li>
              <li :class="isShow == 6 ? 'liHover' : ''" @click="Show(6)">
                铁路
              </li>
              <li :class="isShow == 2 ? 'liHover' : ''" @click="Show(2)">
                电商物流
              </li>
              <li :class="isShow == 7 ? 'liHover' : ''" @click="Show(7)">
                运输
              </li>
              <li :class="isShow == 5 ? 'liHover' : ''" @click="Show(5)">
                仓储
              </li>
              <li :class="isShow == 3 ? 'liHover' : ''" @click="Show(3)">
                系统研发
              </li>
              <!-- <li :class="isShow==9?'liHover':''" @click="Show(9)">海外进口流程</li> -->
            </ul>
          </div>
          <div class="productIntroduction">
            <div class="show" v-show="isShow == 1">
              <div class="showAll">
                <div class="productIntroduction_left">
                  <p class="productRightTitle">空运</p>
                  <p class="productRightText">
                    联合多家知名国内外航司，携手推出空运进出口包机服务，
                    与中州航空/南方航空/东方航空/新加坡航空/顺丰航空/阿特拉斯/俄罗斯航空/阿联酋航空等多家航司建立长期友好合作关系。
                  </p>
                </div>
                <div class="productIntroduction_right">
                  <img src="../../assets/img/product/ky.png" alt="" />
                </div>
              </div>
              <div class="productIntroduction_buttom">
                <div class="productIntroduction_item">空运出口</div>
                <div class="productIntroduction_item">空运进口</div>
                <div class="productIntroduction_item">空运大件货</div>
                <div class="productIntroduction_item">空运包机</div>
              </div>
            </div>
            <div class="show" v-show="isShow == 2">
              <div class="showAll">
                <div class="productIntroduction_left">
                  <p class="productRightTitle">电商物流</p>
                  <p class="productRightText">
                    由佳裕达集团打造的跨境电子商务物流平台，其服务区域横跨美、亚大洲，持续为美国亚马逊、
                    印度亚马逊以及台湾虾皮等全球电子商务企业提供国际一流的物流运输服务。物流运输的细化业务含仓库存储、分拣打包、快递发货等电子商务综合服务。
                  </p>
                </div>
                <div class="productIntroduction_right">
                  <img src="../../assets/img/product/dswl.png" alt="" />
                </div>
              </div>
              <div class="productIntroduction_buttom">
                <div class="productIntroduction_item">空派</div>
                <div class="productIntroduction_item">空卡</div>
                <div class="productIntroduction_item">海派</div>
                <div class="productIntroduction_item">海卡</div>
              </div>
            </div>
            <div class="show" v-show="isShow == 3">
              <div class="showAll">
                <div class="productIntroduction_left">
                  <p class="productRightTitle">系统研发</p>
                  <p class="productRightText">
                    秉承10年的综合物流服务理念和经验，2015年开始注重发展物流领城信息自动化技术创新和升级。组建有着十余年丰富物流开发经验的专家团队，涵盖物联网、大数据分析、解决方案、产品研发等诸多技术领域，自主研发集团数智化信息平台，全程可视化追踪。
                  </p>
                </div>
                <div class="productIntroduction_right">
                  <img src="../../assets/img/product/wlyf.png" alt="" />
                </div>
              </div>
              <div class="productIntroduction_buttom">
                <div class="productIntroduction_item">报关系统</div>
                <div class="productIntroduction_item">货代系统</div>
                <div class="productIntroduction_item">供应链系统</div>
                <div class="productIntroduction_item">运输系统</div>
                <div class="productIntroduction_item">仓储系统</div>
                <div class="productIntroduction_item">电商物流系统</div>
              </div>
            </div>
            <div class="show" v-show="isShow == 4">
              <div class="showAll">
                <div class="productIntroduction_left">
                  <p class="productRightTitle">报关</p>
                  <p class="productRightText">
                    深圳市佳裕达报关有限公司主营口岸清关与仓储、运输等拓展性业务，通过科学高效的管理手段，使用自主研发的高新技术打造的系统操作平台，
                    成功打造最具优势的链条式服务平台。我们秉持团结上进的企业文化，贯彻“专业报关、诚信服务”的经营理念。
                  </p>
                  <p class="productRightText">
                    <span style="font-weight: 600">主要业务：</span
                    >进出口清关、属地报关、快件进出口、ATA单证册、修理物品、退运货物等特殊作业通关服务、9610，9710等跨境电商出口报关。
                  </p>
                </div>
                <div class="productIntroduction_right">
                  <img src="../../assets/img/product/bgyw@2x.png" alt="" />
                </div>
              </div>
              <div class="productIntroduction_buttom">
                <div class="productIntroduction_item">报关代理</div>
                <div class="productIntroduction_item">跨境电商</div>
                <div class="productIntroduction_item">查验代理</div>
              </div>
            </div>
            <div class="show" v-show="isShow == 5">
              <div class="showAll">
                <div class="productIntroduction_left">
                  <p class="productRightTitle">仓储</p>
                  <p class="productRightText">
                    佳裕达集团是综合型国际货运代理有限公司，在深圳、香港及海外拥有多所仓库及配送车辆，可提供装卸、运输、存储、包装、分拣、贴标等一站式服务。仓储区域有专业的叉车设备、专用大型三层承重货架、小件货架、分拣车、收发专用PDA设备和扫描枪。同时，仓储区域24小时设置全方位监控及保安执勤，消防设施齐全，安保管控严格。主要仓库有：
                  </p>
                </div>
                <div class="productIntroduction_right">
                  <img src="../../assets/img/product/ck@2x.png" alt="" />
                </div>
              </div>
              <div class="productIntroduction_buttomC">
                <div class="productIntroduction_itemText">
                  <p class="itemTextO">大铲湾综合仓</p>
                  <p class="itemTextT">占地面积15000<span class="itemTextS">m²</span>
                  </p>
                </div>
                <div class="productIntroduction_itemText">
                  <p class="itemTextO">龙岗仓库</p>
                  <p class="itemTextT">占地面积8000<span class="itemTextS">m²</span></p>
                </div>
                <div class="productIntroduction_itemText">
                  <p class="itemTextO">盐田保税</p>
                  <p class="itemTextT">占地面积3000<span class="itemTextS">m²</span></p>
                </div>
                <div class="productIntroduction_itemText">
                  <p class="itemTextO">香港仓库</p>
                  <p class="itemTextT">占地面积5000<span class="itemTextS">m²</span></p>
                </div>
                <div
                  class="productIntroduction_itemText" 
                >
                  <p class="itemTextO">义乌仓库</p>
                  <p class="itemTextT">占地面积1200<span class="itemTextS">m²</span></p>
                </div>
                <div
                  class="productIntroduction_itemText" 
                >
                  <p class="itemTextO">海外仓库</p>
                  <p class="itemTextT">美国洛杉机、印度、泰国</p>
                </div>
              </div>
            </div>
            <div class="show" v-show="isShow == 6">
              <div class="showAll">
                <div class="productIntroduction_left">
                  <p class="productRightTitle">铁路</p>
                  <p class="productRightText">
                    深圳“湾区号”的订舱服务，珠三角区域内提供门到站服务，目的站点覆盖马拉、汉堡、杜堡、莫斯科、明斯克、塔什干、阿拉木图、老挝万象等。重庆、郑州、西安往返马拉、汉堡、杜堡、莫斯科、明斯克
                    进出口整柜订舱、散货集拼。
                  </p>
                </div>
                <div class="productIntroduction_right">
                  <img src="../../assets/img/product/ty.png" alt="" />
                </div>
              </div>
              <div class="productIntroduction_buttom">
                <div class="productIntroduction_item">出口铁路整箱</div>
                <div class="productIntroduction_item">出口铁路拼箱</div>
                <div class="productIntroduction_item">进口铁路整箱</div>
                <div class="productIntroduction_item">进口铁路拼箱</div>
              </div>
            </div>
            <div class="show" v-show="isShow == 7">
              <div class="showAll">
                <div class="productIntroduction_leftY">
                  <p class="productRightTitle">运输</p>
                  <p class="YtextOne">
                    佳裕达集团已开通全国各地直达深圳的多条物流专线，包含中港跨境、香港本地、深圳城际、珠江三角洲、华南-华东和华南-川渝专线，主营国内铁路、公路、航空、中港货物、吨车往返。自创办以来，深受广大客户的信任和支持。
                  </p>
                  <p class="YtextOne">
                    珠江三角洲业务范围内，公司配备运输服务业内的技术骨干和专业团队，采用各类车型来满足各种货物运输需求；中港跨境业务方面，主要提供深圳与各大港口的拖车服务，已经形成一套完备、高效的流程体系。
                  </p>
                  <p class="YtextOne">
                    我司独立拥有60多辆拖车、挂靠拖车和单拖、双拖、重柜双拖、转关车等多种车型全程采用GPS跟踪管理。拖车部提供24小时服务，实现同行货代、司机、货主三者沟通和谐。
                  </p>
                </div>
                <!-- <div class="productIntroduction_right">
                    <img src="../../assets/img/product/bgyw.png" alt="">
                  </div> -->
              </div>
              <div class="productIntroduction_buttomY">
                <div class="productIntroduction_item">内陆运输</div>
                <div class="productIntroduction_item">中港运输</div>
                <div class="productIntroduction_item">拖车运输</div>
              </div>
            </div>
            <div class="show" v-show="isShow == 8">
              <div class="showAll" style="display: block">
                <div class="productIntroduction_leftH">
                  <p class="productRightTitle">海运</p>
                  <p class="productRightText">
                    佳裕达集团经国家交通部批准，具有无船承运业务资格。集团主营海运集装箱、拼箱货物、海运散杂货运输等海运进出口服务。我司与HPL/YML/MSC/CMA/MCC/-COSCO多家船运公司合作，在境内外均拥有健全的服务网络机制，为客户提供从订舱、报关、转关、仓储、清关、代办货运保险及各证书、代理检验/检疫等一系列物流服务。
                  </p>
                </div>
                <div class="productIntroductionMiddleH">
                  <div>
                    <p class="productRightTitleH">海外进口流程</p>
                    <p class="productRightText">
                      1、审核客户提供的各类清关文件。
                    </p>
                    <p class="productRightText">2、编制各类单证、订舱、报关。</p>
                    <p class="productRightText">
                      3、办理产地证、保险单和商检证。
                    </p>
                    <p class="productRightText">
                      4、集装箱租赁和装箱、国内运输、进港、装船、签发提单、运杂费结算。
                    </p>
                    <p class="productRightText">
                      5、对接国外进口报关、纳税、拆箱/转运、派送到国外代理手上。
                    </p>
                  </div>
                  <div class="productIntroduction_rightH">
                    <img src="../../assets/img/product/hw@2x.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="show" v-show="isShow==9">
                <div class="showAll">
                  <div class="productIntroduction_left">
                    <p class="productRightTitle">海外进口流程</p>
                    <p class="productRightTextItem">1、审核客户提供的各类清关文件。</p>
                    <p class="productRightTextItem">2、编制各类单证、订舱、报关。</p>
                    <p class="productRightTextItem">3、办理产地证、保险单和商检证。</p>
                    <p class="productRightTextItem">4、集装箱租赁和装箱、国内运输、进港、装船、签发提单、运杂费结算。</p>
                    <p class="productRightTextItem">5、对接国外进口报关、纳税、拆箱/转运、派送到国外代理手上。</p>
                  </div>
                  <div class="productIntroduction_right">
                    <img src="../../assets/img/product/hw.png" alt="">
                  </div>
                </div>
                <div class="productIntroduction_buttom">
                  <div class="productIntroduction_item">空运包机</div>
                  <div class="productIntroduction_item">空运包机</div>
                  <div class="productIntroduction_item">空运包机</div>
                  <div class="productIntroduction_item">空运包机</div>
                  <div class="productIntroduction_item">空运包机</div>
                  <div class="productIntroduction_item">空运包机</div>
                  <div class="productIntroduction_item">空运包机</div>
                </div>
            </div> -->
          </div>
          <!-- </div> -->
          <!-- <el-row :gutter="20" type="flex">
            <el-col :span="6">
              <div style="height: 70px;">
                <div class="proIcon-icon kongyunHover" style="margin-left: 80px">
                <img class="kongyunShow" src="@/assets/icon/showTu/kongyunShow.png"/>
                  <img
                    src="@/assets/icon/kongyun.png"
                    width="100%"
                    style="width: 73px; height: 40px;margin-left:3px"
                    alt=""
                  />
                  <div class="tit" style="margin-top: 20px">空运</div>
                  <el-button
                    size="mini"
                    class="btn kongyunBtn"
                    >详情 ></el-button
                  >
                </div>
              </div></el-col
            >
            <diV style="height: 70px; border-right: 1px solid #ccc;margin-left: -10px;margin-top: 38px;"></diV>
            <el-col :span="6">
              <div style="height: 70px;">
                <div class="proIcon-icon haiyunHover" style="margin-left: 90px">
                <img class="haiyunShow" src="@/assets/icon/showTu/haiyunShow.png"/>
                  <img
                    src="@/assets/icon/haiyun.png"
                    style="width: 65px; height: 59px"
                    alt=""
                  />
                  <div class="tit" style="margin-top: 2px">海运</div>
                  <el-button
                    size="mini"
                    class="btn haiyunBtn"
                    >详情 ></el-button
                  >
                </div>
              </div></el-col
            >
            <diV style="height: 70px; border-right: 1px solid #ccc;margin-left: -10px;margin-top: 38px;"></diV>
            <el-col :span="6"
              ><div style="height: 70px;">
                <div class="proIcon-icon dianshangHover" style="margin-left: 90px">
                <img class="dianshangShow" src="@/assets/icon/showTu/dianshangShow.png"/>
                  <img
                    src="@/assets/icon/dianshang.png"
                    width="100%"
                    style="width: 64px; height: 59px"
                    alt=""
                  />
                  <div class="tit" style="margin-top: 3px">电商物流</div>
                  <el-button
                    size="mini"
                    class="btn dianshangBtn"
                    >详情 ></el-button
                  >
                </div>
              </div></el-col
            >
            <diV style="height: 70px; border-right: 1px solid #ccc;margin-left: -10px;margin-top: 38px;"></diV>
            <el-col :span="6"
              ><div>
                <div class="proIcon-icon kuajingHover" style="margin-left: 90px">
                  <img class="kuajingShow" src="@/assets/icon/showTu/kuajingShow.png"/>
                  <img src="@/assets/icon/kuajing.png" width="100%" alt="" />
                  <div class="tit" style="margin-top: 2px">跨境运输</div>
                  <el-button
                    size="mini"
                    class="btn kuajingBtn"
                    >详情 ></el-button
                  >
                </div>
              </div></el-col
            >
          </el-row>
          <el-row
            :gutter="20"
            style="margin-top: 120px; !important"
            type="flex"
          >
            <el-col :span="6"
              ><div style="height: 70px;">
                <div class="proIcon-icon bapguanHover" style="margin-left: 80px">
                <img class="bapguanShow" src="@/assets/icon/showTu/bapguanShow.png"/>
                  <img
                    src="@/assets/icon/bapguan.png"
                    width="100%"
                    style="width: 51px; height: 62px;margin-left:6px;"
                    alt=""
                  />
                  <div class="tit" style="margin-left: -24px; margin-top: 5px">
                    报关
                  </div>
                  <el-button
                    size="mini"
                    class="btn bapguanBtn"
                    >详情 ></el-button
                  >
                </div>
              </div></el-col
            >
            <diV style="height: 70px; border-right: 1px solid #ccc;margin-left: -10px;margin-top: 38px;"></diV>
            <el-col :span="6"
              ><div style="height: 70px;">
                <div class="proIcon-icon cangchuHover" style="margin-left: 90px">
                <img class="cangchuShow" src="@/assets/icon/showTu/cangchuShow.png"/>
                  <img
                    src="@/assets/icon/cangchu.png"
                    width="100%"
                    style="width: 64px; height: 59px"
                    alt=""
                  />
                  <div class="tit" style="margin-top: 7px">仓储</div>
                  <el-button
                    size="mini"
                    class="btn cangchuBtn"
                    >详情 ></el-button
                  >
                </div>
              </div></el-col
            >
            <diV style="height: 70px; border-right: 1px solid #ccc;margin-left: -10px;margin-top: 38px;"></diV>
            <el-col :span="6"
              ><div style="height: 70px;">
                <div class="proIcon-icon jishuHover" style="margin-left: 90px">
                <img class="jishuShow" src="@/assets/icon/showTu/jishuShow.png"/>
                  <img
                    src="@/assets/icon/jishu.png"
                    width="100%"
                    style="width: 60px; height: 59px"
                    alt=""
                  />
                  <div class="tit" style="margin-top: 7px">技术开发</div>
                  <el-button
                    size="mini"
                    class="btn jishuBtn"
                    >详情 ></el-button
                  >
                </div>
              </div></el-col
            >
            <diV style="height: 70px; border-right: 1px solid #ccc;margin-left: -10px;margin-top: 38px;"></diV>
            <el-col :span="6"
              ><div>
                <div class="proIcon-icon shangmaoHover" style="margin-left: 90px">
                <img class="shangmaoShow" src="@/assets/icon/showTu/shangmaoShow.png"/>
                  <img
                    src="@/assets/icon/shangmao.png"
                    width="100%"
                    style="width: 64px; height: 59px"
                    alt=""
                  />
                  <div class="tit" style="margin-top: 7px">商贸供应链</div>
                  <el-button
                    size="mini"
                    class="btn shangmaoBtn"
                    >详情 ></el-button
                  >
                </div>
              </div></el-col
            >
          </el-row> -->
        </div>
      </div>
    </div>
    <div class="honors">
      <div class="content" id="honorsAndQualifications">
        <div class="title">荣誉资质</div>
      </div>
      <div class="swiperBg">
        <div class="swiper-container mySwiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in slideList"
              :key="index"
            >
              <div class="img">
                <!-- <el-image
                  :src="item.img"
                  style="width: 100%; height: 100%"
                  fit="scale-down"
                  :preview-src-list="[item.img]">
                </el-image> -->
                <div>
                  <img
                    class="imgSmall"
                    :src="item.img"
                    :width="item.width"
                    :height="item.height"
                  />
                  <img
                    class="imgBig"
                    :src="item.img1"
                    :width="item.width1"
                    :height="item.height1"
                  />
                </div>
              </div>
              <div class="word">
                <div>{{ item.title }}</div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
          <!-- 如果需要导航按钮 -->
          <div class="swiper-button-prev">
            <img
              src="@/views/home/service/components/com/img/left.png"
              alt
              class="button-jiangtou"
            />
          </div>
          <div class="swiper-button-next">
            <img
              src="@/views/home/service/components/com/img/right.png"
              alt
              class="button-jiangtou"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="main" id="cooperativePartner">
      <!-- 合作伙伴 -->
      <div class="partner">
        <el-tabs v-model="active">
          <el-tab-pane label="合作伙伴" name="first">
            <vue-seamless-scroll
              :class-option="optionHover"
              :data="Customers"
              class="seamless-warp"
            >
              <div class="partnerContent">
                <div
                  class="partnerIcon"
                  v-for="(item, index) in Customers"
                  :key="index"
                >
                  <!-- <div class="parIcon"> -->
                  <img :src="item.img" alt="" />
                  <!-- </div> -->
                </div>
              </div>
            </vue-seamless-scroll>
          </el-tab-pane>
          <el-tab-pane label="合作客户" name="second" lazy>
            <vue-seamless-scroll
              :class-option="optionHover"
              :data="parentList"
              class="seamless-warp"
            >
              <div class="partnerContent">
                <div
                  class="partnerIcon"
                  v-for="(item, index) in parentList"
                  :key="index"
                >
                  <!-- <div class="parIcon"> -->
                  <img :src="item.img" alt="" />
                  <!-- </div> -->
                </div>
              </div>
            </vue-seamless-scroll>
          </el-tab-pane>
        </el-tabs>
        <!-- <div class="title">合作伙伴</div> -->
        <!-- <div class="titles">PARTNERS</div> -->
      </div>
      <!-- 新闻资讯 -->
      <!-- <div class="newsContent">
        <div class="title">新闻资讯</div> -->
      <!-- <div class="titles">NEWS</div> -->
      <!-- <div class="butt" style="margin-top: 10pxs">
          <el-button class="moreButton">查看更多></el-button>
        </div> -->

      <!-- <div class="newsBorder"> -->
      <!-- <el-tabs v-model="activeName" class="tabsT">
            <el-tab-pane label="集团新闻" name="first">
              <div class="newcontainer">
                <div class="left">
                  <div class="topT">
                    <img
                      src="@/assets/img/newsPic.png"
                      alt=""
                      style="width: 100%"
                    />
                  </div>
                  <div class="BotB">
                    <div class="BotLeft">
                      11/19
                      <span class="leftCont"
                        >佳裕达正式与叶水福物流开展合作</span
                      >
                    </div>
                    <div class="BotRight">
                      2014
                      <span class="botCont"
                        >2014年9月12日，佳裕达国际正式签订于叶水福物流（深圳）有限</span
                      >
                    </div>
                  </div>
                </div>
                <div class="right">
                  <div
                    class="rightCont"
                    v-for="(item, index) in newsList"
                    :key="index"
                  >
                    <div class="contL">
                      <div class="dateT">{{ item.date1 }}</div>
                      <div class="dateB">{{ item.date2 }}</div>
                    </div>
                    <div class="line"></div>
                    <div class="contR">
                      <div class="newT">{{ item.list1 }}</div>
                      <div class="newB">
                        {{ item.list2 }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="行业资讯" name="second"> 行业资讯 </el-tab-pane>
            <el-tab-pane label="政策法规" name="third">政策法规</el-tab-pane>
          </el-tabs> -->
      <!-- <div class="left">
  
          </div>
          <div class="right"></div> -->
      <!-- </div> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  data() {
    return {
      srcList: [],
      slideList: [
        {
          img: require("@/assets/product/honor/pW2.png"),
          title: "无船承运人资质",
          width: "228px",
          hieght: "163px",
          img1: require("@/assets/product/honor/pW3.png"),
          width1: "268px",
          hieght1: "186px",
        },
        {
          img: require("@/assets/product/honor/pD2.png"),
          title: "道路运输许可资质",
          width: "228px",
          hieght: "162px",
          img1: require("@/assets/product/honor/pD3.png"),
          width1: "268px",
          hieght1: "186px",
        },
        {
          img: require("@/assets/product/honor/pA2.png"),
          title: "AAAA级物流企业",
          width: "228px",
          hieght: "163px",
          img1: require("@/assets/product/honor/pA3.png"),
          width1: "268px",
          hieght1: "186px",
        },
        {
          img: require("@/assets/product/honor/pB2.png"),
          title: "报关单位注册登记证书",
          width: "230px",
          hieght: "165px",
          img1: require("@/assets/product/honor/pB3.png"),
          width1: "268px",
          hieght1: "186px",
        },
        {
          img: require("@/assets/product/honor/pG2.png"),
          title: "国际食品经营许可证",
          width: "230px",
          hieght: "165px",
          img1: require("@/assets/product/honor/pG3.png"),
          width1: "268px",
          hieght1: "186px",
        },
        {
          img: require("@/assets/product/honor/pGJ2.png"),
          title: "国际货运代理企业备案表",
          width: "159px",
          hieght: "229px",
          img1: require("@/assets/product/honor/pGJ3.png"),
          width1: "181px",
          hieght1: "259px",
        },
        {
          img: require("@/assets/product/honor/pAEO2.png"),
          title: "AEO认证企业证书",
          width: "159px",
          hieght: "229px",
          img1: require("@/assets/product/honor/pAEO3.png"),
          width1: "181px",
          hieght1: "259px",
        },
        {
          img: require("@/assets/product/honor/pDW2.png"),
          title: "对外贸易经营者备案登记表",
          width: "159px",
          hieght: "229px",
          img1: require("@/assets/product/honor/pDW3.png"),
          width1: "181px",
          hieght1: "259px",
        },
        {
          img: require("@/assets/product/honor/pISO2.png"),
          title: "ISO物流科技",
          width: "159px",
          hieght: "229px",
          img1: require("@/assets/product/honor/pISO3.png"),
          width1: "181px",
          hieght1: "259px",
        },
        {
          img: require("@/assets/product/honor/pZ2.png"),
          title: "自理报检企业备案登记证明书",
          width: "230px",
          hieght: "165px",
          img1: require("@/assets/product/honor/pZ3.png"),
          width1: "263px",
          hieght1: "187px",
        },
      ],
      active: "first",
      activeName: "first",
      swiperList: [
        {
          img: require("@/assets/img/homeBg.png"),
          path: "",
          title: "面向世界，服务全球",
          content: "Face the world, serve the world",
        },
        {
          img: require("@/assets/img/homeBg.png"),
          path: "",
          title: "面向世界，服务全球",
          content: "Face the world, serve the world",
        },
        {
          img: require("@/assets/img/homeBg.png"),
          path: "",
          title: "面向世界，服务全球",
          content: "Face the world, serve the world",
        },
      ],
      Customers: [
        {
          img: require("@/assets/home/NCA.png"),
        },
        {
          img: require("@/assets/home/HK.png"),
        },
        {
          img: require("@/assets/home/mala.png"),
        },
        {
          img: require("@/assets/home/ethiopian.png"),
        },
        {
          img: require("@/assets/home/zhonghuahangkong.png"),
        },
        {
          img: require("@/assets/home/atlas.png"),
        },
        {
          img: require("@/assets/home/airbr.png"),
        },
        {
          img: require("@/assets/home/zhongzhouhangkong.png"),
        },
        {
          img: require("@/assets/home/hapag.png"),
        },
        {
          img: require("@/assets/home/MCC.png"),
        },
        {
          img: require("@/assets/home/MSC.png"),
        },
        {
          img: require("@/assets/home/MAERSK.png"),
        },
        {
          img: require("@/assets/home/CMA.png"),
        },
        {
          img: require("@/assets/home/APL.png"),
        },
        {
          img: require("@/assets/home/HMM.png"),
        },
        {
          img: require("@/assets/home/YANGMING.png"),
        },
        {
          img: require("@/assets/home/Matson.png"),
        },
        {
          img: require("@/assets/home/cosco.png"),
        },
        {
          img: require("@/assets/home/oocl.png"),
        },
        {
          img: require("@/assets/home/ZIM.png"),
        },
      ],
      parentList: [
        {
          img: require("@/assets/img/111位图.png"),
        },
        {
          img: require("@/assets/icon/jingdong.png"),
        },
        {
          img: require("@/assets/icon/cainiao.png"),
        },
        {
          img: require("@/assets/icon/yamaxun1.png"),
        },
        {
          img: require("@/assets/icon/hesaikeji.png"),
        },
        {
          img: require("@/assets/icon/zhongliang.png"),
        },
        {
          img: require("@/assets/icon/anke.png"),
        },
        {
          img: require("@/assets/icon/pulutong.png"),
        },
        {
          img: require("@/assets/icon/xinwanda.png"),
        },
        {
          img: require("@/assets/icon/ele.png"),
        },
        {
          img: require("@/assets/icon/deyidianqi.png"),
        },
        {
          img: require("@/assets/icon/dazujiguang.png"),
        },
        {
          img: require("@/assets/icon/chaungwei.png"),
        },
        {
          img: require("@/assets/icon/lianxiang.png"),
        },
        {
          img: require("@/assets/icon/xiaomi.png"),
        },
        {
          img: require("@/assets/icon/oppo.png"),
        },
        {
          img: require("@/assets/icon/vivo.png"),
        },
        {
          img: require("@/assets/icon/ningdeshidai.png"),
        },
        {
          img: require("@/assets/icon/tcl.png"),
        },
        {
          img: require("@/assets/icon/parker.png"),
        },
      ],
      newsList: [
        {
          date1: "11/19",
          date2: 2014,
          list1: "佳裕达正式与叶水福物流开展合作",
          list2: "2014年9月12日，佳裕达国际正式签订于叶水福物流（深圳）有限…",
        },
        {
          date1: "11/19",
          date2: 2014,
          list1: "佳裕达正式与叶水福物流开展合作",
          list2: "2014年9月12日，佳裕达国际正式签订于叶水福物流（深圳）有限…",
        },
        {
          date1: "11/19",
          date2: 2014,
          list1: "佳裕达正式与叶水福物流开展合作",
          list2: "2014年9月12日，佳裕达国际正式签订于叶水福物流（深圳）有限…",
        },
        {
          date1: "11/19",
          date2: 2014,
          list1: "佳裕达正式与叶水福物流开展合作",
          list2: "2014年9月12日，佳裕达国际正式签订于叶水福物流（深圳）有限…",
        },
      ],
      isShow: 4,
    };
  },
  components: {
    vueSeamlessScroll,
  },
  computed: {
    optionHover() {
      return {
        hoverStop: true,
        step: 0.5,
        limitMoveNum: this.limitMoveNum,
      };
    },
  },
  watch: {
    // 'active':{
    //   handler(newVal){
    //     if(newVal=="second"){
    //       hoverStop=true,
    //       step=0.5,
    //       limitMoveNum=this.limitMoveNum
    //     }
    //   },
    //   immediate:true
    // }
  },
  created() {
    this.$nextTick(() => this.getProductService());
    this.$nextTick(() => this.getHonorsAndQualifications());
    this.$nextTick(() => this.getCooperativePartner());
  },
  mounted() {
    new Swiper(".mySwiper", {
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // rewind: true,
      loop: true,
      autoplay: {
        delay: 3000, //自动切换时间；
        //与loop有关，设置为true，loop失效
        stopOnLastSlide: false,
        //用户操作之后，是否禁止自动切换（默认true禁止/设置false允许）
        disableOnInteraction: false,
      },
      slidesPerView: 5,
      // spaceBetween: 10,
      spaceBetween: 10,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      effect: "coverflow",
      coverflowEffect: {
        rotate: 0,
        stretch: -50,
        depth: 77,
        modifier: 2,
        slideShadows: false,
      },
    });
  },
  methods: {
    getProductService() {
      let select = localStorage.getItem("productService");
      let elm = document.getElementById(select);
      if (select) {
        elm.scrollIntoView(true);
      }
    },
    getHonorsAndQualifications() {
      let select = localStorage.getItem("honorsAndQualifications");
      let elm = document.getElementById(select);
      if (select) {
        elm.scrollIntoView(true);
      }
    },
    getCooperativePartner() {
      let select = localStorage.getItem("cooperativePartner");
      let elm = document.getElementById(select);
      if (select) {
        elm.scrollIntoView(true);
      }
    },
    Show(val) {
      this.isShow = val;
    },
  },
  //页面销毁时将auchorId设为空，防止其他页面进入是有锚点问题
  destroyed() {
    localStorage.setItem("productService", "");
    localStorage.setItem("honorsAndQualifications", "");
    localStorage.setItem("cooperativePartner", "");
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  background-color: #ffffff;
  position: relative;

  .banner .title {
    font-size: 76px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 100px;
    position: absolute;
    top: 40%;
    left: 32%;
  }

  .banner .title2 {
    font-size: 76px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 100px;
    position: absolute;
    top: 50%;
    left: 19%;
  }

  .product1 {
    width: 100%;
    background-image: url("~@/assets/img/prucutBg@2x.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .product {
      position: relative;
      width: 1200px;
      margin: 0 auto;
      height: 719px;

      .side {
        position: fixed;
        top: 247px;
        right: 138px;
      }

      .title {
        padding-top: 70px;
        text-align: center;
        font-size: 24px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #333333;
        line-height: 31px;
      }

      .titles {
        margin-top: 7px;
        text-align: center;
        font-size: 24px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 31px;
      }

      .proIcon {
        width: 100%;
        height: 496px;
        background-image: url("~@/assets/img/CP1@2x.png");
        // margin-left: 86px;
        margin-top: 54px;
        display: flex;

        // width: 50%;
        // height: 65%;
        // background-color: red;
        // position: relative;
        .productCatalog {
          height: 100%;
          width: 281px;
          background-color: #ffffff;
          border-radius: 2px 0px 0px 2px;

          ul {
            // background: #357bff;
            padding: 23px 0;
            font-size: 16px;

            li {
              width: 100%;
              height: 50px;
              padding: 14px 0 14px 38px;
              color: #333333;
            }

            li:hover {
              background: #eff5ff;
              border-left: 3px solid #357bff;
              padding: 14px 0 14px 35px;
            }

            .liHover {
              background: #eff5ff;
              border-left: 3px solid #357bff;
              padding: 14px 0 14px 35px;
            }
          }
        }

        .productRightText {
          font-size: 16px;
          color: #333333;
          line-height: 24px;
          font-family: MicrosoftYaHei;
          flex-wrap: wrap;
        }

        .productIntroduction {
          .show {
            .showAll {
              display: flex;

              .productIntroduction_left {
                width: 453px;
                height: 110px;
                margin-left: 57px;

                .productRightTitle {
                  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                  margin: 55px 0 27px 0;
                  font-size: 21px;
                  font-weight: bold;
                  color: #333333;
                  line-height: 27px;
                }

                .productRightText {
                  font-size: 16px;
                  color: #333333;
                  line-height: 24px;
                  font-family: MicrosoftYaHei;
                  flex-wrap: wrap;
                }

                .productRightTextItem {
                  font-size: 16px;
                  color: #333333;
                  line-height: 24px;
                  margin-bottom: 3px;
                }
              }

              .productIntroduction_leftH {
                width: 815px;
                height: 110px;
                margin-left: 57px;

                .productRightTitle {
                  margin: 55px 0 27px 0;
                  font-size: 21px;
                  font-weight: bold;
                  color: #333333;
                  line-height: 27px;
                }
              }

              .productIntroductionMiddleH {
                padding: 47px 0 0 57px;
                display: flex;

                .productRightTitleH {
                  font-size: 21px;
                  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                  font-weight: bold;
                  color: #333333;
                  line-height: 27px;
                  margin-bottom: 26px;
                }

                .productRightText {
                  font-size: 16px;
                  color: #333333;
                  line-height: 24px;
                  font-family: MicrosoftYaHei;
                  margin-bottom: 3px;
                  width: 465px;
                }

                .productIntroduction_rightH {
                  padding: 52px 0 0 69px;

                  img {
                    width: 273px;
                    height: 159px;
                  }
                }
              }

              .productIntroduction_leftY {
                padding: 55px 0 0 57px;
                width: 848px;

                .productRightTitle {
                  font-size: 21px;
                  font-weight: bold;
                  color: #333333;
                  line-height: 27px;
                }

                .YtextOne {
                  font-size: 16px;
                  color: #333333;
                  line-height: 24px;
                  margin-top: 26px;
                  font-family: MicrosoftYaHei;
                }
              }

              .productIntroduction_right {
                padding: 109px 0 0 80px;

                img {
                  width: 273px;
                  height: 159px;
                }
              }
            }

            .productIntroduction_buttomY {
              display: flex;
              margin: 73px 0 0 57px;
              flex-wrap: wrap;
              width: 656px;

              .productIntroduction_item {
                height: 32px;
                border-radius: 16px;
                border: 1px solid #357bff;
                color: #357bff;
                text-align: center;
                line-height: 32px;
                margin: 0 34px 31px 0;
                padding: 0 32px;
                font-size: 16px;
              }
            }

            .productIntroduction_buttom {
              display: flex;
              margin: 51px 0 0 57px;
              flex-wrap: wrap;
              width: 656px;

              .productIntroduction_item {
                height: 32px;
                border-radius: 16px;
                border: 1px solid #357bff;
                color: #357bff;
                text-align: center;
                line-height: 32px;
                margin: 0 34px 31px 0;
                padding: 0 32px;
                font-size: 16px;
              }
            }

            .productIntroduction_buttomC {
              // width: 143px;
              display: flex;
              justify-content: flex-start;
              margin: 40px 0 0 30px;
              flex-wrap: wrap;
              width: 862px;

              .productIntroduction_itemText {
                width: 25%;
                // margin: 0 61px;
                text-align: center;
                padding-bottom: 32px;

                .itemTextO {
                  font-size: 21px;
                  font-weight: 600;
                  color: #357bff;
                  line-height: 35px;
                  font-weight: bold;
                  // font-family: Oswald-Medium, Oswald;
                }

                .itemTextT {
                  font-size: 15px;
                  color: #357bff;
                  line-height: 17px;
                  margin-top: 5px;

                  .itemTextS {
                    font-size: 13px;
                    line-height: 18px;
                    color: #357bff;
                    font-weight: 400;
                    margin-left: 0px;
                  }
                }
              }
            }
          }
        }

        // .proIcon-icon {
        //   width: 73px;
        //   height: 40px;
        //   .tit {
        //     width: 110px;
        //     margin-left: -20px;
        //     padding: 15px;
        //     text-align: center;
        //     font-size: 16px;
        //     font-family: MicrosoftYaHei;
        //     color: #333333;
        //     line-height: 24px;
        //   }
        //   .kongyunShow{
        //     width: 268px;
        //     height: 151px;
        //     display: none;
        //     position: absolute;
        //     top: -13px;
        //     left: 3px;
        //   }
        //   .haiyunShow{
        //     width: 268px;
        //     height: 151px;
        //     display: none;
        //     position: absolute;
        //     top: -13px;
        //     left: 278px;
        //   }
        //   .dianshangShow{
        //     width: 268px;
        //     height: 151px;
        //     display: none;
        //     position: absolute;
        //     top: -13px;
        //     left: 552px;
        //   }
        //   .kuajingShow{
        //     width: 268px;
        //     height: 151px;
        //     display: none;
        //     position: absolute;
        //     top: -13px;
        //     left: 826px;
        //   }
        //   .bapguanShow{
        //     width: 268px;
        //     height: 151px;
        //     display: none;
        //     position: absolute;
        //     top: -13px;
        //     left: 3px;
        //     z-index: 99;
        //   }
        //   .cangchuShow{
        //     width: 268px;
        //     height: 151px;
        //     display: none;
        //     position: absolute;
        //     top: -13px;
        //     left: 278px;
        //     z-index: 99;
        //   }
        //   .jishuShow{
        //     width: 268px;
        //     height: 151px;
        //     display: none;
        //     position: absolute;
        //     top: -13px;
        //     left: 552px;
        //     z-index: 99;
        //   }
        //   .shangmaoShow{
        //     width: 268px;
        //     height: 151px;
        //     display: none;
        //     position: absolute;
        //     top: -13px;
        //     left: 827px;
        //     z-index: 99;
        //   }
        //   .btn{
        //     width: 103px;
        //     height: 28px;
        //     margin-left: -15px;
        //     text-align: center;
        //   }
        // }
        // .kongyunHover:hover{
        //   .kongyunShow{
        //     display: block;
        //   }
        //   .kongyunBtn{
        //     display: none;
        //   }
        // }
        // .haiyunHover:hover{
        //   .haiyunShow{
        //     display: block;
        //   }
        //   .haiyunBtn{
        //     display: none;
        //   }
        // }
        // .dianshangHover:hover{
        //   .dianshangShow{
        //     display: block;
        //   }
        //   .dianshangBtn{
        //     display: none;
        //   }
        // }
        // .kuajingHover:hover{
        //   .kuajingShow{
        //     display: block;
        //   }
        //   .kuajingBtn{
        //     display: none;
        //   }
        // }
        // .bapguanHover:hover{
        //   .bapguanShow{
        //     display: block;
        //   }
        //   .bapguanBtn{
        //     display: none;
        //   }
        // }
        // .cangchuHover:hover{
        //   .cangchuShow{
        //     display: block;
        //   }
        //   .cangchuBtn{
        //     display: none;
        //   }
        // }
        // .jishuHover:hover{
        //   .jishuShow{
        //     display: block;
        //   }
        //   .jishuBtn{
        //     display: none;
        //   }
        // }
        // .shangmaoHover:hover{
        //   .shangmaoShow{
        //     display: block;
        //   }
        //   .shangmaoBtn{
        //     display: none;
        //   }
        // }
      }
    }
  }

  .main {
    width: 1200px;
    margin: 0 auto;
    margin-top: -40px;

    .partner {
      width: 100%;
      height: 542px;

      /deep/ .el-tabs__nav-scroll {
        display: flex;
        justify-content: center;
        align-items: center;

        div#tab-first {
          font-size: 24px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #333333;
        }

        div#tab-second {
          font-size: 24px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #333333;
        }

        .el-tabs__item {
          padding: 0;
          margin-right: 132px;
        }

        .el-tabs__item:last-child {
          margin: 0;
        }

        .el-tabs__active-bar {
          display: none;
        }

        .el-tabs__item:hover,
        .is-active {
          border-bottom: 3px solid #357bff;
        }
      }

      .title {
        padding-top: 25px;
        text-align: center;
        font-size: 24px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #333333;
        line-height: 31px;
      }

      .titles {
        margin-top: 7px;
        text-align: center;
        font-size: 24px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 31px;
      }

      .seamless-warp {
        height: 340px;
        overflow: hidden;
        margin-top: 63px;

        .partnerContent {
          width: 1200px;
          height: 448px;
          // margin-left: 360px;
          display: flex;
          flex-wrap: wrap;
          // align-content: space-between;
          justify-content: space-around;

          // text-align: center;
          .partnerIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            // margin-left: 21px;
            // margin-bottom: 40px;
            width: 223px;
            height: 82px;
            background: #ffffff;
            box-shadow: 0px 2px 23px 0px #e8e8e8;
            border-radius: 4px;

            // float: left;
            .parIcon {
              width: 181px;
              height: 51px;
              margin-left: 21px;
              padding-top: 15px;
            }
          }
        }
      }
    }

    .newsContent {
      /deep/.el-tabs__header.is-top {
        width: 800px;
      }

      /deep/.tabsT.el-tabs.el-tabs--top {
        margin-top: 30px;
      }

      width: 100%;
      height: 567px;

      // margin-top: 63px;
      .title {
        // padding-top: 25px;
        text-align: center;
        font-size: 24px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #333333;
        line-height: 31px;
      }

      .titles {
        margin-top: 7px;
        text-align: center;
        font-size: 24px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 31px;
      }

      .butt {
        float: right;
        margin-top: 10px;
      }

      .newsBorder {
        /deep/ .el-tabs__nav-scroll {
          display: flex;

          // justify-content: center;
          // align-items: center;
          .el-tabs__item {
            padding: 0;
            margin-right: 91px;
          }

          .el-tabs__item:last-child {
            margin: 0;
          }

          .el-tabs__active-bar {
            display: none;
          }

          .el-tabs__item:hover,
          .is-active {
            border-bottom: 3px solid #357bff;
            color: #357bff;
          }
        }

        .tabsT {
          .newcontainer {
            display: flex;
            height: 410px;
            justify-content: space-between;

            .left {
              width: 625px;
              background: #f7f8fa;
              margin-top: 10px;

              .topT {
                margin-top: 19px;
                margin-left: 19px;
                width: 587px;
                height: 291px;
              }

              .BotB {
                margin-top: 25px;
                margin-left: 31px;

                .BotLeft {
                  font-size: 21px;
                  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                  font-weight: bold;
                  color: #333333;
                  line-height: 27px;
                  position: relative;

                  .leftCont {
                    font-size: 12px;
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 16px;
                    margin-left: 26px;
                    margin-top: 15px;
                    position: absolute;
                    top: -10px;
                    // right: 1px;
                  }
                }

                .BotRight {
                  margin-left: 13px;
                  font-size: 14px;
                  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                  font-weight: bold;
                  color: #c1c1c1;
                  line-height: 19px;

                  .botCont {
                    font-size: 12px;
                    font-family: MicrosoftYaHei;
                    color: #333333;
                    line-height: 16px;
                    margin-left: 35px;
                    margin-top: 9px;
                  }
                }
              }
            }

            .right {
              margin-top: 10px;
              width: 552px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .rightCont {
                width: 552px;
                height: 90px;
                background: #f7f8fa;
                display: flex;

                .contL {
                  .dateT {
                    margin-top: 20px;
                    margin-left: 22px;
                    font-size: 21px;
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 27px;
                  }

                  .dateB {
                    margin-top: 1px;
                    margin-left: 35px;
                    font-size: 14px;
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                    font-weight: bold;
                    color: #c1c1c1;
                    line-height: 19px;
                  }
                }

                .line {
                  width: 1px;
                  height: 38px;
                  margin-left: 9px;
                  margin-top: 26px;
                  border: 1px solid #d3d3d3;
                  transform: scale(0.5, 1);
                }

                .contR {
                  margin-left: 9px;
                  margin-top: 23px;

                  .newT {
                    font-size: 12px;
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 16px;
                  }

                  .newB {
                    margin-top: 9px;
                    font-size: 12px;
                    font-family: MicrosoftYaHei;
                    color: #333333;
                    line-height: 16px;
                  }
                }
              }

              .rightCont:hover {
                background: #357bff;

                .contL {
                  .dateT {
                    color: #ffffff;
                  }

                  .dateB {
                    color: #ffffff;
                  }
                }

                // .line{
                //   border: 1px solid #ffffff;
                // }
                .contR {
                  .newT {
                    color: #ffffff;
                  }

                  .newB {
                    color: #ffffff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.moreButton {
  margin-top: 30px;
  width: 128px;
  line-height: 1px;
  height: 35px;
  text-align: center;
  color: #357bff;
  border-color: #357bff;
}

.honors {
  margin: 0 auto;
  width: 100%;
  // background-image: url("~@/assets/product/Bg.png");
  height: 713px;

  .content {
    width: 100%;
    margin-bottom: 85px;
    padding-top: 65px;

    .title {
      text-align: center;
      font-size: 21px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 27px;
    }

    .title1 {
      margin-top: 7px;
      text-align: center;
      font-size: 21px;
      font-family: MicrosoftYaHei;
      color: #333333;
      line-height: 27px;
    }
  }

  .swiperBg {
    width: 100%;
    margin: 0 auto;

    .swiper-container {
      width: 1551px;
      height: 416px;

      .swiper-slide {
        height: 398px;
        // background: #FFFFFF;
        box-shadow: 0px 2px 12px 0px rgba(207, 207, 207, 0.5);
        border-radius: 2px;
        text-align: center;
        font-size: 18px;
        background: #fff;

        .img {
          height: 319px;
          width: 100%;
          padding: 20px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1px dashed #b1b1b1;

          div {
            .imgSmall {
              display: block;
            }

            .imgBig {
              display: none;
            }
          }

          div:hover {
            .imgSmall {
              display: none;
            }

            .imgBig {
              display: block;
            }
          }
        }

        .word {
          height: 78px;
          width: 100%;
          font-size: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.carousel_banner {
  width: 100%;
  position: absolute;
  top: 1px;
  left: 1px;
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.el-tabs__nav-wrap::after {
  display: none;
}

/deep/ .el-button:hover {
  color: #fff;
  border-color: #357bff;
  background-color: #357bff;
}

.swiper-button-prev,
.swiper-button-next {
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.36);
  background-image: "";
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  bottom: 111px;
}

.swiper-button-next {
  right: 31px;
}

.swiper-button-prev {
  left: 31px;
}

.button-jiangtou {
  width: 13px;
  height: 20px;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  display: none;
}

// .set-other-btn{
//   color: #fff;
//   background-color: #FA4EAB;
//   border-color: #FA4EAB;
// }
// .set-other-btn:hover {
// background-color: #357bff; border-color: #c6e2ff; }
</style>
