<template>
  <div class="storage" :style="'background-image:url('+requireImg(data.backgroundurl)+')'">
    <div class="content" id="storages">
      <div class="top">
        <div class="title">{{data.title}}</div>
        <!-- <div class="titleE">{{data.titleE}}</div> -->
      </div>
      <div class="buttom">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="data.platfor" name="charter">
            <div class="tabPaneContent">
              <div class="card" v-for="(item,index) in data.platformData" :key="'tabPane'+index">
                <span class="num">{{item.num}}<span style="font-size:23px;margin-left:5px">{{item.danwei}}</span></span>
                <span class="text">{{item.text}}</span>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="data.carry" name="carry">
            <div class="tabPaneContent2">
              <div class="card" v-for="(item,index) in data.carryData" :key="'tabPane'+index">
                <img :src="requireImg(item.url)" alt />
                <div class="text"></div>
                <p class="text">{{item.text}}</p>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="data.carry2" name="carry2">
            <div class="tabPaneContent3">
              <img :src="requireImg(data.carry2Data.url)" alt />
              <p class="text">{{data.carry2Data.text}}</p>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { storage } from "../data";
export default {
  data() {
    return {
      data: {},
      activeName: "charter"
    };
  },
  components: {},
  created() {
    this.data = storage;
    this.$nextTick(() => this.getDeclareAtCustoms())
  },
  methods: {
    requireImg(url) {
      return require("@/assets/img/service/storage/" + url + ".png");
    },
    handleClick(tab) {
      console.log(tab.index);
      if (!tab.index == 0) {
        document.querySelector(".el-tabs__active-bar").style.transform =
          "translateX(424px)";
      }
    },
    getDeclareAtCustoms() {
      let select = localStorage.getItem("declareAtCustoms");
      let elm = document.getElementById(select);
      if (select) {
        elm.scrollIntoView(true);
      }
    }
  },
  destroyed() {
    localStorage.setItem("declareAtCustoms", "");
  },
};
</script>

<style lang="less" scoped>
.storage {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  .content {
    width: 1200px;
    height: 784px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .top {
      width: 1200px;
      margin-top: 47px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .title {
        font-size: 24px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #fff;
        line-height: 31px;
      }
      .titleE {
        margin-top: 7px;
        font-size: 21px;
        font-family: MicrosoftYaHei;
        color: #fff;
        line-height: 27px;
      }
    }
    .buttom {
      width: 100%;
      display: flex;
      margin-top: 40px;
      justify-content: center;
      /deep/ .el-tabs {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .el-tabs__content {
          width: 100%;
        }
        .el-tabs__header {
          width: 1200px;
          border-bottom: 1px solid #cccccc;
          position: relative;
          display: flex;
          justify-content: space-around;
          .el-tabs__item {
            margin-right: 275px;
          }
          .el-tabs__item:last-child {
            margin: 0;
          }

          .el-tabs__item,
          .el-tabs__item:hover {
            color: #ffffff;
            padding: 0;
          }
          .el-tabs__active-bar {
            display: none;
          }
          .is-active {
            color: #ffffff;
            border-bottom: 3px solid #ffffff;
          }
          // .el-tabs__nav {
          //   display: flex;
          //   justify-content: space-around;
          //   // position: absolute;
          //   width: 100%;
          // }
        }
      }
      .tabPaneContent {
        width: 1200px;
        height: 480px;
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        padding-top: 65px;
        .card {
          width: 300px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .num {
            font-size: 54px;
            font-family: Helvetica;
            color: #ffffff;
            line-height: 65px;
          }
          .text {
            font-size: 18px;
            font-family: MicrosoftYaHei;
            color: #ffffff;
            line-height: 24px;
          }
        }
      }
      .tabPaneContent2 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .card {
          width: 276px;
          height: 192px;
          margin-top: 39px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
          }
          .text {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 56px;
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: #ffffff;
            line-height: 56px;
            text-align: center;
            cursor: pointer;
          }
          div {
            opacity: 0.29;
            background: #000000;
          }
        }
      }
      .tabPaneContent3 {
        width: 1200px;
        padding-top: 35px;
        img {
          height: 397px;
          width: 100%;
        }
        .text {
          width: 100%;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 23px;
          margin-top: 29px;
          height: 69px;
        }
      }
    }
  }
}
</style>