<template>
  <div class="productHomeService">
    <div class="swiperProduct">
      <div class="swiper-container productHomeSwiper">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in data"
            :key="' productHome' + index"
          >
            <!-- <img :src="require('@/assets/jyd_home/header/header' + item + '.png')" alt=""> -->
            <div class="title">{{ item.title }}</div>
            <div class="text">
              <div v-if="typeof item.text == 'object'">
                <div
                  v-for="(it, id) in item.text"
                  :key="'text' + id"
                  class="text"
                >
                  {{ it }}
                </div>
              </div>
              <div class="text" v-else>
                {{ item.text }}
                <div v-if="item.titlebold">
                  <span class="titlebold">{{ item.titlebold }}</span
                  >{{ item.textbold }}
                </div>
              </div>
            </div>
            <div class="title" v-if="item.title2">{{ item.title2 }}</div>
            <div class="text" v-if="item.title2">
              <div v-if="typeof item.text2 == 'object'">
                <div
                  v-for="(it, id) in item.text2"
                  :key="'text' + id"
                  class="text2"
                >
                  {{ it }}
                </div>
              </div>
              <div class="text" v-else>{{ item.text2 }}</div>
            </div>
            <div class="tabPaneContent" v-if="item.platformData">
              <div
                class="card"
                v-for="(it, id) in item.platformData"
                :key="'tabPane' + id"
                :style="
                  'margin-right: ' +
                  it.right / 10 +
                  'rem;margin-left: ' +
                  it.left / 10 +
                  'rem'
                "
              >
                <span class="text">{{ it.text }}</span>
                <span class="num"
                  >{{ it.num }}<span class="symbol">{{ it.danwei }}</span></span
                >
              </div>
            </div>
            <div
              class="image"
              :style="
                'margin: -' +
                item.top / 10 +
                'rem 0 ' +
                item.imgbot / 10 +
                'rem 0'
              "
            >
              <img
                :src="require('@/assets/jyd_home/home/' + item.image)"
                alt=""
                :style="'height:calc((' + item.height + '/10)*1rem);'"
              />
            </div>
            <div class="PButtons" v-if="item.button">
              <div
                v-for="(it, id) in item.button"
                :key="'text' + id"
                class="PButton"
              >
                {{ it }}
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
import { productData } from "./data";
export default {
  data() {
    return {
      data: [],
    };
  },
  mounted() {
    this.data = productData;
    new Swiper(".productHomeSwiper", {
      // 如果需要前进后退按钮
      observer: true, // 修改swiper自己或子元素时，自动初始化swiper
      observeParents: true,
      rewind: true,
      centeredSlides: true,
      // slidesPerView: 3,
      // rewind: true,
      autoplay: {
        // delay: 2000,//自动切换时间；
        //与loop有关，设置为true，loop失效
        stopOnLastSlide: false,
        //用户操作之后，是否禁止自动切换（默认true禁止/设置false允许）
        disableOnInteraction: true,
      },
      spaceBetween: 10,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        bulletClass: "my-pagination",
        bulletActiveClass: "my-pagination-active",
      },
      effect: "coverflow",

      coverflowEffect: {
        rotate: 0, //slide做3d旋转时Y轴的旋转角度
        stretch: 10, //每个slide之间的拉伸值，越大slide靠得越紧。5.3.6 后可使用%百分比
        depth: 10, //slide的位置深度。值越大z轴距离越远，看起来越小。
        modifier: 0, //depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。
        slideShadows: false, //是否开启slide阴影
      },
    });
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.productHomeService {
  width: 100%;
  height: calc((482 / 10) * 1rem);
}

.swiperProduct {
  width: 100%;
  height: calc((482 / 10) * 1rem);

  .swiper-container {
    width: 100%;
    height: calc((482 / 10) * 1rem);

    .swiper-slide {
      height: calc((442 / 10) * 1rem);
      width: 100%;
      background-image: url("../../../assets/jyd_home/home/bg.png");
      background-position: 50% 50%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: calc((16 / 10) * 1rem) calc((18 / 10) * 1rem)
        calc((18 / 10) * 1rem) calc((18 / 10) * 1rem);

      .title {
        padding: 0 0 calc((16 / 10) * 1rem) 0;
        width: 100%;
        text-align: center;
        font-size: calc((14 / 10) * 1rem);
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #333333;
        line-height: calc((19 / 10) * 1rem);
      }

      .text {
        width: 100%;
        text-align: center;
        font-size: calc((12 / 10) * 1rem);
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: calc((18 / 10) * 1rem);
        text-align: left;
        word-break: break-all;
        white-space: 0;
        margin-bottom: calc((13 / 10) * 1rem);
        z-index: 2;
        position: relative;

        .titlebold {
          font-weight: bold;
        }

        .text2 {
          line-height: calc((18 / 10) * 1rem);
        }
      }

      .tabPaneContent {
        width: 100%;
        height: calc((105 / 10) * 1rem);
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        // padding-top: 65px;
        .card {
          // width: 300px;
          // width: calc((100/3) * 1%);
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
          flex-direction: column;
          z-index: 5;

          .num { 
            font-size: calc((11 / 10) * 1rem);
            font-family: MicrosoftYaHei;
            // color: #333333;
            color: #357bff;
            line-height: calc((14 / 10) * 1rem);
            margin-top: calc((3 / 10) * 1rem);
            margin-bottom: calc((3 / 10) * 1rem);
            text-align: center;
            // line-height: 65px;
            .symbol { 
              font-size: calc((10 / 10) * 1rem);
              font-family: PingFang-SC-Bold, PingFang-SC;
              // font-weight: bold;
              color: #357bff;
              line-height: calc((13 / 10) * 1rem);
            }
          }

          .text {
            font-family: Helvetica;
            color: #357bff;
            font-size: calc((13 / 10) * 1rem);
            // font-family: Oswald-Medium, Oswald;
            text-align: center;
            font-weight: bold;
            color: #357bff;
            line-height: calc((17 / 10) * 1rem);
            margin-bottom: calc((0 / 10) * 1rem);
          }
        }
      }

      .image {
        width: 100%;
        // height: calc((184/10)*1rem);
        z-index: 1;
        position: relative;

        img {
          width: 100%;
          // height: 100%;
        }
      }

      .PButtons {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .PButton {
          width: calc((96 / 10) * 1rem);
          height: calc((26 / 10) * 1rem);
          border-radius: calc((16 / 10) * 1rem);
          border: calc((1 / 10) * 1rem) solid #357bff;
          // padding: 0 calc((23/10)*1rem);
          margin: calc((12 / 10) * 1rem) 0 0 0;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: calc((12 / 10) * 1rem);
          font-family: MicrosoftYaHei;
          color: #357bff;
          line-height: calc((16 / 10) * 1rem);
        }
      }
    }
  }
}
</style>