<template>
  <div class="container">
    <div class="main" v-if="!types">
      <div class="logo">
        <div class="logTop">
          <div class="logoIcon">
            <img src="@/assets/img/JYDlogo.png" style="width: 100%" alt />
          </div>
          <div class="logoEWM">
            <div class="word">微信公众号</div>
            <img src="@/assets/img/gongzhonghao.png" alt />
          </div>
        </div>
        <div class="address">
          <div class="address1">全国统一服务热线：</div>
          <div class="address2">0755-2536 9609</div>
          <div class="address3">© 2023 佳裕达集团 版权所有 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff">粤ICP备17024003号-1</a></div>
        </div>
      </div>
      <div class="prduct">
        <div class="tit" @click="toHomePage()">首页</div>
        <div style="margin-top: 13px;" @click="toProductServiceSon('productService')" class="tits"><a
            href="#productService">产品服务</a></div>
        <div @click="toHonorsAndQualifications('honorsAndQualifications')" class="tits"><a
            href="#honorsAndQualifications">荣誉资质</a></div>
        <div @click="toCooperativePartner('cooperativePartner')" class="tits"><a href="#cooperativePartner">合作伙伴</a></div>
        <div @click="toCooperativePartner('cooperativePartner')" class="tits"><a href="#cooperativePartner">合作客户</a></div>
      </div>
      <div class="prduct">
        <div class="tit" @click="toGroupIntroduction()">集团介绍</div>
        <div style="margin-top: 13px;" @click="toGroupProfile('groupProfile')" class="tits"><a
            href="#groupProfile">集团简介</a>
        </div>
        <div @click="toOrganizationalStructure('organizationalStructure')" class="tits"><a
            href="#organizationalStructure">组织架构</a></div>
        <div @click="toDevelopmentHistory('developmentHistory')" class="tits"><a href="#developmentHistory">发展历程</a></div>
        <div @click="toGroupStrategy('groupStrategy')" class="tits"><a href="#groupStrategy">集团战略</a></div>
        <div @click="toGroupCulture('groupCulture')" class="tits"><a href="#groupCulture">集团文化</a></div>
        <!-- <div class="tits">荣誉资质</div> -->
      </div>
      <div class="prduct" style="text-align:left">
        <div class="tit" @click="toProductService()">产品服务</div>
        <!-- <div style="margin-top: 8px" class="tits"><a href="#tradeSupplyChain">商贸供应链</a></div> -->
        <div style="margin-top: 13px;" @click="toCustomsDeclarationBusiness('declareAtCustoms')" class="tits"><a
            href="#declareAtCustoms">报关</a></div>
        <div @click="toAirAnchor('airAnchor')" class="tits"><a href="#airAnchor">空运</a></div>
        <div @click="toSeaAnchor('seaAnchor')" class="tits"><a href="#seaAnchor">海运</a></div>
        <div @click="toEcommerceLogistics('E-commerceLogistics')" class="tits"><a href="#E-commerceLogistics">电商物流</a>
        </div>
        <!-- <div class="tits"><a href="#declareAtCustoms">报关</a></div> -->
        <div @click="toStorages('storages')" class="tits"><a href="#storages">仓储</a></div>
        <div @click="toDeclareAtCustoms('declareAtCustoms')" class="tits"><a href="#declareAtCustoms">国内及陆路跨境运输</a></div>
        <div @click="toTechnologicalDevelopment('technologicalDevelopment')" class="tits"><a
            href="#technologicalDevelopment">技术开发</a></div>
      </div>
      <div class="prduct" style="text-align:left">
        <div class="tit" @click="toSmartLink()">智能科技</div>
        <div style="margin-top: 13px;" @click="toDigitalTwins('digitalTwins')" class="tits"><a
            href="#digitalTwins">数字孪生</a>
        </div>
        <div @click="toIntelligentPlatform('intelligentPlatform')" class="tits"><a
            href="#intelligentPlatform">佳裕达数智化平台系统</a></div>
      </div>
      <!-- <div class="prduct">
        <div class="tit">新闻资讯</div>
        <div class="tits" style="margin-top: 8px">集团新闻</div>
        <div class="tits">行业资讯</div>
        <div class="tits">政策法规</div>
      </div> -->
      <!-- <div class="prduct">
        <div class="tit">人才招聘</div>
        <div class="tits" style="margin-top: 8px">集团新闻</div>
        <div class="tits">社会招聘</div>
        <div class="tits">校园招聘</div>
      </div> -->
    </div>
    <Websites v-if="types"></Websites>
  </div>
</template>
<script>
import Websites from "./FooterWebsites";
export default {
  name: "Footer",
  data() {
    return {};
  },
  components: {
    Websites
  },
  computed: {
    types() {
      return this.$route.fullPath == "/theListedWebsites";
    }
  },
  watch: {
    types: {
      handler: () => {
        console.log("types改变了");
      },
      deep: true
    }
  },
  methods: {
    toHomePage() {
      this.$router.push({ path: "/" })
    },
    toGroupIntroduction() {
      this.$router.push({ path: "software" })
    },
    toProductService() {
      this.$router.push({ path: "service" })
    },
    toSmartLink() {
      this.$router.push({ path: "smartTechnology" })
    },
    toProductServiceSon(val) {
      localStorage.setItem("productService", val)
      this.$router.push({
        name: "Home",//跳转页面的name,如果目标位置内容是组件则name是父组件的name
      });
    },
    toHonorsAndQualifications(val) {
      localStorage.setItem("honorsAndQualifications", val)
      this.$router.push({
        name: "Home",//跳转页面的name,如果目标位置内容是组件则name是父组件的name
      });
    },
    toCooperativePartner(val) {
      localStorage.setItem("cooperativePartner", val)
      this.$router.push({
        name: "Home",//跳转页面的name,如果目标位置内容是组件则name是父组件的name
      });
    },
    toGroupProfile(val) {
      localStorage.setItem("groupProfile", val)
      this.$router.push({
        name: "software",//跳转页面的name,如果目标位置内容是组件则name是父组件的name
      });
    },
    toOrganizationalStructure(val) {
      localStorage.setItem("organizationalStructure", val)
      this.$router.push({
        name: "software",//跳转页面的name,如果目标位置内容是组件则name是父组件的name
      });
    },
    toDevelopmentHistory(val) {
      localStorage.setItem("developmentHistory", val)
      this.$router.push({
        name: "software",//跳转页面的name,如果目标位置内容是组件则name是父组件的name
      });
    },
    toGroupStrategy(val) {
      localStorage.setItem("groupStrategy", val)
      this.$router.push({
        name: "software",//跳转页面的name,如果目标位置内容是组件则name是父组件的name
      });
    },
    toGroupCulture(val) {
      localStorage.setItem("groupCulture", val)
      this.$router.push({
        name: "software",//跳转页面的name,如果目标位置内容是组件则name是父组件的name
      });
    },
    toCustomsDeclarationBusiness(val) {
      localStorage.setItem("declareAtCustoms", val)
      this.$router.push({
        name: "service",//跳转页面的name,如果目标位置内容是组件则name是父组件的name
      });
    },
    toAirAnchor(val) {
      localStorage.setItem("groupCulture", val)
      this.$router.push({
        name: "service",//跳转页面的name,如果目标位置内容是组件则name是父组件的name
      });
    },
    toSeaAnchor(val) {
      localStorage.setItem("seaAnchor", val)
      this.$router.push({
        name: "service",//跳转页面的name,如果目标位置内容是组件则name是父组件的name
      });
    },
    toEcommerceLogistics(val) {
      localStorage.setItem("E-commerceLogistics", val)
      this.$router.push({
        name: "service",//跳转页面的name,如果目标位置内容是组件则name是父组件的name
      });
    },
    toStorages(val) {
      localStorage.setItem("storages", val)
      this.$router.push({
        name: "service",//跳转页面的name,如果目标位置内容是组件则name是父组件的name
      });
    },
    toDeclareAtCustoms(val) {
      localStorage.setItem("declareAtCustoms", val)
      this.$router.push({
        name: "service",//跳转页面的name,如果目标位置内容是组件则name是父组件的name
      });
    },
    toTechnologicalDevelopment(val) {
      localStorage.setItem("technologicalDevelopment", val)
      this.$router.push({
        name: "service",//跳转页面的name,如果目标位置内容是组件则name是父组件的name
      });
    },
    toDigitalTwins(val) {
      localStorage.setItem("digitalTwins", val)
      this.$router.push({
        name: "smartTechnology",//跳转页面的name,如果目标位置内容是组件则name是父组件的name
      });
    },
    toIntelligentPlatform(val) {
      localStorage.setItem("intelligentPlatform", val)
      this.$router.push({
        name: "smartTechnology",//跳转页面的name,如果目标位置内容是组件则name是父组件的name
      });
    },
  }
};
</script>
<style scoped lang="less">
.container {
  width: 100%;
  background: #252838;

  .main {
    background: #252838;
    box-sizing: border-box;
    width: 1200px;
    height: 332px;
    padding: 41px 41px 0px;
    margin: 0 auto;
    color: #fff;

    // overflow: hidden;
    // text-align: center;
    display: flex;
    justify-content: space-between;

    .logo {
      width: 300px;
      height: 210px;

      // margin-left: 407px;
      // margin-top: 41px;
      // flex-direction: column;
      // display: flex;
      .logTop {
        display: flex;
        width: 188px;

        .logoIcon {
          width: 91px;
          height: 55px;
        }

        .logoEWM {
          margin-left: 47px;
          height: 110px;
          width: 86px;

          img {
            width: 100%;
          }

          .word {
            font-size: 14px;
            white-space: nowrap;
          }
        }
      }

      .address {
        font-size: 12px;

        .address1 {
          white-space: nowrap;
          // margin-top: 16px;
          // margin-right: 130px;
          // font-size: 12px;
          margin-left: 0;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 16px;
        }

        .address2 {
          // margin-right: 50px;
          font-size: 26px;
          font-family: Silom;
          color: #ffffff;
          line-height: 40px;
          // margin-top: 3px;
          font-family: MicrosoftYaHei;
        }

        .address3 {
          margin-top: 9px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 16px;
        }
      }
    }

    .prduct {

      // margin-left: 117px;
      // margin-top: 41px;
      .tit {
        // margin-top: 13px;
        font-size: 14px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 19px;
      }

      .tit:hover {
        cursor: pointer;
      }

      .tits {
        margin-top: 11px;

        a {
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 16px;
        }
      }

      .tits:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
