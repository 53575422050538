<template>
    <div class="personnelManager">
        <div class="tit">
            <div class="tit_heart">
                <span class="tit_Text">人才招聘</span><i class="el-icon-arrow-right"></i>
                <span class="tit_Icon" @click="back()">社会招聘</span><i class="el-icon-arrow-right"></i><span class="tit_Icon">职位详情</span>
            </div>
        </div>
        <div class="con">
            <div class="applyForPosition">
                <p class="position_left">职位名称：电话销售</p>
                <el-button type="primary" disabled>申请职位</el-button>
            </div>
        </div>
        <div class="introduce">
            <div class="introduce_top">
                <p class="sty" style="margin-right:377px">招聘人数：30人</p>
                <p class="sty" style="margin-right:335px">招聘有效期：2月～5月</p>
                <p class="sty">薪酬范围：5000-20000元/月</p>
            </div>
            <div class="introduce_bottom" style="margin-top:16px">
                <p class="sty" style="margin-right:396px">学历要求：大专</p>
                <p class="sty">经验要求：经验不限</p>
            </div>
        </div>
        <div class="operatingDuty">
            <div class="operatingDuty_text">工作职责</div>
            <ul class="operatingDuty_item">
                <li>1、通过电话或者网络，负责终端客户的开发，产品的销售，后期的客户维护(前期公司有完善的拓客体系，只负责开发客户邀约)；</li>
                <li>2、了解跨境物流的基础知识 (公司安排基础培训，协助销售解决客户后顾之忧)；</li>
                <li>3、通过电话预约客户时间，通知业务经理上门拜坊；</li>
                <li>4、完成公司每月的邀约目标，及时向上级汇报工作，递交相关报告，如客户跟踪情况等(辅助你快速成交)。</li>
            </ul>
        </div>
        <div class="operatingDuty" style="padding:43px 0 54px;">
            <div class="operatingDuty_text">岗位要求</div>
            <ul class="operatingDuty_item">
                <li>1、对电话销售有浓厚兴趣；</li>
                <li>2、性格开朗、做事严谨、善于沟通。</li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default{
        data(){
            return{
                bacKShow:null
            }
        },
        methods:{
            back(){
                this.bacKShow = 1
                this.$emit('bacKShow',this.bacKShow)
            }
        }
    }
</script>

<style lang="less" scoped>
.personnelManager{
    .tit{   
        background: #F4F3F3;
        height: 60px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 60px;
        .tit_heart{
            margin: 0 auto;
            width: 1200px;
            .tit_Text{
                margin-right: 11px;
            }
            .tit_Icon{
                margin: 11px;
            }
        }
    }
    .con{
        margin: 0 auto;
        width: 1200px;
        padding-top: 38px;
        .applyForPosition{
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .position_left{
                font-size: 18px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #333333;
                line-height: 24px;
            }
            /deep/.el-button--primary.is-disabled{
                background: #357BFF;
                border: #357BFF;
                border-radius: 2px;
            }
        }
    }
    .introduce{
        padding: 39px 0 37px;
        width: 1200px;
        margin: 0 auto;
        border-bottom: 1px solid #CCCCCC;
        .introduce_top,.introduce_bottom{
            display: flex;
            .sty{
                font-size: 16px;
                font-family: MicrosoftYaHei;
                color: #333333;
                line-height: 21px;
            }
        }
    }
    .operatingDuty{
        width: 1200px;
        margin: 0 auto;
        padding-top: 36px;
        .operatingDuty_text{
            font-size: 18px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #333333;
            line-height: 24px;
        }
        .operatingDuty_item{
            padding: 29px 0 0 72px;
            li{
                font-size: 16px;
                font-family: MicrosoftYaHei;
                color: #333333;
                line-height: 21px;
                margin-bottom: 12px;
            }
        }
    }
}
</style>