<template>
  <div class="container">
    <div class="news">
      <el-tabs v-model="activeName">
        <el-tab-pane label="集团新闻" name="first">
          <div
            class="newBox"
            v-for="(item, index) in newsList"
            :key="item.id + index"
          >
            <div class="newBoxL">
              <img :src="item.img" alt style="100%;" />
            </div>
            <div class="newBoxR">
              <div class="newT">{{ item.newT }}</div>
              <div class="newCont">
                <p class="newP">{{ item.p1 }}</p>
                <p class="newP">{{ item.p2 }}</p>
              </div>
              <div class="newBot">
                <div class="newDate">2015-05-06</div>
                <div class="newButt">
                  <span class="detaill">
                    <el-button type="text" @click="checkDetail(item.id)">
                      查看详情
                      <i class="el-icon-arrow-right"></i>
                    </el-button>
                  </span>
                  <!-- <span>
                  <img src="@/assets/news/lujing.png" alt="" />
                  </span>-->
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="行业资讯" name="second">行业资讯</el-tab-pane>
        <el-tab-pane label="政策法规" name="third">政策法规</el-tab-pane>
      </el-tabs>
      <el-pagination
        class="pagination"
        background
        layout="prev, pager, next"
        :total="50"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newsList: [
        {
          id: 1,
          img: require("@/assets/news/sea.png"),
          newT: " 进口汽车费用削减20%-深圳海关助力自贸区建设支持平行汽车进口政策落地",
          p1: "中国（广东）自由贸易试验区前海蛇口片区日前挂牌。深圳海关将从突出深港合作、依托创新驱动、面向“一带一路”、推动互联互通、促进贸易便利等5个方面，全力支持自贸片区建设发展。",
          p2: "深圳海关将打造前海蛇口片区内海关特殊监管区域货物经陆路口岸进出香港的物流直通通道，减少了转关申报、到位核销等多个环节，通关时间可缩短一半。据深圳海关…",
        },
        {
          id: 2,
          img: require("@/assets/news/sea.png"),
          newT: " 进口汽车费用削减20%-深圳海关助力自贸区建设支持平行汽车进口政策落地",
          p1: "中国（广东）自由贸易试验区前海蛇口片区日前挂牌。深圳海关将从突出深港合作、依托创新驱动、面向“一带一路”、推动互联互通、促进贸易便利等5个方面，全力支持自贸片区建设发展。",
          p2: "深圳海关将打造前海蛇口片区内海关特殊监管区域货物经陆路口岸进出香港的物流直通通道，减少了转关申报、到位核销等多个环节，通关时间可缩短一半。据深圳海关…",
        },
        {
          id: 3,
          img: require("@/assets/news/sea.png"),
          newT: " 进口汽车费用削减20%-深圳海关助力自贸区建设支持平行汽车进口政策落地",
          p1: "中国（广东）自由贸易试验区前海蛇口片区日前挂牌。深圳海关将从突出深港合作、依托创新驱动、面向“一带一路”、推动互联互通、促进贸易便利等5个方面，全力支持自贸片区建设发展。",
          p2: "深圳海关将打造前海蛇口片区内海关特殊监管区域货物经陆路口岸进出香港的物流直通通道，减少了转关申报、到位核销等多个环节，通关时间可缩短一半。据深圳海关…",
        },
        {
          id: 4,
          img: require("@/assets/news/sea.png"),
          newT: " 进口汽车费用削减20%-深圳海关助力自贸区建设支持平行汽车进口政策落地",
          p1: "中国（广东）自由贸易试验区前海蛇口片区日前挂牌。深圳海关将从突出深港合作、依托创新驱动、面向“一带一路”、推动互联互通、促进贸易便利等5个方面，全力支持自贸片区建设发展。",
          p2: "深圳海关将打造前海蛇口片区内海关特殊监管区域货物经陆路口岸进出香港的物流直通通道，减少了转关申报、到位核销等多个环节，通关时间可缩短一半。据深圳海关…",
        },
        {
          id: 5,
          img: require("@/assets/news/sea.png"),
          newT: " 进口汽车费用削减20%-深圳海关助力自贸区建设支持平行汽车进口政策落地",
          p1: "中国（广东）自由贸易试验区前海蛇口片区日前挂牌。深圳海关将从突出深港合作、依托创新驱动、面向“一带一路”、推动互联互通、促进贸易便利等5个方面，全力支持自贸片区建设发展。",
          p2: "深圳海关将打造前海蛇口片区内海关特殊监管区域货物经陆路口岸进出香港的物流直通通道，减少了转关申报、到位核销等多个环节，通关时间可缩短一半。据深圳海关…",
        },
      ],
      activeName: "first",
    };
  },
  methods: {
    checkDetail(id) {
      this.$router.push({
        name: "newDetail",
        params: {
          id: id,
        },
      });
      // console.log(id);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  background-color: #fff;
  .news {
    width: 1200px;
    margin: 0 auto;
    // background-color: red;
    //   height: 1000px;
    /deep/ .el-tabs {
      width: 100%;
      .el-tabs__header {
        border-bottom: 1px solid #cccccc;
        position: relative;
        display: flex;
        justify-content: space-around;
      }
    }
    /deep/ .el-tabs__nav-scroll {
      display: flex;
      justify-content: center;
      align-items: center;
      .el-tabs__item {
        padding: 0;
        margin-right: 273px;
      }
      .el-tabs__item:last-child {
        margin: 0;
      }
      .el-tabs__active-bar {
        display: none;
      }
      .el-tabs__item:hover,
      .is-active {
        border-bottom: 3px solid #357bff;
        color: #357bff;
      }
    }
    .newBox {
      display: flex;
      width: 100%;
      height: 250px;
      margin-top: 22px;
      .newBoxL {
        width: 453px;
        height: 250px;
      }
      .newBoxR {
        width: 747px;
        height: 250px;
        background-color: #f4f3f3;
        margin-left: 1px;
        margin-top: 1px;
        .newT {
          margin: 0 auto;
          width: 657px;
          margin-top: 49px;
          text-align: center;
          font-size: 18px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #333333;
          line-height: 24px;
        }
        .newCont {
          margin: 0 auto;
          width: 657px;
          margin-top: 23px;
          height: 76px;
          // text-align: center;
          .newP {
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #333333;
            line-height: 19px;
            text-indent: 24px;
            white-space: initial;
          }
        }
        .newBot {
          margin: 0 auto;
          width: 657px;
          height: 193px;
          display: flex;
          justify-content: space-between;
          .newDate {
            margin-top: 36px;
            font-size: 14px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #333333;
            line-height: 19px;
          }
          .newButt {
            display: flex;
            height: 19px;
            width: 80px;
            align-items: center;
            justify-content: space-between;
            margin-top: 36px;
            .detaill {
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #333333;
              line-height: 19px;
            }
          }
        }
      }
    }
  }
  ::v-deep.el-tabs__active-bar.is-top {
    transform: translateX(409.823px);
  }
  .pagination {
    margin-top: 76px;
    margin-left: -10px;
    margin-bottom: 56px;
  }
}
/deep/.el-pagination.is-background .el-pager li {
  margin: 0 5px;
  background-color: white;
  color: #606266;
  border: 1px solid #ccc;
  min-width: 30px;
  border-radius: 2px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #357bff;
  color: #fff;
  border-color: #357bff;
}
/deep/.el-pagination.is-background .btn-prev {
  background-color: white;
  border: 1px solid #ccc;
}
/deep/.el-pagination.is-background .btn-next {
  background-color: white;
  border: 1px solid #ccc;
}
</style>