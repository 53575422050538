<template>
  <div class="service">
    <div class="left">
      <img :src="requireImg('b3')" alt />
    </div>
    <div class="right">
      <span class="title">{{ datas.text }}</span>
      <div class="cards">
        <div class="card" v-for="(item, index) in datas.data" :key="'cards' + index">{{ item.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    datas: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      // key: value
    };
  },
  methods: {
    requireImg(url) {
      return require("@/assets/img/service/roadTransport/" + url + ".png");
    }
  }
};
</script>

<style lang="less" scoped>
.service {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;

  .left {
    width: 680px;
    height: 395px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .right {
    width: 518px;
    height: 395px;
    display: flex;
    background-color: rgba(53, 123, 255, 0.07);
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 21px;
      margin: 0 0 56px 0;
      text-align: center;
    }

    .cards {
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      width: 398px;
      height: 165px;

      .card {
        width: 185px;
        height: 54px;
        background: #ffffff;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: auto;
        font-size: 16px;
      }
    }
  }
}</style> 