<template>
  <div class="development">
    <div class="content" id="technologicalDevelopment">
      <div class="top">
        <div class="title">{{data.title}}</div>
        <!-- <div class="titleE">{{data.titleE}}</div> -->
      </div>
      <div class="buttoms">
        <img class="cards" src="../../../../assets/img/smart/line@2x.png" alt/>
        <div class="card" v-for="(item,index) in data.data" :key="'cards'+index" @click="clickfn">
          <img class="img1" :src="requireImg(item.url)" alt :width="item.width" :height="item.height"/>
          <img class="img2" :src="requireImg(item.url2)" alt :width="item.width" :height="item.height"/>
          <div class="text">{{item.text}}</div>
        </div>
      </div>
      <!-- <div class="more">查看更多 ></div> -->
    </div>
  </div>
</template>

<script>
import { development } from "../data";
export default {
  data() {
    return {
      data: {}
    };
  },
  created() {
    this.data = development;
    this.$nextTick(() => this.getTechnologicalDevelopment())
  },
  methods: {
    requireImg(url) {
      return require("@/assets/img/smart/icon/" + url + ".png");
    },
    clickfn() {
      this.$router.push({ path: "/smartTechnology" });
    },
    getTechnologicalDevelopment() {
      let select = localStorage.getItem("technologicalDevelopment");
      let elm = document.getElementById(select);
      if (select) {
        elm.scrollIntoView(true);
      }
    }
  },
  destroyed() {
    localStorage.setItem("technologicalDevelopment", "");
  },
};
</script>

<style lang="less" scoped>
.development {
  width: 100%;
  height: 747px;
  position: relative;
  background: #FFFFFF;
  // background-image: url("../../../../assets/img/smart/jishukaifaBG.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: center;
  display: flex;
  justify-content: center;
  .content {
    width: 1200px;
    // height: 1120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .top {
      margin-top: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .title {
        font-size: 24px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #333333;
        line-height: 31px;
      }
      .titleE {
        margin-top: 7px;
        font-size: 21px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 27px;
      }
    }
    .buttoms {
      width: 100%;
      display: flex;
      margin-top: 24px;
      // justify-content: space-between;
      flex-wrap: wrap;
      // height: 341px;
      position: relative;
      .cards{
        width: 1364px;
        height: 372px;
        position: absolute;
        top: 97px;
        left: -80px;
      }
      .card{
        width: 119px;
        // height: 64px;
        // background: #FFFFFF;
        // border-radius: 32px;
        // border: 1px solid #357BFF;
        // line-height: 64px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        text-align: -webkit-center;
        margin:0 60px;
        color: #333333;;
        z-index: 99;
        .img1{
          margin-top: 55px;
          display: block;
        }
        .img2{
          margin-top: 55px;
          display: none;
        }
        .text{
          font-size: 18px;
          line-height: 24px;
          // margin-left: 8px;
          margin-top: 19px;
        }
      }
      .card:hover{
        .img1{
          margin-top: 55px;
          display: none;
        }
        .img2{
          margin-top: 55px;
          display: block;
        }
      }
      // .card {
      //   width: 372px;
      //   height: 226px;
      //   display: flex;
      //   justify-content: center;
      //   flex-direction: column;
      //   align-items: center;
      //   margin-top: 33px;
      //   cursor: pointer;
      //   img {
      //     width: 372px;
      //     height: 183px;
      //     margin-bottom: 19px;
      //   }
      //   span {
      //     font-size: 18px;
      //     font-family: MicrosoftYaHei;
      //     color: #333333;
      //     line-height: 24px;
      //   }
      // }
    }
    .more {
      width: 133px;
      height: 38px;
      border-radius: 2px;
      border: 1px solid #979797;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #333333;
      line-height: 21px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 95px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
</style>