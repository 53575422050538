<template>
  <div>
    <div class="airFreight">
      <div class="content" id="seaAnchor">
        <div class="top">
          <div class="title">{{data.title}}</div>
          <!-- <div class="titleE">{{data.titleE}}</div> -->
        </div>
        <div class="buttom">
          <img :src="requireImg(data.url)" alt class="carryUrl" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { shipping } from "../data";

export default {
  data() {
    return {
      data: {}
    };
  },
  created() {
    this.data = shipping;
    this.$nextTick(() => this.getSeaAnchor())
  },
  methods: {
    requireImg() {
      return require("@/assets/home/haiyun2.png");
    },
    getSeaAnchor() {
      let select = localStorage.getItem("seaAnchor");
      let elm = document.getElementById(select);
      if (select) {
        elm.scrollIntoView(true);
      }
    },
  },
  destroyed() {
    localStorage.setItem("seaAnchor", "");
  },
};
</script>

<style lang="less" scoped>
.airFreight {
  width: 100%;
  height: 100%;
  position: relative;
  background: #ffffff;
  display: flex;
  justify-content: center;
  .content {
    width: 1327px;
    height: 916px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .top {
      margin-top: 58px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .title {
        font-size: 24px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #333333;
        line-height: 31px;
      }
      .titleE {
        margin-top: 7px;
        font-size: 21px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 27px;
      }
    }
    .buttom {
      width: 100%;
      display: flex;
      margin-top: 50px;
      .carryUrl {
        width: 100%;
        height: 625px;
        margin-top: 38px;
      }
    }
  }
}
</style>