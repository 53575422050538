<template>
  <div class="content">
    <div class="title">合作咨询</div>
    <!-- <div class="title2">COOPERATION CONSULTING</div> -->
    <div class="content_box">
      <img src="@/assets/product/hezuoIcon.png" style="width:37px;height:32px;margin-top:10px;" alt />
      <div
        class="contTit"
        style
      >感谢您使用佳裕达的服务。请您认真填写以下信息。提交成功后，我们的专业服务人员会尽快与您取得联系。佳裕达集团会对通过本网站收集的个人信息保密。</div>
    </div>
    <div class="form">
      <el-form :model="ruleForm" :rules="rules">
        <el-row class="firstRow">
          <el-col :span="7">
            <el-form-item label=" 客户名称" prop="name">
              <el-input v-model="ruleForm.name" placeholder="客户名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="margin-left: 18px">
            <el-form-item label=" 行业类型" prop="type">
              <el-input v-model="ruleForm.type"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="margin-left: 18px">
            <el-form-item label=" 客户联系人" prop="linkman">
              <el-input v-model="ruleForm.linkman"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label=" 客户手机号码" prop="phone">
              <el-input v-model="ruleForm.phone" placeholder="请输入手机号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :span="4">
            <el-form-item label="客户地址" class="formText" prop="address">
              <el-select v-model="ruleForm.address" placeholder="请选择省"></el-select>
              <!-- <el-input></el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="4" style="margin-top: 40px; margin-left: 18px">
            <el-select placeholder="请选择市"></el-select>
          </el-col>
          <el-col :span="4" style="margin-top: 40px; margin-left: 18px">
            <el-select placeholder="城乡结合部及乡镇"></el-select>
          </el-col>
          <el-col :span="9" style="margin-top: 40px; margin-left: 18px">
            <el-input placeholder="请输入详细地址"></el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="验证码" prop="authCode">
              <el-input v-model="ruleForm.authCode" placeholder="请输入验证码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="margin-left: 18px; margin-top: 40px">
            <el-input></el-input>
          </el-col>
        </el-row>
        <div class="contList">
          <el-radio v-model="radio" label="1">我同意佳裕达官网搜集并使用我的以上个人信息，并同意接收佳裕达想向发送的相关推广活动信息。</el-radio>
        </div>
        <div class="content_butt">
          <el-button type="primary" class="buttons">确认</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  created() {},
  data() {
    return {
      radio: "",
      ruleForm: {},
      rules: {
        name: [{ required: true, message: " ", trigger: "blur" }],
        type: [{ required: true, message: " ", trigger: "blur" }],
        linkman: [{ required: true, message: " ", trigger: "blur" }],
        phone: [{ required: true, message: " ", trigger: "blur" }],
        address: [{ required: true, message: " ", trigger: "blur" }],
        authCode: [{ required: true, message: " ", trigger: "blur" }]
      }
    };
  }
};
</script>

<style lang="less" scoped>
.content {
  padding-top: 150px;
  width: 100%;
  height: 100%;
  .title {
    // margin: 0 auto;
    text-align: center;
    // width: 1200px;
    font-size: 24px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #333333;
    line-height: 31px;
  }
  .title2 {
    margin-top: 7px;
    text-align: center;
    font-size: 21px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #333333;
    line-height: 27px;
  }
  .content_box {
    margin: 0 auto;
    margin-top: 62px;
    width: 1200px;
    height: 48px;
    display: flex;
    .contTit {
      margin-left: 6px;
      font-size: 18px;
      font-family: MicrosoftYaHei;
      color: #333333;
      line-height: 24px;
    }
  }
  .form {
    margin: 0 auto;
    width: 1200px;
    margin-top: 90px;
    .firstRow {
      height: 120px;
    }
    .contList {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #666666;
      line-height: 19px;
      margin-top: 29px;
    }
    .content_butt {
      //   margin: 0 auto;
      //   width: 1200px;
      .buttons {
        margin-top: 83px;
        margin-bottom: 59px;
        width: 242px;
        height: 42px;
        margin-left: 500px;
        text-align: center;
        background: #357bff;
      }
    }
  }
}
</style>