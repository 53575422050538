<template>
  <div class="partner">
    <el-tabs v-model="active">
      <el-tab-pane label="合作伙伴" name="first">
        <!-- <vue-seamless-scroll :class-option="optionHover" :data="Customers" class="seamless-warp"> -->
        <div class="partnerContent">
          <div class="partnerIcon" v-for="(item, index) in Customers" :key="'huoban' + index"
            :style="item.img == '' ? 'box-shadow:none' : ''">
            <img :src="item.img" alt="" preview="2" />
            <!-- </div> -->
          </div>
        </div>
        <!-- </vue-seamless-scroll> -->
      </el-tab-pane>
      <el-tab-pane label="合作客户" name="second" lazy>
        <!-- <vue-seamless-scroll :class-option="optionHover" :data="parentList" class="seamless-warp"> -->
        <div class="partnerContent">
          <div class="partnerIcon" v-for="(item, index) in parentList" :key="'kehu' + index"
            :style="item.img == '' ? 'box-shadow:none' : ''">
            <img :src="item.img" alt="" preview="3" />
            <!-- </div> -->
          </div>
        </div>
        <!-- </vue-seamless-scroll> -->
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import "swiper/dist/css/swiper.min.css";
// import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  data() {
    return {
      active: "first",
      Customers: [
        {
          img: require("@/assets/home/NCA.png"),
        },
        {
          img: require("@/assets/home/HK.png"),
        },
        {
          img: require("@/assets/home/mala.png"),
        },
        {
          img: require("@/assets/home/ethiopian.png"),
        },
        {
          img: require("@/assets/home/zhonghuahangkong.png"),
        },
        {
          img: require("@/assets/home/atlas.png"),
        },
        {
          img: require("@/assets/home/airbr.png"),
        },
        {
          img: require("@/assets/home/zhongzhouhangkong.png"),
        },
        {
          img: require("@/assets/home/hapag.png"),
        },
        {
          img: require("@/assets/home/MCC.png"),
        },
        {
          img: require("@/assets/home/MSC.png"),
        },
        {
          img: require("@/assets/home/MAERSK.png"),
        },
        {
          img: require("@/assets/home/CMA.png"),
        },
        {
          img: require("@/assets/home/APL.png"),
        },
        {
          img: require("@/assets/home/HMM.png"),
        },
        {
          img: require("@/assets/home/YANGMING.png"),
        },
        {
          img: require("@/assets/home/Matson.png"),
        },
        {
          img: require("@/assets/home/cosco.png"),
        },
        {
          img: require("@/assets/home/oocl.png"),
        },
        {
          img: require("@/assets/home/ZIM.png"),
        },
      ],
      parentList: [
        {
          img: require("@/assets/img/111位图.png"),
        },
        {
          img: require("@/assets/icon/jingdong.png"),
        },
        {
          img: require("@/assets/icon/cainiao.png"),
        },
        {
          img: require("@/assets/icon/yamaxun1.png"),
        },
        {
          img: require("@/assets/icon/hesaikeji.png"),
        },
        {
          img: require("@/assets/icon/zhongliang.png"),
        },
        {
          img: require("@/assets/icon/anke.png"),
        },
        {
          img: require("@/assets/icon/pulutong.png"),
        },
        {
          img: require("@/assets/icon/xinwanda.png"),
        },
        {
          img: require("@/assets/icon/ele.png"),
        },
        {
          img: require("@/assets/icon/deyidianqi.png"),
        },
        {
          img: require("@/assets/icon/dazujiguang.png"),
        },
        {
          img: require("@/assets/icon/chaungwei.png"),
        },
        {
          img: require("@/assets/icon/lianxiang.png"),
        },
        {
          img: require("@/assets/icon/xiaomi.png"),
        },
        {
          img: require("@/assets/icon/oppo.png"),
        },
        {
          img: require("@/assets/icon/vivo.png"),
        },
        {
          img: require("@/assets/icon/ningdeshidai.png"),
        },
        {
          img: require("@/assets/icon/tcl.png"),
        },
        {
          img: require("@/assets/icon/parker.png"),
        },

      ],
      srcList: [],

    }
  },
  components: {
    // vueSeamlessScroll
  },
  created() {
    // this.Customers = [...this.Customers, ...this.Customers]
    // this.parentList = [...this.parentList, ...this.parentList]
  },
  computed: {
    optionHover() {
      return {
        hoverStop: true,
        step: 0.5,
        limitMoveNum: this.limitMoveNum
      };
    }
  },
}
</script>

<style lang="less" scoped>
.partner {
  width: 100%;
  height: calc((415/10)*1rem);
  background: #fff;
  padding: 0 calc((3/10)*1rem) calc((0/10)*1rem) calc((3/10)*1rem);
  overflow: hidden;
  z-index: 999;
  position: relative;

  /deep/ .el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
    align-items: center;

    div#tab-first {
      font-size: calc((16/10)*1rem);
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: calc((21/10)*1rem);
      margin-right: calc((53/10)*1rem);
      height: calc((27/10)*1rem);
    }

    div#tab-second {
      font-size: calc((16/10)*1rem);
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: calc((21/10)*1rem);
      height: calc((27/10)*1rem);
    }

    .el-tabs__item {
      padding: 0;
    }

    .el-tabs__item:last-child {
      margin: 0;
    }

    .el-tabs__active-bar {
      display: none;
    }

    .el-tabs__item:hover,
    .is-active {
      border-bottom: calc((3/10)*1rem) solid #357bff;
    }
  }



  .seamless-warp {
    height: 340px;
    overflow: hidden;
    margin-top: calc((22/10)*1rem);


  }

  .partnerContent {
    width: 100%;
    // height: 100%;
    // height: calc((415/10)*1rem);
    // margin-left: 360px;
    // height: calc((400/10)*1rem);
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    // align-content: space-between;
    justify-content: flex-start;

    // text-align: center;
    .partnerIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc((111/10)*1rem);
      height: calc((41/10)*1rem);
      background: #ffffff;
      box-shadow: calc((1/10)*1rem) calc((1/10)*1rem) calc((7/10)*1rem) calc((1/10)*1rem) #e8e8e8;
      ;
      border-radius: calc((2/10)*1rem);
      margin-bottom: calc((7/10)*1rem);
      margin-top: calc((3/10)*1rem);
      // margin-right: calc((7/10)*1rem);
      margin-left: calc((9/10)*1rem);

      img {
        min-width: calc((61/10)*1rem);
        min-height: calc((21/10)*1rem);
        max-width: calc((91/10)*1rem);
        max-height: calc((32/10)*1rem);
      }
    }
  }
}

.newsContent {
  /deep/.el-tabs__header.is-top {
    width: 800px;
  }

  /deep/.tabsT.el-tabs.el-tabs--top {
    margin-top: 30px;
  }

  width: 100%;
  height: 567px;




  .butt {
    float: right;
    margin-top: 10px;
  }

  .newsBorder {
    /deep/ .el-tabs__nav-scroll {
      display: flex;

      // justify-content: center;
      // align-items: center;
      .el-tabs__item {
        padding: 0;
        margin-right: 91px;
      }

      .el-tabs__item:last-child {
        margin: 0;
      }

      .el-tabs__active-bar {
        display: none;
      }

      .el-tabs__item:hover,
      .is-active {
        border-bottom: 3px solid #357bff;
        color: #357bff;
      }
    }

    .tabsT {
      .newcontainer {
        display: flex;
        height: 410px;
        justify-content: space-between;

        .left {
          width: 625px;
          background: #f7f8fa;
          margin-top: 10px;

          .topT {
            margin-top: 19px;
            margin-left: 19px;
            width: 587px;
            height: 291px;
          }

          .BotB {
            margin-top: 25px;
            margin-left: 31px;

            .BotLeft {
              font-size: 21px;
              font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
              font-weight: bold;
              color: #333333;
              line-height: 27px;
              position: relative;

              .leftCont {
                font-size: 12px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #333333;
                line-height: 16px;
                margin-left: 26px;
                margin-top: 15px;
                position: absolute;
                top: -10px;
                // right: 1px;
              }
            }

            .BotRight {
              margin-left: 13px;
              font-size: 14px;
              font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
              font-weight: bold;
              color: #c1c1c1;
              line-height: 19px;

              .botCont {
                font-size: 12px;
                font-family: MicrosoftYaHei;
                color: #333333;
                line-height: 16px;
                margin-left: 35px;
                margin-top: 9px;
              }
            }
          }
        }

        .right {
          margin-top: 10px;
          width: 552px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .rightCont {
            width: 552px;
            height: 90px;
            background: #f7f8fa;
            display: flex;

            .contL {
              .dateT {
                margin-top: 20px;
                margin-left: 22px;
                font-size: 21px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #333333;
                line-height: 27px;
              }

              .dateB {
                margin-top: 1px;
                margin-left: 35px;
                font-size: 14px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #c1c1c1;
                line-height: 19px;
              }
            }

            .line {
              width: 1px;
              height: 38px;
              margin-left: 9px;
              margin-top: 26px;
              border: 1px solid #d3d3d3;
              transform: scale(0.5, 1);
            }

            .contR {
              margin-left: 9px;
              margin-top: 23px;

              .newT {
                font-size: 12px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #333333;
                line-height: 16px;
              }

              .newB {
                margin-top: 9px;
                font-size: 12px;
                font-family: MicrosoftYaHei;
                color: #333333;
                line-height: 16px;
              }
            }
          }

          .rightCont:hover {
            background: #357bff;

            .contL {
              .dateT {
                color: #ffffff;
              }

              .dateB {
                color: #ffffff;
              }
            }

            // .line{
            //   border: 1px solid #ffffff;
            // }
            .contR {
              .newT {
                color: #ffffff;
              }

              .newB {
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}
</style>