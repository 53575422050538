<template>
    <div class="personnelManager">
        <div class="tit">
            <div class="tit_heart">
                <span class="tit_Text">人才招聘</span><i class="el-icon-arrow-right"></i>
                <span class="tit_Icon" @click="back()">社会招聘</span><i class="el-icon-arrow-right"></i><span class="tit_Icon">职位详情</span>
            </div>
        </div>
        <div class="con">
            <div class="applyForPosition">
                <p class="position_left">职位名称：FBA业务员</p>
                <el-button type="primary" disabled>申请职位</el-button>
            </div>
        </div>
        <div class="introduce">
            <div class="introduce_top">
                <p class="sty" style="margin-right:377px">招聘人数：30人</p>
                <p class="sty" style="margin-right:335px">招聘有效期：2月～5月</p>
                <p class="sty">薪酬范围：5000-20000元/月</p>
            </div>
            <div class="introduce_bottom" style="margin-top:16px">
                <p class="sty" style="margin-right:396px">学历要求：大专</p>
                <p class="sty">经验要求：经验不限</p>
            </div>
        </div>
        <div class="operatingDuty">
            <div class="operatingDuty_text">工作职责</div>
            <ul class="operatingDuty_item">
                <li>1、通过各种渠道收集潜在客户资料外贸公司、跨境电商平台等，并跟客户进行沟通，了解客户需求公司货运航线及服务，寻找合作机会；</li>
                <li>2、挖掘客户需求，给出货运物流方案，达成交易，努力完成公司制定的销售任务；</li>
                <li>3、维护好老客户，并负责客户回款催收工作。</li>
            </ul>
        </div>
        <div class="operatingDuty" style="padding:43px 0 54px;">
            <div class="operatingDuty_text">岗位要求</div>
            <ul class="operatingDuty_item">
                <li>1、热爱业务开发，具有一定抗压能力；</li>
                <li>2、有亚马逊平台及FBA销售经验及有物流管理、供应链、市场营销等相关专业优先考虑。</li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default{
        data(){
            return{
                bacKShow:null
            }
        },
        methods:{
            back(){
                this.bacKShow = 1
                this.$emit('bacKShow',this.bacKShow)
            }
        }
    }
</script>

<style lang="less" scoped>
.personnelManager{
    .tit{   
        background: #F4F3F3;
        height: 60px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 60px;
        .tit_heart{
            margin: 0 auto;
            width: 1200px;
            .tit_Text{
                margin-right: 11px;
            }
            .tit_Icon{
                margin: 11px;
            }
        }
    }
    .con{
        margin: 0 auto;
        width: 1200px;
        padding-top: 38px;
        .applyForPosition{
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .position_left{
                font-size: 18px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #333333;
                line-height: 24px;
            }
            /deep/.el-button--primary.is-disabled{
                background: #357BFF;
                border: #357BFF;
                border-radius: 2px;
            }
        }
    }
    .introduce{
        padding: 39px 0 37px;
        width: 1200px;
        margin: 0 auto;
        border-bottom: 1px solid #CCCCCC;
        .introduce_top,.introduce_bottom{
            display: flex;
            .sty{
                font-size: 16px;
                font-family: MicrosoftYaHei;
                color: #333333;
                line-height: 21px;
            }
        }
    }
    .operatingDuty{
        width: 1200px;
        margin: 0 auto;
        padding-top: 36px;
        .operatingDuty_text{
            font-size: 18px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #333333;
            line-height: 24px;
        }
        .operatingDuty_item{
            padding: 29px 0 0 72px;
            li{
                font-size: 16px;
                font-family: MicrosoftYaHei;
                color: #333333;
                line-height: 21px;
                margin-bottom: 12px;
            }
        }
    }
}
</style>