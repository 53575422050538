//这里是导出的是最开始部分的数据
export const information = {
    "customer": {
        "num": 1888,
        "text": "累计服务客户"
    },
    "order": {
        "num": 1888,
        "text": "累计业务订单"
    },
    "data": [
        {
            "num": 23,
            "unit": "个",
            "text": "服务网点"
        },
        {
            "num": 6,
            "unit": "个",
            "text": "仓储中心"
        },
        {
            "num": 32200,
            "unit": "m²",
            "text": "仓储总面积"
        },
        {
            "num": 80.96,
            "unit": "亿元",
            "text": "年运货总价值"
        },
        // {
        //     "num": 4000,
        //     "unit": "km",
        //     "text": "日运输里程"
        // }
    ]
}
// 商贸供应链
export const supplyChain = {
    titleE: "TRADE SUPPLY CHAIN",
    title: "商贸供应链",
    data: [{
        url: 'daicai@2x', title: '国内代采',
        textPath: '', text: '根据海外客户要求，佳裕达商贸寻找优质的供应商替海外客户采购，品质验收，跟进货物运输、报关等物流环节全程跟进。        '
    },
    { url: 'jinchukou@2x', title: '一般进出口贸易', textPath: '', text: '根据国内进出口企业要求，佳裕达商贸与国内外工厂合作，以佳裕达商贸采购的方式，采购国内外产品，签订采购/销售合同，并以佳裕达商贸抬头报关，以一般贸易方式进出口。    ' },
    { url: 'daishoufuhuokuan@2x', title: '代收付货款TT', textPath: '', text: '配合国内外企业以佳裕达商贸为主体进出口货物，由佳裕达商贸对国内外收付货款，并结付汇给供货方。    ' },
    { url: 'daidianshuikuan@2x', title: '代垫税款', textPath: '', text: '1、国内供货企业，以佳裕达商贸抬头报关出口货物，供货企业开具增值税票，由佳裕达代供货企业申请退税，垫付税款给供货企业。2、国内采购企业，以佳裕达商贸抬头报关进口货物，协助采购企业垫付进口增值税，并开具增值税专用发票给采购企业。' },
    { url: 'xinyongzheng@2x', title: '信用证', textPath: '', text: '1、代国内出口企业，接收海外买家开具的信用证，替企业审核，持信用证到期，支付货款给国内企业； 2、代国内进口企业开具信用证，完成货物流后付款给海外供应商。' }]
}
// 空运
export const airFreight = {
    titleE: "AIR TRANSPORT",
    title: "空运",
    charter: '首航包机',
    charterData: [{ text: '深圳—吉隆坡', url: "1@2x" },
    { text: '深圳—曼谷', url: "2@2x" },
    { text: '深圳—纽约', url: "3@2x" },
    { text: '深圳—马尼拉', url: "4@2x" },
    { text: '香港—新德里', url: "5@2x" },
    { text: '深圳—克拉克', url: "6@2x" }],
    carry: '运载能力',
    carryUrl: 'carry'
}
// 海运
export const shipping = {
    titleE: "OCEAN SHIPPING",
    title: "海运",
    url: 'shipping'
}
// 电商物流
export const EBL = {
    titleE: "E-COMMERCE LOGISTICS",
    title: "电商物流-服务平台",
    backgroundurl: 'background2',
    // platfor: '服务平台',
    platformData: [
        { text: '亚马逊', url: "yamaxun", height: 67, width: '222' },
        { text: 'Ebay', url: "Ebay", height: 67, width: '167' },
        { text: '速卖通', url: "Ali", height: 50, width: '271' },
        { text: 'Wayfair', url: "Wayfair", height: 65, width: '217' },
        { text: 'Lazada', url: "Lazada", height: 53, width: '223' },
        { text: 'Shopee', url: "Shopee", height: 63, width: '223' },
        { text: '乐天', url: "lotte", height: 43, width: '222' },
        { text: '独立站', url: "shopify", height: 73, width: '222' },
    ],
    // carry: '物流方式',
    // carryData: [
    //     { text: '空运', url: require("@/assets/img/service/EBL/feiji.png"), id: 0 },
    //     { text: '海运', url: require("@/assets/img/service/EBL/feiji.png"), id: 1 },
    //     { text: '陆运', url: require("@/assets/img/service/EBL/feiji.png"), id: 2 },
    //     { text: '铁路', url: require("@/assets/img/service/EBL/feiji.png"), id: 3 },
    // ],
    // carry2: '铁路服务',
    // carry2Data: [
    //     { text: '深圳“湾区号”的订舱服务，珠三角区域内提供门到站服务，目的站点覆盖马拉、汉堡、杜堡、莫斯科、明斯克、塔什干、阿拉木图、老挝万象等。', url: '31', id: 0 },
    //     { text: '重庆、郑州、西安往返马拉、汉堡、杜堡、莫斯科、明斯克 进出口整柜订舱、散货集拼。', url: '32', id: 1 },
    // ]
}
// 国内及跨境陆路运输
export const roadTransport = {
    titleE: "LAND TRANSPORTATION",
    title: "国内及跨境陆路运输",
    charter: '服务内容',
    charterData: [
        { text: '整车运输', activeIcon: '11', icon: "1", url: 'b2' },
        { text: '零担快运', activeIcon: '12', icon: "2", url: 'b7' },
        { text: '同城配送', activeIcon: '13', icon: "3", url: 'b5' },
        { text: '冷链运输', activeIcon: '14', icon: "4", url: 'b3' },
        { text: '项目运输', activeIcon: '15', icon: "5", url: 'b6' },
        { text: '小包裹准时交付', activeIcon: '16', icon: "6", url: 'b4' },],
    carry: '中港运输',
    carryUrl: 'carry',
    carryData: {
        text: "中港运输优势",
        data: [
            { text: '自有车辆', url: "1" },
            { text: '车辆周转快', url: "2" },
            { text: '香港仓库快速中转', url: "3" },
            { text: '定时派送', url: "4" },
        ]
    },
    carry2: '中欧铁路运输',
    carry2Data: {
        text: "中欧运输优势",
        data: [
            { title: '中欧铁路运输优势', text: '比海运高效，比空运经济，交期准确，稳定性强', url: "1" },
            { title: '适用货运产品', text: '高科技产品，电子产品，医疗器械，工业设备，汽车及其零配件，高端消费品，农副产品', url: "2" },
        ]
    },
}


// 仓储
export const storage = {
    titleE: "STORAGE",
    title: "仓储",
    backgroundurl: 'background',
    platfor: '仓库情况说明',
    platformData: [
        { text: '中港中转仓', num: "1200", danwei:"m²" },
        { text: '红酒客户', num: "1000", danwei:"m²" },
        { text: '进口海运货物暂存仓', num: "1000", danwei:"m²" },
        { text: '国际快寄货物', num: "1500" , danwei:"m²"},
        { text: '国内货物暂存及中港配送', num: "16000" , danwei:"m²"},
        { text: '香港本地派送', num: "800" , danwei:"m²"},
    ],
    carry: '仓库设施',
    carryData: [
        { text: '摄像头', url: "shexaingtou2", id: 0 },
        { text: '叉车', url: "chache", id: 1 },
        { text: '托盘', url: "tuopan", id: 2 },
        { text: '液压升降平台', url: "dibang", id: 2 },
        { text: 'PDA', url: "PDA", id: 0 },
        { text: '货架', url: "dianzijishuqi", id: 1 },
        { text: '视屏监控', url: "qiaoshiqizhongji", id: 2 },
        { text: 'X光安检机', url: "Xhuanganjianji", id: 2 },
    ],
    carry2: '大铲湾项目',
    carry2Data:
        { text: '  大铲湾国际物流中心位于宝安区金港大道华展物流园内，项目包含3号仓、5号仓，两仓库内面积共计约  1.4万平米。项目主要业务为快进快出、散货集拼、机场前置仓，具备仓储、全球采购、国际分拨配送和国际中转等多元化、多流向的综合业务能力。项目可为华南制造业从业者、贸易商以及物流同行提供更完善和更高效的物流服务及进出口商贸配套服务。', url: "31", id: 0 },
}

// 技术开发
export const development = {
    titleE: "TECHNOLOGICAL DELELOPMENT",
    title: "技术开发",
    data: [
        { text: '客户管理系统', url: "icon1@2x", url2: "icon1-2@2x", id: 0 , width:"84" , height:"85"},
        { text: 'OMS订单系统', url: "icon2@2x", url2: "icon2-2@2x", id: 1 , width:"84" , height:"85"},
        { text: '供应链系统', url: "icon3@2x", url2: "icon3-2@2x", id: 2 , width:"84" , height:"85"},
        { text: 'IOT智能仓库', url: "icon4@2x", url2: "icon4-2@2x", id: 0 , width:"84" , height:"85"},
        { text: 'B2C电商物流', url: "icon5@2x", url2: "icon5-2@2x", id: 1 , width:"84" , height:"85"},
        { text: '海外仓系统', url: "icon6@2x", url2: "icon6-2@2x", id: 2 , width:"84" , height:"85"},
        { text: '报关系统', url: "icon7@2x", url2: "icon7-2@2x", id: 0 , width:"84" , height:"85"},
        { text: '货代操作系统', url: "icon8@2x", url2: "icon8-2@2x", id: 1 , width:"84" , height:"85"},
        { text: 'WMS仓储系统', url: "icon9@2x", url2: "icon9-2@2x", id: 2 , width:"84" , height:"85"},
        { text: 'TMS智能运输', url: "icon10@2x", url2: "icon10-2@2x", id: 0 , width:"84" , height:"85"},
        { text: '小包系统', url: "icon11@2x", url2: "icon11-2@2x", id: 1 , width:"84" , height:"85"},
        { text: 'BMS结算系统', url: "icon12@2x", url2: "icon12-2@2x", id: 2 , width:"84" , height:"85"},
        { text: 'BI数据分析', url: "icon13@2x", url2: "icon13-2@2x", id: 0 , width:"84" , height:"85"},
        // { text: '大客户定制化', url: "echarts", id: 1 },
    ]
}
